// components
import Message from "components/Message";

export default function Messages({
    conversation,
    imageSenderMap,
    setLoadedImages,
}) {
	// Sort the conversation array by the timestamp field
	const sortedConversation = [...conversation].sort((a, b) => {
		const dateA = new Date(a.timestamp); // Make sure 'timestamp' exists in your message object
		const dateB = new Date(b.timestamp);

		return dateA - dateB;
	});
	return (
		<>
			{sortedConversation.map((message, index) => {
				const isLastMessage = index === conversation.length - 1;
				return (
					<div
						key={index}
						style={{ display: 'flex', alignItems: 'start' }}
					>
						{message.type !== 'student' && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
									margin: '0 0',
								}}
							>
								<img
									src={imageSenderMap[message.type]}
									alt=" Icon"
									style={{
										marginRight: '8px',
										height: '1.8rem',
										width: '1.8rem',
										borderRadius: '50%',
									}}
								/>
							</div>
						)}
						<Message
							from={message.type}
							content={message.text}
							isCreativeImageMode={
								message.imageUrl ? true : false
							}
							creativeContent={message.imageUrl}
							image={imageSenderMap[message.type]}
							setLoadedImages={setLoadedImages}
							isShare={true}
							key={index}
						/>
						{message.type !== 'chatbot' && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
									margin: '7px 0',
								}}
							>
								<img
									src={imageSenderMap[message.type]}
									alt=" Icon"
									style={{
										marginLeft: '8px',
										height: '1.8rem',
										width: '1.8rem',
										borderRadius: '50%',
									}}
								/>
							</div>
						)}
					</div>
				);
			})}
		</>
	);
}
