import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import classnames from "classnames";
import { Offline } from "react-detect-offline";
import { isMobileSafari } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { useAuth } from 'contexts/AuthContext';
import { db } from 'lib/firebase';
import { doc, getDoc } from 'firebase/firestore';
import BigBrain from 'views/stripe/BigBrian';
// import Onboarding from 'components/Onboarding';
import { useLaunch } from 'contexts/LaunchContext';
import Smartlook from 'smartlook-client'
import HalfRedirect from 'views/stripe/HalfRedirect';


export default function Layout({
	children,
	isShareComponent,
	callChatBotChild,
	toggleCancelButton,
	isCharacterOpen,
	setIsCharacterOpen,
	isVisible,
}) {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	let [subscribed, setSubscribed] = useState(false);
	let [halfRedirect, setHalfRedirect] = useState(false);
	const [feedbackOpen, setFeedbackOpen] = useState(false);
	const mainContentRef = useRef();
	const location = useLocation();
	const { user } = useAuth();
	const {bigBrain, setBigBrain } = useLaunch();
	const [count, setCount] = useState(0);

	useLayoutEffect(() => {
		const handleScroll = (e) => {
			setIsScrolled(e.target.scrollTop > 100);
		};
		mainContentRef.current.addEventListener('scroll', handleScroll);
		return () => {
			mainContentRef.current.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	useEffect(() => {
		const fetchUserProfileSubscribed = async (uid) => {
			const docRef = doc(db, 'userProfiles', uid);
			const docSnap = await getDoc(docRef);
			return docSnap.data().is_subscribed;
		};

		const getSubscribed = async () => {
			const subscribed = await fetchUserProfileSubscribed(user.uid);
			setSubscribed(subscribed);
		};

		if (user && user.uid) {
			getSubscribed();
		}
	}, [user, setSubscribed]);

	useEffect(() => {
		if (user && user.uid) {
			Smartlook.identify(user.uid, {
				name: user.displayName,
				email: user.email,
			})
		}
	}, [])

	useEffect(() => {
		if (user && user.uid) {
			const fetchUserProfileWordCount = async (uid) => {
				const docRef = doc(db, 'userProfiles', uid);
				const docSnap = await getDoc(docRef);
				return docSnap.data().question_count || 0;
			};

			const callFetchUserProfileWordCount = async () => {
				return await fetchUserProfileWordCount(user.uid);
			};

			const getCount = async () => {
				const question_count = await callFetchUserProfileWordCount();
				setCount(10000 - question_count || 0);
				if (question_count == 13 ) {
						setHalfRedirect(true);
				}
			};

			getCount();
		}
	}, []);

	const [width, setWidth] = useState(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const isMobile = width <= 800;

	function OutsideClick(ref) {
		const [isClicked, setIsClicked] = useState(false);

		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setIsClicked(true);
				} else {
					setIsClicked(false);
				}
			}

			document.addEventListener('mousedown', handleClickOutside);
			document.addEventListener('touchstart', handleClickOutside);

			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
				document.removeEventListener('touchstart', handleClickOutside);
			};
		}, [ref]);

		return isClicked;
	}
	const boxRef = useRef(null);
	// boxOutsideClick will be true on outside click
	const boxOutsideClick = OutsideClick(boxRef);

	const closeSubscribe = () => {
		setBigBrain(false);
	};

	const closeHalfRedirect = () => {
		setHalfRedirect(false);
	};

	return (
		<div
			className="layout"
			onClick={() => setFeedbackOpen(false)}
			style={{
				position: isMobileSafari ? 'fixed' : 'fixed',
			}}
		>
			{!isShareComponent ? (
				<div
					className={classnames('sidebar', { open: isSidebarOpen })}
					style={{
						overflow: 'visible',
					}}
				>
					<Sidebar
						isMobile={isMobile}
						isOpen={!isSidebarOpen}
						setIsSidebarOpen={setIsSidebarOpen}
						isShareComponent={isShareComponent}
						setBigBrain={setBigBrain}
					/>
				</div>
			) : (
				<></>
			)}
			{isSidebarOpen && (
				<div
					className="sidebar-close"
					ref={boxRef}
					onClick={() => setIsSidebarOpen(false)}
				></div>
			)}
			<div className="main-content" ref={mainContentRef}>
				{/* <Offline>
					<div className="networkContainer">
						Your network is currently out! Check your connection.
					</div>
				</Offline> */}
				{isShareComponent ? (
					<></>
				) : (
					<nav className="header">
						<div className="burger-menu" onClick={toggleSidebar}>
							<i className="fa fa-bars"></i>
						</div>
						<div style={{ color: 'transparent' }}>.</div>
						<Header
							isShareComponent={isShareComponent}
							toggleCancelButton={toggleCancelButton}
							isVisible={isVisible}
							setIsCharacterOpen={setIsCharacterOpen}
							setBigBrain={setBigBrain}
							feedbackOpen={feedbackOpen}
							setFeedbackOpen={setFeedbackOpen}
						/>
					</nav>
				)}
				<HalfRedirect
					subscribe={halfRedirect}
					onClose={closeHalfRedirect}
				/>
				{/* <Onboarding setBigBrain={setBigBrain} /> */}
				<BigBrain bigBrain={bigBrain} onClose={closeSubscribe} />
				<div>{children}</div>
			</div>
		</div>
	);
}
