
import React, { useState, ref } from "react";
import Message from "components/Message";
import { logEvent } from 'firebase/analytics';
import { analytics } from 'lib/firebase';

export default function Messages({
	conversation,
	imageSenderMap,
	shareCallback,
	handleRegenerate,
	setLoadedImages,
	handleShareMode,
	messageChild,
	disableShareChild,
	callChatBotChild,
	isMobile,
	setIsVisible,
	setBottomShare,
	toggleCancelButton,
}) {
	// refs
	React.useEffect(() => {
		messageChild.current = handleShareClick;
	}, []);

	React.useEffect(() => {
		disableShareChild.current = disableShareOption;
	}, []);

	// states
	const [checkedMessages, setCheckedMessages] = useState([]);
	const [showCheckbox, setShowCheckbox] = useState(false);

	// methods
	const handleCheckedMessage = (message, isChecked, index) => {
		if (isChecked) {
			if (
				!checkedMessages.some(
					(checkedMessage) => checkedMessage.index === index
				)
			) {
				setCheckedMessages([...checkedMessages, { ...message, index }]);
			}
		} else {
			const newCheckedMessages = checkedMessages.filter(
				(checkedMessage) => checkedMessage.index !== index
			);
			setCheckedMessages(newCheckedMessages);
		}
	};

	const disableShareOption = () => {
		setShowCheckbox(false);
		handleShareMode(false);
	};

	const handleShareClick = () => {
		handleShareMode(true);
		setCheckedMessages([]);
		setShowCheckbox(true);
		setIsVisible(true);
	};

	const onSubmit = () => {
		shareCallback(checkedMessages, showCheckbox);
		setShowCheckbox(false);
		setCheckedMessages([]);
		toggleCancelButton();
		logEvent(analytics, 'share_button_clicked');
	};

	const handleSelectAll = () => {
		const checkboxes = document.querySelectorAll("input[type='checkbox']");
		checkboxes.forEach((checkbox) => {
			checkbox.checked = true;
		});
		const newCheckedMessages = conversation.map((message, index) => ({
			...message,
			index,
		}));
		setCheckedMessages(newCheckedMessages);
	};

	const cancelButton = () => {
		callChatBotChild();
		callChatBotChild();
	};

	return (
		<div>
			{conversation.map((message, index) => {
				const isLastMessage = index === conversation.length - 1;
				const isChecked = checkedMessages.includes(message);

				return (
					<div
						key={index}
						style={{ display: 'flex', alignItems: 'center' }}
					>
						{showCheckbox && (
							<label>
								<input
									type="checkbox"
									onClick={(event) => {
										handleCheckedMessage(
											message,
											event.target.checked,
											index
										);
									}}
									value={index}
								/>
								<span></span>
							</label>
						)}
						<Message
							from={message.type}
							content={message.text}
							isCreativeImageMode={
								message.imageUrl ? true : false
							}
							creativeContent={message.imageUrl}
							image={imageSenderMap[message.type]}
							key={index}
							setLoadedImages={setLoadedImages}
							shareCallback={
								isLastMessage ? handleShareClick : null
							}
							handleRegenerate={handleRegenerate}
							isShareMode={showCheckbox}
							isShareComponent={false}
							childMessage={message}
							handleCheckedMessage={handleCheckedMessage}
							childIndex={index}
							setBottomShare={setBottomShare}
						/>
					</div>
				);
			})}
			{showCheckbox && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						margin: '1rem',
					}}
				>
					<div
						style={{
							position: 'fixed',
							bottom: '10px',
							display: 'flex',
							justifyContent: 'space-around',
						}}
					>
						<div
							className="gradientButton"
							style={{
								textAlign: 'center',
								padding: '0.5rem 1rem',
								margin: '1rem 1rem 1rem 0',
								fontSize: '13px',
								boxShadow: 'px 0px 10px rgba(0, 0, 0, 0.1)',
								fontFamily: 'Lexend Deca,sans-serif',
								maxWidth: '400px',
								width: '9rem',
								cursor: 'pointer',
							}}
							onClick={handleSelectAll}
						>
							Select All
						</div>
						<div
							className="gradientButton"
							style={{
								textAlign: 'center',
								padding: '0.5rem 1rem',
								margin: isMobile ? '1rem 0' : '1rem',
								fontSize: '13px',
								boxShadow: 'px 0px 10px rgba(0, 0, 0, 0.1)',
								fontFamily: 'Lexend Deca,sans-serif',
								maxWidth: '400px',
								width: '9rem',
								cursor: 'pointer',
							}}
							onClick={onSubmit}
						>
							Share
						</div>
						{!isMobile && (
							<div
								className="gradientButton"
								style={{
									textAlign: 'center',
									padding: '0.5rem 1rem',
									margin: '1rem 0 1rem 1rem',
									fontSize: '13px',
									boxShadow: 'px 0px 10px rgba(0, 0, 0, 0.1)',
									fontFamily: 'Lexend Deca,sans-serif',
									maxWidth: '400px',
									width: '9rem',
									cursor: 'pointer',
								}}
								onClick={cancelButton}
							>
								Cancel
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
}
