import { createContext, useContext, useState } from 'react'
import { Alert } from 'reactstrap'
const BannerContext = createContext()

export function useBanner() {
  return useContext(BannerContext)
}

function Banner({ type, text, onClose }) {
  const [visible, setVisible] = useState(false)

  const onDismiss = () => {
    setVisible(false)
  }

  return (
    <Alert 
      color={type}
      isOpen={visible}
      toggle={onDismiss}
    >{ text }</Alert>
  )
}

export function BannerProvider({ children }) {
  const [banners, setBanners] = useState([])

  const addMessage = ({ type, text, duration }) => {
    const banner = { type, text }
    setBanners(banners => {
      return [...banners, banner]
    })
    if(duration) {
      setTimeout(() => {
        setBanners(banners => banners.filter(b => b !== banner))
      }, duration)
    }
  }

  const removeBanner = (index) => {
    setBanners(banners => {
      return banners.filter((_, i) => i !== index)
    })
  }

  const value = {
    addMessage
  }
  return (
    <BannerContext.Provider value={value}>
      <div>
        { banners.map((banner, index) => (<Banner key={index} {...banner} />)) }
      </div>
      { children }
    </BannerContext.Provider>
  ) 
}