import {
	Button,
	Card,
	Col,
	Container,
	Modal,
	ModalBody,
	Row,
	CardBody,
	Media
} from 'reactstrap';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function BoxCard({ content, textClick, imageClick }) {
	return (
		<Container className="pb-3" fluid>
			<Row className="p-2">
				<Col xs="12" className="d-flex justify-content-center pb-0">
					<h3 className="mt-2">{content[2]} ?</h3>
				</Col>
			</Row>
			<Row className="p-2">
				<Col xs="6" className="content flex-column text-center">
					<button
						className="px-4 pb-2 h-100"
						style={{
							border: '0',
							borderRadius: '5px',
							color: '#fff',
							outline: 'none',
							background: 'hsla(234, 91%, 47%, 1)',
							background:
								'linear-gradient(135deg, hsla(234, 91%, 47%, 1) 34%,hsla(282, 100%, 48%, 1) 100%)',
						}}
						onClick={imageClick}
					>
						<i
							className="far fa-file-image mt-2 mb-1"
							style={{
								fontSize: '3em',
							}}
						></i>
						<br />
						<span className="mb-3">{content[0]}</span>
					</button>
				</Col>
				<Col xs="6" className="content flex-column text-center">
					<button
						className="px-4 pb-2 h-100"
						style={{
							border: '0',
							borderRadius: '5px',
							color: '#fff',
							outline: 'none',
							background: 'hsla(234, 91%, 47%, 1)',
							background:
								'linear-gradient(135deg, hsla(234, 91%, 47%, 1) 34%,hsla(282, 100%, 48%, 1) 100%)',
						}}
						onClick={textClick}
					>
						<i
							className="fas fa-align-justify mt-3 mb-1"
							style={{
								fontSize: '3em',
							}}
						></i>
						<br />
						<span className="mb-3">{content[1]}</span>
					</button>
				</Col>
			</Row>
		</Container>
	);
}

export default function CreativeModeSelection({ enter, handleEnter }) {
	const { topic } = useParams();
	const [decodedTopic, setDecodedTopic] = useState('');
	const navigate = useNavigate();

	// Decode the topic parameter
	useEffect(() => {
		setDecodedTopic(topic ? decodeURIComponent(topic) : '');
	}, [topic]);

	// Selects Image for Create an AI image by default
	useEffect(() => {
		if (decodedTopic === "Create an AI image") {
			handleImageClick();
		}
	}, [decodedTopic])

	const handleImageClick = () => {
		// Navigate to /mode/creativeImage/ with decoded topic parameter
		handleEnter();
		navigate(
			`/mode/creativeImage${decodedTopic ? `/${decodedTopic}` : ''}`
		);
	};

	const handleTextClick = () => {
		// Navigate to /mode/creative/ with decoded topic parameter
		handleEnter();
	};

	const page = {
		title: 'Learning with AI',
		content: (
			<>
				<BoxCard
					content={['Image', 'Text', 'What do want to create']}
					textClick={handleTextClick}
					imageClick={handleImageClick}
				/>
			</>
		),
	};

	return (
		<Modal
			isOpen={enter}
			modalClassName="modal-dark-blue custom-height-modal"
			centered
			toggle={handleEnter}
		>
			<Card>
				<CardBody>{page.content}</CardBody>
			</Card>
		</Modal>
	);
}
