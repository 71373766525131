import { useAuth } from 'contexts/AuthContext';
import { IoShareOutline } from 'react-icons/io5';	
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { db } from 'lib/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useLaunch } from 'contexts/LaunchContext';
import React from 'react';
import Feedback from './Feedback';

export default function Header({ setBigBrain, feedbackOpen, setFeedbackOpen }) {
	const { user } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const [width, setWidth] = useState(window.innerWidth);
	const { characterData } = useLaunch();
	const [tooltipOpen, setTooltipOpen] = useState(true);

	const toogleFeedback = () => {
		setFeedbackOpen(!feedbackOpen);
	};

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		const isAvatarTab = async (uid) => {
			const docRef = doc(db, 'userProfiles', uid);
			const docSnap = await getDoc(docRef);
			return docSnap.data().avatar_tab;
		};

		const getAvatar = async () => {
			let avatar = await isAvatarTab(user.uid);
			if (avatar === false) {
				setTooltipOpen(false);
			}
		};

		if (user && user.uid) {
			getAvatar();
		}
	}, []);

	const updateUserProfileAvaterTab = async (uid) => {
		const docRef = doc(db, 'userProfiles', uid);
		const docSnap = await getDoc(docRef);
		if (docSnap && docSnap.exists()) {
			if (!docSnap.data().avatar_tab) {
				await updateDoc(docRef, { avatar_tab: true });
			}
		}
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const isMobile = width <= 800;
	const isSamllMobile = width <= 451;
	const countHide = width <= 1042;

	const goToProfile = () => {
		navigate('/settings#account');
	};

	return (
		<>
			<div className="senseiContainer">
				{location.pathname === '/' ||
				location.pathname === '/settings' ? (
					isMobile ? (
						<>
							<span
								style={{
									marginLeft: isMobile ? '1vw' : '2vw',
									color: 'white',
									cursor: 'pointer',
								}}
								onClick={() => navigate('/')}
							>
								<img
									src="/logo.png"
									alt="logo"
									style={{
										marginTop: '4px',
										height: '6.4vh',
										width: 'auto',
									}}
								/>
							</span>
						</>
					) : location.pathname === '/settings' ? (
						<></>
					) : (
						<div className="sensei-projects mt-1">
							<h3 className="mt-4 pt-1 ml-4">
								Projects Sensei Can Help With
							</h3>
						</div>
					)
				) : (
					<div>
						<div
							className="d-flex flex-column position-relative align-items-center"
							style={{
								cursor: 'pointer',
								pointerEvents: 'auto',
							}}
							onClick={() => {
								// setIsCharacterOpen(true);
								navigate('/avatar-list');
							}}
						>
							<span
								id="char_name"
								className="d-flex align-items-center"
								onClick={async () => {
									setTooltipOpen(true);
									await updateUserProfileAvaterTab(user.uid);
								}}
							>
								<span className="position-relative">
									<img
										src={
											characterData
												? characterData.image
												: '/sensei2.png'
										}
										alt="Sensei Profile"
										className="sensei"
										style={{
											height: isMobile ? '3rem' : '4rem',
											width: isMobile ? '3rem' : '4rem',
										}}
									/>
									<i
										className="fas fa-plus"
										style={{
											color: '#fff',
											fontSize: isMobile
												? '.4rem'
												: '.7rem',
											padding: isMobile
												? '.2rem'
												: '.3rem',
											background:
												'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
											borderRadius: '50%',
											position: 'absolute',
											left: 0,
											bottom: isMobile ? '-6px' : '-15px',
										}}
									></i>
								</span>
								<span className="sensei-text d-flex align-items-center ">
									{characterData
										? characterData.name
										: 'Sensei'}
									<i
										className="fas fa-angle-right ml-2"
										style={{ fontSize: '1rem' }}
									></i>{' '}
								</span>
							</span>
							<p
								className="mt-3 px-2 py-1 position-absolute chat-tooltip"
								style={{
									color: 'white',
									bottom: -50,
									fontSize: '1rem',
									width: 'max-content',
									// height: "3rem",
									background:
										'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
									borderRadius: '8px',
									visibility: !tooltipOpen
										? 'visible'
										: 'hidden',
									// clipPath:
									// 	'polygon(0 57%, 34% 56%, 42% 0, 51% 56%, 100% 56%, 100% 100%, 0 100%)',
								}}
							>
								<span className="">
									Switch Avatar
									<span
										onClick={async (event) => {
											event.stopPropagation();
											setTooltipOpen(true);
											await updateUserProfileAvaterTab(
												user.uid
											);
										}}
									>
										<i className="fas fa-times-circle ml-2"></i>
									</span>{' '}
								</span>
							</p>
							<style>
								{`
									.chat-tooltip::before {
										content: "";
										width: 0;
										height: 0;
										border-left: 15px solid transparent;
										border-right: 15px solid transparent;
										border-bottom: 15px solid hsla(282, 100%, 48%, 1);
										position: absolute;
										top: -12px;
										left: 50%;
										transform: translateX(-50%);
									}
								`}
							</style>
						</div>
					</div>
				)}
			</div>
			<div>
				{isMobile ? (
					location.pathname === '/' ||
					location.pathname === '/settings' ? (
						<div />
					) : (
						<div
							className="shareContainer"
							style={{
								background: isMobile ? 'transparent' : '',
							}}
						>
							<button
								className="p-1 px-2 text-white"
								style={{
									outline: 'none',
									border: 0,
									borderRadius: '7px',
									background:
										'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1)100%)',
								}}
								onClick={() => {
									setBigBrain(true);
								}}
							>
								<span
									style={{
										fontSize: '1.2rem',
									}}
								>
									Go
								</span>{' '}
								{'   '}
								<img
									src="/brain.png"
									style={{
										filter: 'invert(100%)',
										width: '1.2rem',
										height: '1.2rem',
										marginLeft: '.2rem',
										marginBottom: '.5rem',
									}}
									alt="brain"
								/>
							</button>
						</div>
					)
				) : (
					<div className="main-nav position-relative">
						<a
							href="https://discord.gg/QkdQbrh23G"
							target="_blank"
							rel="noreferrer"
						>
							<i className="fab fa-discord"></i>
						</a>
						{/* <Link to="/settings">
							<i className="fa fa-gear"></i>
						</Link> */}
						<style>
							{`
								.fas.fa-lightbulb {
									color: #f4f5f7 ;
									cursor: pointer
								}
							`}
						</style>
						<i
							className="fas fa-lightbulb user-img d-flex align-items-center justify-content-center"
							onClick={(event) => {
								event.stopPropagation();
								toogleFeedback();
							}}
						></i>
						<Feedback
							feedbackOpen={feedbackOpen}
							setFeedbackOpen={setFeedbackOpen}
						/>
						<div
							onClick={goToProfile}
							style={{
								cursor: 'pointer',
							}}
						>
							<span className="glow-ring">
								<img
									className="avatar avatar-md user-img"
									src={
										user
											? user.photoURL || '/profile.jpeg'
											: '/logo.png'
									}
									alt="..."
								/>
							</span>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
