import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from "lib/firebase"

export function uploadImageAndGetURL(file, folder) {
  return new Promise(async (resolve, reject) => {
    try {
      const storageRef = ref(storage, folder || '/images')
      const result = await uploadBytes(storageRef, file)
      const url = await getDownloadURL(result.ref)
      resolve(url)
    }
    catch(err) {
      reject(err)
    }
  })
} 