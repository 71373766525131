import {useState} from 'react';
import Slider from './Slider';

export default function GradeLevel({
	isMobile,
	isGradeLevel,
	showGradeLevelOther,
	gradeLevel,
	gradeLevelText,
	handleGradeLevelOther,
	setShowGradeLevelOther,
	setGradeLevelText,
	setIsGradeLevel,
	setActiveComponent,
}) {
	const [prev, setPrev] = useState("");
	return (
		<>
			<Slider isVisible={isGradeLevel}>
				<>
					<div className={`big-wrapper`}>
						<div
							className={`bubble-wrapper ${
								showGradeLevelOther ? '' : 'position-relative'
							}  d-flex flex-wrap`}
						>
							<i
								className="fa fa-times"
								style={{
									position: 'absolute',
									top: 5,
									right: 8,
									color: '#fff',
									cursor: 'pointer',
								}}
								onClick={() => {
									setShowGradeLevelOther(false);
									setIsGradeLevel(!isGradeLevel);
								}}
							></i>
							{gradeLevel.map((item, key) => {
								return (
									<p
										className={`bubble mx-1  ${
											item === prev ? 'bubble-white' : ''
										}`}
										key={key}
										onClick={() => {
											if (item === prev) {
												setGradeLevelText('');
												setPrev(''); // Clear the previously selected item
											} else {
												setGradeLevelText(item);
												setPrev(item); // Set this item as the currently selected item
											}
										}}
									>
										{item}
									</p>
								);
							})}
							<p
								className={`bubble mx-1 d-flex align-items-center justify-content-center position-relative ${
									showGradeLevelOther ? 'p-0' : 'p-2'
								}`}
								onClick={() => setShowGradeLevelOther(true)}
							>
								{showGradeLevelOther ? (
									<>
										<input
											type="text"
											className="input-save"
											value={gradeLevelText}
											onChange={handleGradeLevelOther}
											placeholder="What grade?"
											autoFocus
										/>
										<i
											className="fa fa-times mr-2"
											onClick={(event) => {
												event.stopPropagation();
												setShowGradeLevelOther(false);
											}}
										></i>
									</>
								) : (
									'Other'
								)}
							</p>
						</div>
					</div>
				</>
			</Slider>
			<button
				className="px-2 py-1 text-white mb-1 mr-2 bubble-btn"
				style={{
					borderTopLeftRadius: isGradeLevel ? '0px' : '',
					borderTopRightRadius: isGradeLevel ? '0px' : '',
					borderTopLeftRadius: !isGradeLevel
						? isMobile
							? '15px'
							: ''
						: '',
					borderTopRightRadius: !isGradeLevel
						? isMobile
							? '15px'
							: ''
						: '',
					borderBottomLeftRadius: !isGradeLevel
						? isMobile
							? '15px'
							: ''
						: '',
					borderBottomRightRadius: !isGradeLevel
						? isMobile
							? '15px'
							: ''
						: '',
				}}
				onClick={() => {
					setActiveComponent('isGradeLevel');
					setIsGradeLevel(!isGradeLevel);
				}}
			>
				{gradeLevelText ? gradeLevelText : 'Grade Level'}
				{isGradeLevel ? (
					<i className="fas fa-caret-up ml-1"></i>
				) : (
					<i className="fas fa-caret-down ml-1"></i>
				)}
			</button>
		</>
	);
}
