// react
import React, { useState, useEffect, useRef } from 'react';

// components
import ShareMessage from 'components/ShareComponent/ShareMessage';

// lib
import { db, analytics } from 'lib/firebase';
import {
    collection,
    doc,
    getDocs,
    query,
    where
} from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';

// reactstrap components
import {
    Button,
} from "reactstrap";

export default function ShareComponent() {
    // refs
    const [messages, setMessages] = useState([]);
    const [loadedImages, setLoadedImages] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const [userName, setUserName] = useState('');
    const [characterData, setCharacterData] = useState(null);

    const scrollRef = useRef();
    const isMobile = width <= 800;

    // functions
    const fetchMessages = async () => {
        let docId = window.location.pathname.split("/")[3];
        let parentDocId = window.location.pathname.split("/")[2];
        const messagesRef = collection(db, 'public-share-messages', parentDocId, 'messages');
        const q = query(messagesRef, where('docId', '==', docId));
        const snapshot = await getDocs(q);
        const data = snapshot.docs.map((doc) => doc.data());

        data.sort((a, b) => {
            return a.timestamp - b.timestamp;
        });

        setUserName(data[0].name ? data[0].name : 'Anonymous');
		setCharacterData({ name: data[0].header, image: data[0].header_img})

        setMessages(data);
    };

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    // hooks
    useEffect(() => {
        fetchMessages();
        logEvent(analytics, 'share_chat_viewed');
    }, []);

    useEffect(() => {
        scrollRef.current.scrollIntoView();
    }, [messages, loadedImages]);

    useEffect(() => {
        scrollRef.current.scrollIntoView();
    }, []);

    useEffect(() => {
        scrollRef.current.scrollIntoView();
    }, [loadedImages]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        if (!isMobile) {
            const sidebarWidth = 300;
            const contentWidth = width - sidebarWidth;
            const left = width - contentWidth;
            const backgroundMode = document.querySelector('.background-mode');
            if (backgroundMode) {
                backgroundMode.style.width = `${contentWidth}px`;
                backgroundMode.style.left = `${left}px`;
            }
        }
        if (isMobile) {
            const backgroundMode = document.querySelector('.background-mode');
            if (backgroundMode) {
                backgroundMode.style.width = `100%`;
                backgroundMode.style.left = `0px`;
            }
        }
    }, [width, isMobile]);

    return (
		<>
			<div
				className="background-mode-wrapper"
				style={{
					position: 'relative',
					width: '100%',
					height: '100%',
					overflow: 'hidden',
					zIndex: 0,
				}}
			>
				<div
					className="pt-3 w-100 position-fixed"
					style={{ zIndex: 99, backgroundColor: 'black' }}
				>
					<>
						<div className="d-flex align-items-center justify-content-between">
							<span
								style={{
									marginLeft: isMobile ? '1vw' : '2vw',
									color: 'white',
									cursor: 'pointer',
								}}
							>
								<img
									src="/logo.png"
									alt="Sensei Profile"
									className="img-fluid"
									style={{
										height: isMobile ? '3rem' : '4rem',
									}}
								/>
							</span>
							<div className="d-none d-sm-block">
								<span className="d-flex align-items-center">
									<img
										src={
											characterData?.image
												? characterData.image
												: '/sensei2.png'
										}
										alt="Sensei Profile"
										className="sensei"
										style={{
											height: isMobile ? '3rem' : '4rem',
										}}
									/>
									<span className="sensei-text d-flex align-items-center ">
										{characterData
											? characterData.name
											: 'Sensei'}
									</span>
								</span>
							</div>
							<div>
								<a
									href="https://app.learningwith.ai/?source=share"
									target="_blank"
									rel="noopener noreferrer"
									style={{ color: '#fff' }}
								>
									<Button
										color="primary"
										className="cta-btn mr-4"
										style={{
											width: isMobile ? '7rem' : '12rem',
											fontSize: isMobile
												? '0.8rem'
												: '1rem',
											height: isMobile
												? '2.3rem'
												: '3rem',
										}}
									>
										Try for free
									</Button>
								</a>
							</div>
						</div>
					</>
				</div>
				<div
					className="chat_page_container_ll chat-component"
					style={{
						marginTop: '7rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '1rem',
						}}
					>
						Conversation with{' '}
						{characterData?.name ? characterData.name : 'Sensei'} &{' '}
						{userName}.
					</div>
					<div className="messages-container p-3">
						<ShareMessage
							conversation={messages}
							imageSenderMap={{
								chatbot: characterData?.image
									? characterData.image
									: '/sensei2.png',
								student: '/profile.jpeg',
							}}
							setLoadedImages={setLoadedImages}
							isShare={true}
						/>

						<div ref={scrollRef} style={{ marginTop: '80px' }} />
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%',
							}}
						>
							<a
								href="https://app.learningwith.ai/?source=share"
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: '#fff' }}
							>
								<Button
									color="primary"
									className="cta-btn"
									style={{
										width: isMobile ? '7rem' : '12rem',
										fontSize: isMobile ? '0.8rem' : '1rem',
										height: isMobile ? '2.3rem' : '3rem',
									}}
								>
									Try for free
								</Button>
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
