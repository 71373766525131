import { useState } from 'react';
import Slider from './Slider';

export default function GradeLevel({
	isMobile,
	isPersona,
	showPersonaOther,
	persona,
	personaText,
	handlePersonaOther,
	setShowPersonaOther,
	setPersonaText,
	setIsPersona,
	setActiveComponent,
}) {
	const [prev, setPrev] = useState('');
	return (
		<>
			<Slider isVisible={isPersona}>
				<>
					<div className={`big-wrapper`}>
						<div
							className={`bubble-wrapper ${
								showPersonaOther ? '' : 'position-relative'
							}  d-flex flex-wrap`}
						>
							<i
								className="fa fa-times"
								style={{
									position: 'absolute',
									top: 5,
									right: 8,
									color: '#fff',
									cursor: 'pointer',
								}}
								onClick={() => {
									setShowPersonaOther(false);
									setIsPersona(!isPersona);
								}}
							></i>
							{persona.map((item, key) => {
								return (
									<p
										className={`bubble mx-1  ${
											item === prev ? 'bubble-white' : ''
										}`}
										key={key}
										onClick={() => {
											if (item === prev) {
												setPersonaText('');
												setPrev(''); // Clear the previously selected item
											} else {
												setPersonaText(item);
												setPrev(item); // Set this item as the currently selected item
											}
										}}
									>
										{item}
									</p>
								);
							})}
							<p
								className={`bubble mx-1 d-flex align-items-center justify-content-center position-relative ${
									showPersonaOther ? 'p-0' : 'p-2'
								}`}
								onClick={() => setShowPersonaOther(true)}
							>
								{showPersonaOther ? (
									<>
										<input
											type="text"
											className="input-save"
											value={personaText}
											onChange={handlePersonaOther}
											placeholder="What persona?"
										/>
										<i
											className="fa fa-times mr-2"
											onClick={(event) => {
												event.stopPropagation();
												setShowPersonaOther(false);
											}}
										></i>
									</>
								) : (
									'Other'
								)}
							</p>
						</div>
					</div>
				</>
			</Slider>
			<button
				className="px-2 py-1 text-white mb-1 mr-2 bubble-btn"
				style={{
					borderTopLeftRadius: isPersona ? '0px' : '',
					borderTopRightRadius: isPersona ? '0px' : '',
					borderTopLeftRadius: !isPersona
						? isMobile
							? '15px'
							: ''
						: '',
					borderTopRightRadius: !isPersona
						? isMobile
							? '15px'
							: ''
						: '',
					borderBottomLeftRadius: !isPersona
						? isMobile
							? '15px'
							: ''
						: '',
					borderBottomRightRadius: !isPersona
						? isMobile
							? '15px'
							: ''
						: '',
				}}
				onClick={() => {
					setActiveComponent('isPersona');
					setIsPersona(!isPersona);
				}}
			>
				{personaText ? personaText : 'Persona'}
				{isPersona ? (
					<i className="fas fa-caret-up ml-1"></i>
				) : (
					<i className="fas fa-caret-down ml-1"></i>
				)}
			</button>
		</>
	);
}
