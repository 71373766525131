import React, { useState } from 'react';

// reactstrap component-s
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';

import { useForm } from 'react-hook-form';


import { db } from 'lib/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { useAuth } from 'contexts/AuthContext';

export default function Feedback({ feedbackOpen, setFeedbackOpen }) {
	const { register, handleSubmit, reset } = useForm();
	const [activeTab, setActiveTabs] = useState(1);

	const { user } = useAuth();

	const handleActiveTab = (num) => {
		setActiveTabs(num);
	};

	const onSubmit = async (data) => {
		let whom, text;
		if (activeTab == 1) {
			whom = 'Support';
			text = data.support;
		} else {
			whom = 'Founder';
			text = data.founder;
		}

		const datum = {
			to: ['team@learningwith.ai'],
			message: {
				subject: `Feedback from ${user.email} to ${whom}`,
				text: text,
			},
		};
		await addDoc(collection(db, 'mail'), datum);
		reset();
		setFeedbackOpen(false);
	};

	return (
		<>
			<style>
				{`
                    .feedback {
						background-color: #000 !important;
						border-radius: 7px !important;
                        color: #fff;
					}

                    .textarea {
                        &::-webkit-scrollbar {
							width: 1px;
							background-color: transparent;
						}
						
						&::-moz-scrollbar-scrollbar {
							width: 1px;
							background-color: transparent;
						}
                    }
                `}
			</style>
			<div
				className="feedback position-absolute p-4"
				style={{
					display: feedbackOpen ? '' : 'none',
					top: '4rem',
					right: '0',
					width: '23rem',
					backgroundColor: 'rgba(30, 132, 227, 0.2)',
					background:
						'linear-gradient(107.62deg, rgba(30, 132, 227, 0.2) 0%,rgba(92, 38, 211, 0.2) 100% )',
					boxShadow: '0px 20px 50px 0px rgba(0, 0, 0, 0.3)',
				}}
				onClick={(event) => {
					event.stopPropagation();
				}}
			>
				<Nav
					className="nav d-flex nav-tabs nav-tabs-primary rounded-lg border border-light justify-content-center"
					tabs
					style={{
						backgroundColor: 'rgb(225, 225, 225, .1)',
					}}
				>
					<NavItem>
						<NavLink
							className={
								activeTab === 1 ? 'active' : 'text-muted'
							}
							style={{
								borderColor: 'transparent',
								outline: 'none',
								border: '0',
								fontSize: '16px',
								borderRadius: '4px',
								flex: '1',
								cursor: 'pointer',
							}}
							onClick={(e) => {
								e.preventDefault();
								handleActiveTab(1);
							}}
						>
							To Support
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={
								activeTab === 2 ? 'active' : 'text-muted'
							}
							style={{
								borderColor: 'transparent',
								outline: 'none',
								border: '0',
								fontSize: '16px',
								borderRadius: '4px',
								flex: '1',
								cursor: 'pointer',
							}}
							onClick={(e) => {
								e.preventDefault();
								handleActiveTab(2);
							}}
						>
							To Founders
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={'header' + activeTab}>
					<TabPane tabId="header1">
						<form
							className="text-center py-3"
							style={{ fontSize: '16px' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<h4>What can we help you with?</h4>
							<textarea
								{...register('support')}
								style={{
									height: '10rem',
									width: '100%',
									borderRadius: '7px',
									color: 'lightgray',
								}}
								placeholder="Bug you found, getting back into your account, something that doesn't make sense, general questions about how Learning With AI works... We're here to help with anything and everything :)"
								className={`p-2  bg-dark textarea`}
							/>
							<button
								className="w-100 my-2 py-2 text-white rounded-lg"
								style={{
									outline: 'none',
									border: 0,
									backgroundColor: 'rgba(30, 132, 227, 0.2)',
									backgroundImage:
										'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
									boxShadow:
										'0px 20px 50px 0px rgba(0, 0, 0, 0.3)',
								}}
							>
								Send To Our Support Team
							</button>
						</form>
					</TabPane>
					<TabPane tabId="header2">
						<form
							className="text-center py-3"
							style={{ fontSize: '16px' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<h4>
								What do you want to share with the founders
								directly?
							</h4>
							<textarea
								{...register('founder')}
								style={{
									height: '10rem',
									width: '100%',
									borderRadius: '7px',
									color: 'lightgray',
								}}
								placeholder="Give feedback, request a feature, criticize us, roast us, tell us what we should prioritize or focus on, share an idea, tell us how we can be better, tell us what you hate & love... all feedback is appreciated, read by our founders personally, and taken into account with all future decisions."
								className={`p-2  bg-dark textarea`}
							/>
							<button
								className="w-100 my-2 py-2 text-white rounded-lg"
								style={{
									outline: 'none',
									border: 0,
									backgroundColor: 'rgba(30, 132, 227, 0.2)',
									backgroundImage:
										'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
									boxShadow:
										'0px 20px 50px 0px rgba(0, 0, 0, 0.3)',
								}}
							>
								Send Directly To Our Founder's Phone
							</button>
						</form>
					</TabPane>
				</TabContent>
			</div>
		</>
	);
}
