import { useEffect, useState } from "react";
import classnames from "classnames";
import {
  Alert,
  Container,
  Col,
  Button,
  InputGroup,
  Input,
  Form,
} from "reactstrap";
import { useAuth } from "contexts/AuthContext";
import "./Onboarding.css";

export default function UserDetails({ onComplete }) {
  const { updateUser } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameFocus, setFirstNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 450;

  const handleUserInfo = async () => {
    if (!firstName || !lastName) {
      return setError("Must include first and last name");
    }
    setLoading(true);
    const displayName = `${firstName} ${lastName}`;
    try {
      await updateUser({ displayName });
      onComplete(displayName);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Col className="d-flex align-items-center justify-content-center flex-column">
        <h1 className="text-align-center">Tell us about you</h1>
        {error && <Alert color="warning">{error}</Alert>}
        <Form
          action=""
          className="form d-flex align-items-center justify-content-around"
          style={{ width: isMobile ? "70vw" : "22vw", marginBottom: "3vh" }}
          method=""
        >
          <InputGroup
            className={classnames("input-lg", {
              "input-group-focus": firstNameFocus,
            })}
          >
            <Input
              placeholder="First Name"
              type="text"
              value={firstName}
              onFocus={(e) => setFirstNameFocus(true)}
              onBlur={(e) => setFirstNameFocus(false)}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </InputGroup>
          <InputGroup
            className={classnames("input-lg", {
              "input-group-focus": lastNameFocus,
            })}
          >
            <Input
              placeholder="Last Name"
              type="text"
              value={lastName}
              onFocus={() => setLastNameFocus(true)}
              onBlur={() => setLastNameFocus(false)}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </InputGroup>
        </Form>
        <InputGroup className="d-flex justify-content-center">
          <button
            color="primary"
            className="continueButton"
            onClick={handleUserInfo}
            disabled={loading}
          >
            Continue
          </button>
        </InputGroup>
      </Col>
    </Container>
  );
}
