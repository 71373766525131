import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';

export default function ImageUploadModal({ isOpen, onClose, onImageUpload }) {
	const [selectedImage, setSelectedImage] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);

	const handleImageChange = (e) => {
		if (e.target.files && e.target.files[0]) {
			setSelectedImage(e.target.files[0]);
			setPreviewImage(URL.createObjectURL(e.target.files[0]));
		}
	};

	const handleAccept = () => {
		onImageUpload(selectedImage);
		setPreviewImage(null);
        onClose();
	};

	return (
		<Modal isOpen={isOpen} toggle={onClose} centered>
			<ModalHeader toggle={onClose}>Upload Image</ModalHeader>
			<ModalBody>
				<Input
					name="file"
					type="file"
					accept="image/*"
					onChange={handleImageChange}
				/>
				{previewImage && <img src={previewImage} alt="Preview" />}
			</ModalBody>
			<ModalFooter className="p-2">
				<Button
					color="primary"
					className="btn btn-primary"
					onClick={handleAccept}
				>
					Accept
				</Button>{' '}
				<Button
					color="danger"
					onClick={() => {
						setPreviewImage(null);
						onClose();
					}}
				>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
}
