import React from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Spinner,
} from 'reactstrap';

function PauseSub({ isOpen, onClose, onPause, handlePortal, loading, setLoading, toggle}) {
	const handleToggle = () => {
		toggle(!isOpen);
		setLoading(!loading);
	} 
	return (
		<Modal
			isOpen={isOpen}
			modalClassName="modal-dark-blue custom-height-modal"
			centered
			toggle={handleToggle}
		>
			<div className="p-3 text-center">
				<ModalHeader
					className="text-center justify-content-center"
					toggle={onClose}
				></ModalHeader>
				<ModalBody>
					<h4 className="p-0 m-0">
						Would you like to pause your subscription for three
						months?
					</h4>
				</ModalBody>
				<ModalFooter className="mt-3">
					<Button
						onClick={() => {
							setLoading(true);
							onPause();
						}}
						className=""
						color="success"
					>
						{loading ? <Spinner>Loading...</Spinner> : 'Yes please'}
					</Button>
					<Button
						onClick={() => {
							onClose();
							alert('You will be redirected in a moment.');
							handlePortal();
						}}
						className=""
						color="danger"
					>
						Sayonara, muchacho
					</Button>
				</ModalFooter>
			</div>
		</Modal>
	);
}

export default PauseSub;
