import Layout from 'components/Layout';
import {
	Nav,
	NavItem,
	NavLink,
	Card,
	Form,
	Input,
	InputGroup,
	Alert,
	Table
} from 'reactstrap';
import { useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import chemistryImg from 'assets/img/cards/chemistry.png';
import physicsImg from 'assets/img/cards/physics.png';
import chatImg from 'assets/img/cards/speech.png';
import { db, storage, analytics } from 'lib/firebase';
import { logEvent } from 'firebase/analytics';
import { useAuth } from 'contexts/AuthContext';
import {
	query,
	collection,
	orderBy,
	doc,
	getDoc,
	getDocs,
	updateDoc,
} from 'firebase/firestore';
import { Helmet } from 'react-helmet';
import { ref, getDownloadURL } from 'firebase/storage';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useLaunch } from 'contexts/LaunchContext';
import { useNavigate } from 'react-router-dom';
import { config } from 'constants/stripe';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const CACHE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours

const categories = [
	'popular',
	'learning',
	'solve_it',
	'math_n_science',
	'creative',
	'writing',
	'coding',
	'play',
];

export default function Dashboard() {
	const [searchOption, setSearchOption] = useState(categories[0]);
	const [flashCard, setFlashCard] = useState([]);
	const [savedCard, setSavedCard] = useState([]);
	const [imageUrls, setImageUrls] = useState([]);
	const { user } = useAuth();
	const { setLaunchPad } = useLaunch();
	const navigate = useNavigate();
	const [width, setWidth] = useState(window.innerWidth);
	const [searchQuery, setSearchQuery] = useState('');
	const [filteredTools, setFilteredTools] = useState(flashCard);
	const [showButton, setShowButton] = useState(false);
	const [inputFocused, setInputFocused] = useState(false);
	const [error, setError] = useState('');
	const [selectedOptionLaunchpad, setSelectedOptionLaunchpad] = useState('Pro');
	const [isCheckedLaunchpad, setIsCheckedLaunchpad] = useState(true);

	const location = useLocation();

	// Retrieve the affiliate from the URL
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const aff = searchParams.get('aff');
		const source = searchParams.get('source');
		if (aff) {
			localStorage.setItem('referrer_url', aff);
			navigate("/")
		}

		if (source) {
			logEvent(analytics, 'source', {"source" : source});
			navigate('/');
		}
	}, [location.search]);

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const isSmallMobile = width <= 420;
	const isSm = width <= 576;
	const isSmallestMobile = width <= 372;
	const isSmallerMobile = width <= 400;
	const isMediumMobile = width <= 540;
	const isButtonMobile = width <= 570;
	const isMidMobile = width <= 579;
	const isMobile = width <= 800;
	const isTab = width <= 1000;

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		document.body.classList.add('dashboard-page');
		return () => {
			document.body.classList.remove('dashboard-page');
		};
	}, []);

	const cachedFlashCard = useMemo(() => {
		const cachedData = localStorage.getItem('flashCard');
		const cacheExpiry = localStorage.getItem('flashCardExpiry');
		if (cachedData && cacheExpiry && Date.now() < cacheExpiry) {
			return JSON.parse(cachedData);
		}
		return null;
	}, []);

	const saveToCache = (data) => {
		localStorage.setItem('flashCard', JSON.stringify(data));
		localStorage.setItem('flashCardExpiry', Date.now() + CACHE_EXPIRY_TIME);
	};

	useEffect(() => {
		const filtered = flashCard.filter(
			(tool) =>
				tool.categories.includes(searchOption) &&
				(tool.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
					tool.description
						.toLowerCase()
						.includes(searchQuery.toLowerCase()))
		);
		if (filtered.length === 0 && searchQuery.length > 0) {
			setShowButton(true);
		} else {
			setShowButton(false);
		}
		setFilteredTools(filtered);
	}, [flashCard, searchOption, searchQuery]);

	useEffect(() => {
		const getImageUrls = async (cards) => {
			const urls = await Promise.all(
				cards.map((tool) => getImageUrl(tool.main_image))
			);
			setImageUrls(urls);
		};
		if (cachedFlashCard) {
			const sortedData = sortFlashCardBySavedCard(
				cachedFlashCard,
				savedCard
			);
			setFlashCard(sortedData);
			getImageUrls(sortedData);

			return;
		}
		const options = query(
			collection(db, 'flash-card'),
			orderBy('click_count', 'desc')
		);
		getDocs(options)
			.then((snapshot) => {
				const data = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				const sortedData = sortFlashCardBySavedCard(data, savedCard);
				setFlashCard(sortedData);
				saveToCache(sortedData);
				getImageUrls(sortedData);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [cachedFlashCard, savedCard]);

	const sortFlashCardBySavedCard = (flashCardData, savedCardData) => {
		const savedCardIds = new Set(savedCardData);
		const sortedData = [...flashCardData].sort((a, b) => {
			if (savedCardIds.has(a.id) && !savedCardIds.has(b.id)) {
				return -1;
			} else if (!savedCardIds.has(a.id) && savedCardIds.has(b.id)) {
				return 1;
			} else {
				return 0;
			}
		});
		return sortedData;
	};

	useEffect(() => {
		async function fetchData() {
			if (user) {
				let save = await fetchUserProfileSavedCards(user.uid);
				if (save === undefined) {
					setSavedCard([]);
				} else {
					setSavedCard(save);
				}
			}
		}
		fetchData();
	}, [user]);

	const getImageUrl = async (path) => {
		const imageRef = ref(storage, path);
		try {
			const url = await getDownloadURL(imageRef);
			return url;
		} catch (error) {
			console.error(error);
		}
	};

	const addToSavedCards = async (uid, toolId) => {
		const docRef = doc(db, 'userProfiles', uid);
		const userProfileSnap = await getDoc(docRef);
		const savedCards = userProfileSnap.data().saved_cards || []; // default to empty array if saved_cards doesn't exist yet
		if (!savedCards.includes(toolId)) {
			// add toolId to saved_cards if it's not already there
			savedCards.push(toolId);
		}
		await updateDoc(docRef, { saved_cards: savedCards }); // update saved_cards field of userProfiles document
		setSavedCard([...savedCard, toolId]);
	};

	const removeFromSavedCards = async (uid, toolId) => {
		const docRef = doc(db, 'userProfiles', uid);
		const userProfileSnap = await getDoc(docRef);
		const savedCards = userProfileSnap.data().saved_cards || [];
		const updatedSavedCards = savedCards.filter((id) => id !== toolId); // remove toolId from saved_cards
		await updateDoc(docRef, { saved_cards: updatedSavedCards }); // update saved_cards field of userProfiles document
		setSavedCard(updatedSavedCards);
	};

	const fetchUserProfileSavedCards = async (uid) => {
		const docRef = doc(db, 'userProfiles', uid);
		const docSnap = await getDoc(docRef);
		return docSnap.data().saved_cards;
	};

	const handleBookmarkClick = async (toolId) => {
		const savedCards = await fetchUserProfileSavedCards(user.uid);
		if (savedCards === undefined) {
			addToSavedCards(user.uid, toolId);
		} else {
			console.log('Save cards', savedCards);
			const isSaved = savedCards.includes(toolId);
			if (isSaved) {
				removeFromSavedCards(user.uid, toolId);
			} else {
				addToSavedCards(user.uid, toolId);
			}
		}
	};

	const handleSearchInputChange = (event) => {
		setSearchQuery(event.target.value);
		setShowButton(
			filteredTools.length === 0 && event.target.value.length > 0
		);
	};

	const checkedSaved = (id) => {
		if (savedCard.includes(id)) {
			return <i className="fas fa-star" style={{ color: 'white' }}></i>;
		} else {
			return <i className="far fa-star"></i>;
		}
	};

	const getImageUrlsForTools = async (tools) => {
		const urls = await Promise.all(
			tools.map((tool) => getImageUrl(tool.main_image))
		);
		return urls;
	};

	const handleSearchOptionChange = async (newOption) => {
		setSearchOption(newOption);
		const filtered = flashCard.filter((tool) =>
			tool.categories.includes(newOption)
		);
		const urls = await getImageUrlsForTools(filtered);
		setImageUrls(urls);
	};


	const handleButtonLaunchClick = () => {
		if (user) {
			handleCheckout(selectedOptionLaunchpad);
		} else {
			alert('Please Login to use this feature!');
		}
	};

	const handleCheckout = async (price_plan) => {
		try {
			const response = await axios.post(
				'https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/stripe/create-checkout-session/',
				{
					price_plan: price_plan,
					uid: user.uid,
				},
				config
			);
			if (response.data.url !== undefined) {
				const amount = response.data.amount_total / 100;
				localStorage.setItem('sub_amount', amount);
				window.location.href = response.data.url;
			}
		} catch (error) {
			console.error(error);
			setError('Failed to create checkout session');
		}
	};

	const displayInCaps = (text) => {
		if (text === 'popular') {
			return 'Popular';
		} else if (text === 'learning') {
			return 'Learning';
		} else if (text === 'solve_it') {
			return 'Solve it';
		} else if (text === 'math_n_science') {
			return 'Math & Science';
			// return isMobile ? 'STEM' : 'Math & Science';
		} else if (text === 'creative') {
			return 'Creative';
		} else if (text === 'writing') {
			return 'Writing';
		} else if (text === 'coding') {
			return 'Coding';
		} else if (text === 'play') {
			return 'Play';
		}
	};

	const setCat = (text) => {
		if (text === 'learning') {
			return 'learning';
		} else if (text === 'solve_it') {
			return 'solver';
		} else if (text === 'math_n_science') {
			return 'math';
		} else if (text === 'creative') {
			return 'creative';
		} else if (text === 'writing') {
			return 'writing';
		} else if (text === 'coding') {
			return 'coding';
		} else if (text === 'play') {
			return 'play';
		}
	};
	return (
		<Layout title="Launchpad" style={{ padding: '2rem' }}>
			<Helmet>
				<title>Launchpad | Learning With AI</title>
			</Helmet>
			<div className="page">
				<div className="content-center">
					{isMobile ? (
						<h3 className="mt-2 ml-2 mb-2">
							Projects Sensei Can Help With
						</h3>
					) : (
						''
					)}
					<Nav
						className="nav-pills-primary d-flex align-items-center flex-wrap"
						pills
						role="tablist"
					>
						{categories.map((category, key) => (
							<NavItem key={key}>
								<NavLink
									to="/"
									className={classnames('pointer', {
										active: searchOption === category,
										gradBtn: searchOption === category,
									})}
									style={{
										padding: isSmallestMobile
											? '.5rem 1.45rem'
											: isSmallerMobile
											? '.5rem .7rem'
											: isMediumMobile
											? '.5rem 1.45rem'
											: '.7rem 1.45rem',
										// minWidth: '50px',
										marginRight: isSm ? '1px' : '10px',
									}}
									onClick={() =>
										handleSearchOptionChange(category)
									}
								>
									{displayInCaps(category)}
								</NavLink>
							</NavItem>
						))}
						<Form
							className="flex-grow-1"
							style={{
								flexShrink: 1,
								width: 'min-content',
								minWidth: '200px',
								paddingLeft: isMobile ? '.7rem' : '.5rem',
								paddingRight: isMobile ? '.7rem' : '.5rem',
								marginRight: isSmallestMobile
									? '0%'
									: isSmallMobile
									? '8%'
									: isMobile
									? '0%'
									: isTab
									? '0%'
									: '6rem',
							}}
						>
							<style>
								{`
								input.custom-input::selection {
									background:  hsla(234, 91%, 47%, 1);
									color: white;
								}

								input.custom-input::-moz-selection {
									background: hsla(234, 91%, 47%, 1);
									color: white;
								}

								input.custom-input::-webkit-selection {
									background: hsla(234, 91%, 47%, 1);
									color: white;
								}
								`}
							</style>
							<InputGroup
								className="position-relative d-flex"
								style={{
									marginTop: isSm ? '-2px' : '1px',
								}}
							>
								<span className="position-absolute mt-1 mt-sm-2 pt-1 ml-3 top-50 start-0 translate-middle-y">
									<i
										className="fas fa-search"
										style={{ marginTop: '3.5px' }}
									></i>
								</span>
								<Input
									className="custom-input pl-5 mt-0 mt-sm-1 rounded-pill"
									type="text"
									placeholder="Search Projects"
									value={searchQuery}
									onChange={handleSearchInputChange}
									onFocus={() => setInputFocused(true)}
									onBlur={() => setInputFocused(false)}
									style={{
										pointerEvents: inputFocused
											? 'none'
											: 'auto',
										border: '1px solid rgba(50, 50, 50, 0.9)',
									}}
								/>
								{showButton && (
									<button
										type="button"
										style={{
											width: 'fit-content',
											position: 'absolute',
											top: isButtonMobile ? 5 : 9,
											right: 5,
											padding: isButtonMobile
												? '.2rem 1rem'
												: '.2rem 1.5rem',
											cursor: 'pointer',
											outline: 'none',
											border: '0px',
										}}
										className="gradBtn text-white  rounded-pill"
										onClick={() => {
											setLaunchPad(null);
											navigate('/mode/learning');
										}}
									>
										{/* Start New Chat{' '} */}
										{isButtonMobile
											? ''
											: 'Start New Chat'}{' '}
										<i className="fas fa-arrow-right"></i>
									</button>
								)}
							</InputGroup>
						</Form>
					</Nav>
					<div className="tool-grid m-0 mt-3">
						{filteredTools.map((tool, index) => {
							return (
								<Card
									className="p-1 p-sm-3 m-0 card-raised d-flex card-mobile "
									key={index}
									onClick={() => {
										if (tool.categories.length === 2) {
											setLaunchPad({
												name: tool.name,
												description: tool.description,
												prefix: tool.prefix,
												intro: tool.intro,
												image: imageUrls[index],
											});
											let cat = setCat(
												tool.categories[1]
											);
											let name = tool.name.replace(
												/\//g,
												'-'
											);
											logEvent(
												analytics,
												'card_pressed',
												{
													card_id: tool.id,
													card_name: tool.name,
												}
											);
											navigate(
												`/mode/${cat}/${encodeURIComponent(
													name
												)}`
											);
										}
									}}
								>
									<div className="card-image mr-2 mr-sm-3">
										<LazyLoadImage
											src={imageUrls[index]}
											style={{
												width: '100%',
												height: '100%',
											}}
											placeholderSrc={chemistryImg}
											effect="blur"
										/>
									</div>
									<div className="card-content">
										<h4 className="mb-0 mb-sm-2">
											{tool.name}
										</h4>
										{isMidMobile ? (
											''
										) : (
											<div>{tool.description}</div>
										)}
									</div>
									<div
										className="bookmark"
										data-id={tool.id}
										onClick={(event) => {
											event.stopPropagation(); // prevent click from bubbling up to Card
											if (user) {
												const id =
													event.currentTarget.dataset
														.id;
												console.log(
													'Clicked bookmark for tool with ID:',
													id
												);
												handleBookmarkClick(id);
											} else {
												navigate(
													`/sign-up?from=${window.location.pathname}`
												);
											}
										}}
									>
										{user ? (
											checkedSaved(tool.id)
										) : (
											<i className="far fa-star"></i>
										)}
									</div>
								</Card>
							);
						})}
					</div>
				</div>
				<div className="right-sidebar">
					<Card
						className=" p-0 position-fixed"
						style={{
							width: '23vw',
							marginTop: '-20px !important',
						}}
					>
						<style>
							{`
							.modal-content {
								background-color: #000 !important;
								border-radius: 7px !important;
							}

							.selected {
								background: linear-gradient(rgba(92, 38, 211, 0.2), rgba(30, 132, 227, 0.2)) padding-box,
											linear-gradient(45deg, #AB00F5, #0B21E5) border-box;
								border: 2px solid transparent;
								border-radius: 3px !important;
								cursor: pointer;
								width: 12rem;
							}

							.not-selected {
								border-width: 2px;
								border-style: solid;
								bordercolor: #64748b;
								border-radius: 3px !important;
								cursor: pointer;
								width: 12rem;
							}

							.small_card {
								background: hsla(282, 100%, 48%, 1) !important;
							}

							
							.blue-purple:hover {
								background-image:
									linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%) !important;
								
							}

							.blue-purple{
								display: block;
								font-weight: 700;
								width: 80% !important;
								font-size: 1.15rem;
							}


							.check-icon {
								background: #0B21E5;
								background: hsla(282, 100%, 48%, 1);
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
							}

							.switchLaunch-label {
								transform: scale(-1);
							}

							.switchLaunch-input {
								height: 0;
								width: 0;
								visibility: hidden;
							}

							.switchLaunch-label {
								cursor: pointer;
								text-indent: -9999px;
								width: 40px;
								height: 20px;
								background: grey;
								display: block;
								border-radius: 100px;
								position: relative;
							}

							.switchLaunch-label:after {
								content: '';
								position: absolute;
								top: 5px;
								left: 5px;
								width: 10px;
								height: 10px;
								background: #fff;
								border-radius: 90px;
								transition: 0.3s;
							}

							.switchLaunch-input:checked + .switchLaunch-label {
								background: hsla(282, 100%, 48%, 1);
							}

							.switchLaunch-input:checked + .switchLaunch-label:after {
								left: calc(100% - 5px);
								transform: translateX(-100%);
							}

							.switchLaunch-label:active:after {
								width: 30px;
							}

							td {
								text-align: center;
								line-height: 1rem;
								padding: 0 !important;
							}

							th {
								line-height: .8rem;
								padding: 0;
								color: #fff !important;
							}

							tr {
								padding: 0;
							}

							.odd {
								background: rgb(225, 225, 225, .1) !important;
							}

							.x-me {
								font-size: 1.3rem;
							}

							.check-me {
								font-size: 1.3rem;
							}

							.head {
								font-weight: 700 !important;
								font-size: 1rem !important;
								color: #fff;
								text-align: center;
							}
						`}
						</style>
						<div
							className="relative rounded-lg"
							style={{
								backgroundColor: 'rgba(30, 132, 227, 0.2)',
								background:
									'linear-gradient(107.62deg, rgba(30, 132, 227, 0.2) 0%,rgba(92, 38, 211, 0.2) 100% )',
								boxShadow:
									'0px 20px 50px 0px rgba(0, 0, 0, 0.3)',
							}}
						>
							<div className="p-0">
								<div className="p-0">
									<div className="d-flex align-items-center justify-content-center text-center">
										<div>
											<h3 className="mb-2 mt-1 text-white font-weight-bolder">
												Big Brain Mode
												<span className="ml-2">
													<img
														src="/brain.png"
														style={{
															filter: 'invert(100%)',
															width: '1.5rem',
															height: '1.5rem',
															color: '#fff',
															fontWeight: '700',
														}}
														alt="brain"
													/>
												</span>
											</h3>
											<p
												className="text-white mb-2"
												style={{
													fontSize: '1.2em',
													lineHeight: '1.1rem',
												}}
											>
												Unlock your personal AI tutor.
											</p>

											{error && (
												<Alert
													className="mt-3 py-1"
													color="warning"
												>
													{error}
												</Alert>
											)}
										</div>
									</div>
									<div>
										<Table borderless>
											<thead>
												<tr>
													<th className="head"></th>
													<th className="head">
														Free
													</th>
													<th className="head">
														Big Brain
														<span className="ml-2">
															<img
																src="/brain.png"
																style={{
																	filter: 'invert(100%)',
																	width: '1rem',
																	height: '1rem',
																}}
																alt="brain"
															/>
														</span>
													</th>
												</tr>
											</thead>
											<tbody>
												<tr className="odd">
													<th scope="row">
														Questions
													</th>
													<td>25/month</td>
													<td>Unlimited</td>
												</tr>
												<tr>
													<th scope="row">
														AI Images
													</th>
													<td>5/month</td>
													<td>200/month</td>
												</tr>
												<tr className="odd">
													<th scope="row">Avatars</th>
													<td> 3</td>
													<td>Unlimited</td>
												</tr>
												<tr>
													<th scope="row">
														Infinite memory
													</th>
													<td>
														<i className="fa fa-times p-1 x-me"></i>
													</td>
													<td>
														<i className="fas fa-check p-1 check-me"></i>
													</td>
												</tr>
												<tr className="odd">
													<th scope="row">
														Personalizes to you over
														time
													</th>
													<td>
														<i className="fa fa-times p-1 x-me"></i>
													</td>
													<td>
														<i className="fas fa-check p-1 check-me"></i>
													</td>
												</tr>
												<tr>
													<th scope="row">
														Upload images to Sensei
													</th>
													<td>
														<i className="fa fa-times p-1 x-me"></i>
													</td>
													<td>
														<i className="fas fa-check p-1 check-me"></i>
													</td>
												</tr>
												<tr className="odd">
													<th scope="row">
														Sensei internet access
													</th>
													<td>
														<i className="fa fa-times p-1 x-me"></i>
													</td>
													<td>
														<i className="fas fa-check p-1 check-me"></i>
													</td>
												</tr>
												<tr >
													<th scope="row">Price</th>
													<td>Free</td>
													<td
														style={{
															fontWeight: '700',
														}}
													>
														$100/year or $10/month
													</td>
												</tr>
											</tbody>
										</Table>
									</div>
								</div>
								<div className="mt-0 mb-2 px-2">
									<button
										style={{
											background:
												'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
											cursor: 'pointer',
											outline: 'none',
											border: 0,
											borderRadius: '8px',
										}}
										className="m-auto py-3 text-white px-1 blue-purple mx-2"
										onClick={handleButtonLaunchClick}
									>
										Go Big Brain Mode
									</button>
									<div className="d-flex align-items-start justify-content-center mt-1 w-100 py-2">
										<div className="d-flex flex-column justify-content-between align-items-center">
											<p className="text-white">
												<b
													style={{
														fontWeight: '700',
													}}
												>
													Yearly
												</b>
											</p>
											<p
												className="py-0 px-2 rounded small_card text-white"
												style={{
													fontSize: '.8rem',
												}}
											>
												Save 20%
											</p>
										</div>
										<div className="d-flex align-items-center mx-3">
											<input
												type="checkbox"
												id="switchLaunch"
												className="switchLaunch-input"
												checked={isCheckedLaunchpad}
												onChange={(e) => {
													const checked =
														e.target.checked;
													setIsCheckedLaunchpad(
														checked
													);
													setSelectedOptionLaunchpad(
														e.target.checked
															? 'Pro'
															: 'Basic'
													);
												}}
											/>
											<label
												htmlFor="switchLaunch"
												className="switchLaunch-label"
											>
												Toggle
											</label>
										</div>
										<div
											className={`d-flex px-1 pt-0 mt-0 align-items-start`}
										>
											<div>
												<p className="text-white">
													<b
														style={{
															fontWeight: '700',
														}}
													>
														Monthly
													</b>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Card>
				</div>
			</div>
		</Layout>
	);
}
