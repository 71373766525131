import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// styles
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/css/nucleo-icons.css';
import 'assets/scss/blk-design-system-pro-react.scss?v1.2.0';

// Providers
import { AuthProvider, ProtectedRoute } from 'contexts/AuthContext';
import { BannerProvider } from 'contexts/BannerContext';
import { RouteProvider } from 'contexts/RouteContext';
import { LaunchProvider } from 'contexts/LaunchContext';
import { TypeProvider } from 'contexts/TypeAnimationContext';

// Main pages
import Login from 'views/Login';
import ForgotPassword from 'views/ForgotPassword';
import Signup from 'views/Signup';
import Settings from 'views/Settings';
import Dashboard from 'views/Dashboard';
import Plans from 'views/Plans';
import CreateAvatar from 'views/avatars/CreateAvatar';
import EditAvatar from 'views/avatars/EditAvatar';
import AvatarList from 'views/avatars/AvatarList';
import Username from 'components/Signup/Username';
import Chat from 'views/Chat';
import ShareChat from 'views/ShareChat';
import OnboardingPage from 'views/Onboarding';
import NotFoundPage from 'views/Error404';

// helpers
import modes from 'helpers/modes.json';

const root = ReactDOM.createRoot(document.getElementById('root'));

const updatedModes = Object.keys(modes).map((mode) => {
	return { ...modes[mode], key: mode };
});

root.render(
	<TypeProvider>
		<AuthProvider>
			<LaunchProvider>
				<BannerProvider>
					<BrowserRouter>
						<RouteProvider>
							<Routes>
								<Route
									path="/"
									element={
										<ProtectedRoute
											element={<Dashboard />}
										/>
									}
								/>
								<Route
									path="/plans"
									element={
										<ProtectedRoute element={<Plans />} />
									}
								/>
								<Route
									path="/create-avatar"
									element={
										<ProtectedRoute
											element={<CreateAvatar />}
										/>
									}
								/>
								<Route
									path="/edit-avatar/:docId"
									element={
										<ProtectedRoute
											element={<EditAvatar />}
										/>
									}
								/>
								<Route
									path="/avatar-list"
									element={
										<ProtectedRoute
											element={<AvatarList />}
										/>
									}
								/>
								<Route
									path="/dashboard"
									element={<Navigate to="/" />}
								/>
								<Route
									path="/share-chat/:parrentDocId/:docId"
									element={
										<ShareChat
											mode={updatedModes[0]}
											key={updatedModes[0].key}
										/>
									}
								/>

								{/* <Route path='/verify-phone' render={(props) => <VerifyPhone {...props} />} /> */}
								<Route
									path="/onboarding"
									element={<OnboardingPage />}
								/>
								<Route
									path="/add-username"
									element={
										<ProtectedRoute
											element={<Username />}
										/>
									}
								/>
								<Route path="/login" end element={<Login />} />
								<Route
									path="/sign-up"
									end
									element={<Signup />}
								/>
								<Route
									path="/settings"
									element={
										<ProtectedRoute
											element={<Settings />}
										/>
									}
								/>

								{updatedModes.map((mode) => {
									return (
										<Route
											path={`/mode/${mode.key}`}
											end
											key={mode.key}
											element={
												<ProtectedRoute
													element={
														<Chat
															mode={mode}
															key={mode.key}
														/>
													}
												/>
											}
										/>
									);
								})}
								{updatedModes.map((mode) => {
									return (
										<Route
											path={`/mode/${mode.key}/:topic`}
											end
											key={mode.key}
											element={
												<ProtectedRoute
													element={
														<Chat
															mode={mode}
															key={mode.key}
														/>
													}
												/>
											}
										/>
									);
								})}
								<Route
									path="/forgot-password"
									element={<ForgotPassword />}
								/>
								<Route path="*" element={<NotFoundPage />} />
							</Routes>
						</RouteProvider>
					</BrowserRouter>
				</BannerProvider>
			</LaunchProvider>
		</AuthProvider>
	</TypeProvider>
);
