import React, { useRef } from 'react';
import { bool, node } from 'prop-types';
import { useTransition, animated } from '@react-spring/web';

const Inner = React.forwardRef(({ children }, ref) => (
	<div ref={ref}>
		<style>
			{`
        .inner &:before,
        &:after {
          content: '';
          display: table;
        }
      `}
		</style>
		<div className="inner">{children}</div>
	</div>
));

const visibleStyle = { height: 'auto', opacity: 1, overflow: 'visible' };
const hiddenStyle = { opacity: 0, height: 0, overflow: 'hidden' };

function getElementHeight(ref) {
	return ref.current ? ref.current.getBoundingClientRect().height : 0;
}

/** The children of this component will slide down on mount and will slide up on unmount */
const Slider = ({ isVisible, children, forceSlideIn }) => {
	const isVisibleOnMount = useRef(isVisible && !forceSlideIn);
	const containerRef = useRef(null);
	const innerRef = useRef(null);

	const transitions = useTransition(isVisible, {
		from: isVisibleOnMount.current ? visibleStyle : hiddenStyle,
		enter: () => async (next) => {
			const height = getElementHeight(innerRef);
			await next({ height, opacity: 1, overflow: 'hidden' });
			await next(visibleStyle);
		},
		leave: () => async (next) => {
			const height = getElementHeight(containerRef);
			await next(hiddenStyle);
			await next({ height, overflow: 'hidden' });
			isVisibleOnMount.current = false;
		},
		unique: true,
	});

	return transitions((style, item) =>
		item ? (
			<animated.div
				ref={containerRef}
				style={{
					...style,
					position: 'absolute',
					bottom: 0,
                    marginBottom: "2rem",
					zIndex: item ? 1 : -1,
					transform: item ? 'translateY(0%)' : 'translateY(100%)',
				}}
			>
				<Inner ref={innerRef}>{children}</Inner>
			</animated.div>
		) : null
	);
};

Slider.defaultProps = {
	forceSlideIn: false,
};

Slider.propTypes = {
	/** Should the component mount it's children and slide down */
	isVisible: bool.isRequired,
	/** Makes sure the component always slides in on mount. Otherwise it will be immediately visible if isVisible is true on mount */
	forceSlideIn: bool,
	/** The slidable content elements */
	children: node.isRequired,
};

export default Slider;
