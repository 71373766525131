import React from 'react';
import Slider from './Slider';
import InternetAccess from './InternetAccess';
import { useState, useEffect, useRef } from 'react';


const MyCarousel = ({ children }) => {
	const scrollRef = useRef(null);
	const [isOverflowingLeft, setIsOverflowingLeft] = useState(false);
	const [isOverflowingRight, setIsOverflowingRight] = useState(false);

	const checkOverflow = () => {
		const element = scrollRef.current;
		setIsOverflowingLeft(element.scrollLeft > 0);
		setIsOverflowingRight(
			element.scrollWidth > element.clientWidth + element.scrollLeft
		);
	};

	useEffect(() => {
		checkOverflow();
		const handleResize = () => {
			checkOverflow();
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const scroll = (scrollOffset) => {
		scrollRef.current.scrollLeft += scrollOffset;
		checkOverflow();
	};

	return (
		<div className="d-flex align-items-center position-relative">
			{isOverflowingLeft && (
				<i
					className="far fa-arrow-alt-circle-left text-white mr-1"
					style={{ fontSize: '1.3rem' }}
					onClick={() => scroll(-100)}
				></i>
			)}
			<div
				ref={scrollRef}
				style={{
					display: 'flex',
					overflowX: 'auto',
					scrollbarWidth: 'none', // Firefox
					msOverflowStyle: 'none', // IE and Edge
				}}
			>
				{/* For WebKit browsers */}
				<style>
					{`
					::-webkit-scrollbar {
						display: none;
					}
				`}
				</style>
				{children}
			</div>
			{isOverflowingRight && (
				<i
					className="far fa-arrow-alt-circle-right text-white ml-1"
					style={{ fontSize: '1.3rem' }}
					onClick={() => scroll(100)}
				></i>
			)}
		</div>
	);
};

export default function Idea({
	prompt,
	setPromptText,
	isMobile,
	isVisible,
	setIsVisible,
	isInternet,
	setIsInternet,
	setActiveComponent,
}) {
	return (
		<>
			<MyCarousel>
				<style>
					{`
					.bubble {
						background: black;
						border-radius: 10px;
						padding: .5rem;
					}
					.bubble-wrapper {
						background: linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%);
						border-radius: 10px;
						border-bottom-left-radius: 0;
						padding: .5rem;
						padding-top: 1.5rem;
						padding-right: 1rem;
						position: relative;
					}
					.bubble-btn {
						background: linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%);
						outline: none;
						border: 0;
						border-radius: 7px;
					}
					`}
				</style>
				<Slider isVisible={isVisible}>
					<>
						<div className="bubble-wrapper">
							<i
								className="fa fa-times"
								style={{
									position: 'absolute',
									top: 5,
									right: 8,
									color: '#fff',
									cursor: 'pointer',
								}}
								onClick={() => setIsVisible(!isVisible)}
							></i>
							{prompt.map((item, key) => {
								return (
									<p
										className="bubble"
										key={key}
										onClick={() => {
											setPromptText(item);
										}}
									>
										{item}
									</p>
								);
							})}
						</div>
					</>
				</Slider>
				<button
					className="px-2 py-1 text-white mb-1 mr-2 bubble-btn"
					style={{
						borderTopLeftRadius: isVisible ? '0px' : '',
						borderTopRightRadius: isVisible ? '0px' : '',
						borderTopLeftRadius: !isVisible
							? isMobile
								? '15px'
								: ''
							: '',
						borderTopRightRadius: !isVisible
							? isMobile
								? '15px'
								: ''
							: '',
						borderBottomLeftRadius: !isVisible
							? isMobile
								? '15px'
								: ''
							: '',
						borderBottomRightRadius: !isVisible
							? isMobile
								? '15px'
								: ''
							: '',
					}}
					onClick={() => {
						setActiveComponent('isVisible');
						setIsVisible(!isVisible);
					}}
				>
					💡 {` `} Ideas
					{isVisible ? (
						<i className="fas fa-caret-up ml-1"></i>
					) : (
						<i className="fas fa-caret-down ml-1"></i>
					)}
				</button>
				<InternetAccess
					isMobile={isMobile}
					isInternet={isInternet}
					setIsInternet={setIsInternet}
					setActiveComponent={setActiveComponent}
				/>
			</MyCarousel>
		</>
	);
}