window.env = {
  REACT_APP_NODE_ENV: "development",
  REACT_APP_FAST_API_URL:
    "https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1",
};

export const STRIPE_PUBLISHABLE =
  window.env.REACT_APP_NODE_ENV === "production"
    ? "pk_live_MY_PUBLISHABLE_KEY"
    : window.env.REACT_APP_PK_TEST_MY_PUBLISHABLE_KEY;

export const FAST_API_URL = window.env.REACT_APP_FAST_API_URL;

export const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};
