
import React, { useState, useEffect, useRef } from "react";

// reactstrap components
import { Nav, NavItem, NavLink, TabPane, TabContent } from "reactstrap";

import { useLocation, useNavigate } from 'react-router-dom';

import Layout from "components/Layout";
import Account from "./Account";
import Plans from "./stripe/Plans";
import CancelSub from "./stripe/CancelSub";
import PauseSub from "./stripe/PauseSub";

import { config } from '../constants/stripe';
import axios from 'axios';
import { db } from 'lib/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from 'contexts/AuthContext';

export default function AccountSettings() {
	const [activeTab, setActiveTabs] = useState(1);
	let [message, setMessage] = useState('');
	let [success, setSuccess] = useState(false);
	const sessionIdRef = useRef('');
	let [showCancel, setShowCancel] = useState(false);
	let [showPause, setShowPause] = useState(false);
	let [loading, setLoading] = useState(false);
	let [subscribed, setSubscribed] = useState(false);
	let [pause, setPause] = useState(false);
	let [date, setDate] = useState(null);
	let [type, setType] = useState(null);
  	const { user } = useAuth();
    let location = useLocation();
    const navigate = useNavigate();

  // Update the active tab based on the browser URL
//   useEffect(() => {
//     const hash = window.location.hash;
//     if (hash === "#billings") {
//       setActiveTabs(2);
//     } else {
//       setActiveTabs(1);
//     }
//   }, []);

  // Update the browser URL when the active tab changes
  useEffect(() => {
		const params = new URLSearchParams(location.search);

		if (params.get('success')) {
			setSuccess(true);
			sessionIdRef.current = params.get('session_id');
			setActiveTabs(2);
			let url = `${location.pathname}#billings${location.search}`;
			navigate(url);
		}

		if (params.get('canceled')) {
			navigate("/");
			setSuccess(false);
			setMessage(
				"Subscription canceled -- continue to explore around and checkout when you're ready."
			);
			setActiveTabs(2);

			// let url = `${location.pathname}#billings${location.search}`;
			// navigate(url);
		}

		if (!params.get('success') && !params.get('canceled')) {
			const hash = window.location.hash;
			if (hash === '#billings') {
				let url = `${location.pathname}#billings`;
				setActiveTabs(2);
				navigate(url);
			} else {
				let url = `${location.pathname}#account`;
				setActiveTabs(1);
				navigate(url);
			}
		}
  }, [activeTab, navigate, location.pathname, location.search]);


  useEffect(() => {
		const fetchUserProfileSubscribed = async (uid) => {
			const docRef = doc(db, 'userProfiles', uid);
			const docSnap = await getDoc(docRef);
			return docSnap.data().is_subscribed;
		};

		const fetchUserProfilePause = async (uid) => {
			const docRef = doc(db, 'userProfiles', uid);
			const docSnap = await getDoc(docRef);
			return docSnap.data().is_paused;
		};

		const fetchUserProfileExpireDate = async (uid) => {
			const docRef = doc(db, 'userProfiles', uid);
			const docSnap = await getDoc(docRef);
			return docSnap.data().expire_at;
		};

		const fetchUserProfileSubType = async (uid) => {
			const docRef = doc(db, 'userProfiles', uid);
			const docSnap = await getDoc(docRef);
			return docSnap.data().sub_type;
		};

		const getSubscribedAndPaused = async () => {
			const subscribed = await fetchUserProfileSubscribed(user.uid);
			const paused = await fetchUserProfilePause(user.uid);
			const date = await fetchUserProfileExpireDate(user.uid);
			const type = await fetchUserProfileSubType(user.uid);
			setSubscribed(subscribed);
			setPause(paused);
			setDate(date);
			setType(type);
		};

		if (user && user.uid) {
			getSubscribedAndPaused();
		}
  }, [user, setSubscribed, setPause]);


    const handleActiveTab = (num) => {
        let url;
        if (num === 1){

            url = `${location.pathname}#account`; 
            setActiveTabs(1);
            setMessage("")
            setSuccess(false)
			sessionIdRef.current = "";
        } else {
            url = `${location.pathname}#billings`; 
            setActiveTabs(2);
        }
        navigate(url);
    }

  const fetchUserProfileSessionId = async (uid) => {
		const docRef = doc(db, 'userProfiles', uid);
		const docSnap = await getDoc(docRef);
		return docSnap.data().checkout_session_id;
  };

  const fetchUserProfileSubscribed = async (uid) => {
		const docRef = doc(db, 'userProfiles', uid);
		const docSnap = await getDoc(docRef);
		return docSnap.data().is_subscribed;
  };

  const handlePortal = async () => {
		if (await fetchUserProfileSubscribed(user.uid)) {
			try {
				let sessionId = await fetchUserProfileSessionId(user.uid);
				const response = await axios.post(
					`https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/stripe/create-portal-session/`,
					// `http://127.0.0.1:8000/api/v1/stripe/create-portal-session/`,
					{
						session_id: sessionId,
					},
					config
				);
				window.location.href = response.data.url;
			} catch (error) {
				console.error(error);
			}
		} else {
			alert('Please Subscribe to manage your billings');
		}
  };

  // Close the cancel sub modal
  const handleCloseCancelSub = () => {
    setShowCancel(false);
  }

  // Open the cancel sub modal
  const handleOpenCancelSub = () => {
    setShowCancel(true);
  }

  // Open Pause sub model
  const handleOpenPauseSub = () => {
    setShowPause(true);
  }

  // Close Pause sub model
const handleClosePauseSub = () => {
	setShowPause(false);
};
  
  const handlePauseSub = async () => {
	if (await fetchUserProfileSubscribed(user.uid)) {
			try {
				const response = await axios.post(
					`https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/stripe/pause-subscription/`,
					// `http://127.0.0.1:8000/api/v1/stripe/pause-subscription/`,
					{
						uid: user.uid,
					},
					config
				);
				setLoading(false);
				if (response.data === true) {
					alert("Your subscription has been successfully paused!");
					setShowPause(false);
				} else {
					alert("Pause not successful. If issue persist contact support.");
				}
				console.log("This is fun", response.data)
			} catch (error) {
				console.error(error);
				alert('An error occurred. If error persist contact support');
				setLoading(false);
			}
		} else {
			alert('Please Subscribe to pause your subscriptions');
			setLoading(false);
		}
  }



  return (
		<Layout>
			<div className="m-4">
				<Nav className="nav d-flex nav-tabs nav-tabs-primary" tabs>
					<NavItem>
						<NavLink
							className={activeTab === 1 ? 'active' : ''}
							style={{
								borderColor: 'transparent',
								outline: 'none',
								border: '0',
							}}
							href="#account"
							onClick={(e) => {
								e.preventDefault();
								handleActiveTab(1);
							}}
						>
							Account
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={activeTab === 2 ? 'active' : ''}
							style={{
								borderColor: 'transparent',
								outline: 'none',
								border: '0',
							}}
							href="#billings"
							onClick={(e) => {
								e.preventDefault();
								handleActiveTab(2);
							}}
						>
							Billing
						</NavLink>
					</NavItem>
				</Nav>
				<CancelSub
					isOpen={showCancel}
					onClose={handleCloseCancelSub}
					openNext={handleOpenPauseSub}
					toggle={setShowCancel}
					uid={user.uid}
				/>
				<PauseSub
					isOpen={showPause}
					onClose={handleClosePauseSub}
					onPause={handlePauseSub}
					handlePortal={handlePortal}
					loading={loading}
					setLoading={setLoading}
					toggle={setShowPause}
				/>
				<TabContent activeTab={'setting' + activeTab}>
					<TabPane tabId="setting1">
						<Account handlePortal={handlePortal} />
					</TabPane>
					<TabPane tabId="setting2">
						<Plans
							message={message}
							setMessage={setMessage}
							success={success}
							setSuccess={setSuccess}
							sessionIdRef={sessionIdRef}
							handleOpenCancelSub={handleOpenCancelSub}
							pause={pause}
							subscribed={subscribed}
							date={date}
							type={type}
						/>
					</TabPane>
				</TabContent>
			</div>
		</Layout>
  );
}
