import { useState, useEffect, useRef } from 'react';
import { Button, UncontrolledTooltip, Tooltip } from 'reactstrap';
import UploadOptions from './UploadOptions';
import { IoSend } from 'react-icons/io5';
import ImageUploadModal from './ImageUploadModal';
import Idea from './Idea';
import WritingIdea from './WritingIdea';

export default function Input({
	onSubmit,
	useable,
	mode,
	uploadedImage,
	setUploadedImage,
	setWritingPrefix,
	isVisible,
	setIsVisible,
	isGradeLevel,
	setIsGradeLevel,
	isTone,
	setIsTone,
	isLength,
	setIsLength,
	isPersona,
	setIsPersona,
	isInternet,
	setIsInternet,
}) {
	const [value, setValue] = useState('');
	const [showUploadModal, setShowUploadModel] = useState(false);
	const [showImageUploadModal, setShowImageUploadModal] = useState(false);
	const containerRef = useRef();
	const [width, setWidth] = useState(window.innerWidth);
	const [open, setOpen] = useState('');
	const [activeComponent, setActiveComponent] = useState('');
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const toggle = () => setTooltipOpen(!tooltipOpen);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	useEffect(() => {
		if (activeComponent === 'isVisible') {
			setIsVisible(true);
			setIsGradeLevel(false);
			setIsTone(false);
			setIsLength(false);
			setIsPersona(false);
			setIsInternet(false);
		} else if (activeComponent === 'isGradeLevel') {
			setIsGradeLevel(true);
			setIsVisible(false);
			setIsTone(false);
			setIsLength(false);
			setIsPersona(false);
			setIsInternet(false);
		} else if (activeComponent === 'isTone') {
			setIsTone(true);
			setIsVisible(false);
			setIsGradeLevel(false);
			setIsLength(false);
			setIsPersona(false);
			setIsInternet(false);
		} else if (activeComponent === 'isLength') {
			setIsLength(true);
			setIsVisible(false);
			setIsGradeLevel(false);
			setIsTone(false);
			setIsPersona(false);
			setIsInternet(false);
		} else if (activeComponent === 'isPersona') {
			setIsPersona(true);
			setIsVisible(false);
			setIsGradeLevel(false);
			setIsTone(false);
			setIsLength(false);
			setIsInternet(false);
		} else if (activeComponent === 'isInternet') {
			setIsInternet(true);
			setIsPersona(false);
			setIsVisible(false);
			setIsGradeLevel(false);
			setIsTone(false);
			setIsLength(false);
		}
	}, [activeComponent, mode]);

	const isMobile = width <= 450;
	const isTab = width <= 1000;
	const isBiggerTab = width <= 1300;

	const handlePrefix = (prefix) => {
		handleSend(prefix + ': ');
	};

	const handleImageUpload = (image) => {
		setUploadedImage(image);
		setShowImageUploadModal(false);
	};

	const handleKeyDown = (e) => {
		setIsVisible(false);
		setIsGradeLevel(false);
		setIsTone(false);
		setIsLength(false);
		setIsPersona(false);
		setIsInternet(false);
		if (!e.shiftKey) {
			if (e.key === 'Enter') {
				e.preventDefault();
				handleSend();
			}
		}
	};

	const textareaRef = useRef(null);
	const buttonRef = useRef(null);

	useEffect(() => {
		textareaRef.current.style.height = '2.5rem';
		buttonRef.current.style.height = '2.5rem';

		const scrollHeight = textareaRef.current.scrollHeight;
		if (scrollHeight > 96) {
			textareaRef.current.style.height = '6rem';
			if (!isMobile) {
				buttonRef.current.style.height = '6rem';
			}
		} else {
			textareaRef.current.style.height = scrollHeight + 'px';
			if (!isMobile) {
				buttonRef.current.style.height = scrollHeight + 'px';
			}
		}
	}, [value]);

	const handleChange = (e) => {
		setValue(e.target.value);
	};

	const handleValue = (item) => {
		if (value !== '') {
			setValue(value + ' ' + item);
		} else {
			setValue(item);
		}
	};

	const mobileKeyDown = (e) => {
		// do nothing
		setIsVisible(false);
		setIsGradeLevel(false);
		setIsTone(false);
		setIsLength(false);
		setIsPersona(false);
		setIsInternet(false);
	};

	const handleSend = (prefix = '') => {
		if (!value.trim()) return;
		if (!useable) return;

		onSubmit({
			visibleText: value,
			actualValue: prefix + value,
		});
		setValue('');
		setIsVisible(false);
		setIsGradeLevel(false);
		setIsTone(false);
		setIsLength(false);
		setIsPersona(false);
		setIsInternet(false);
	};

	const uploadImageContainer = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '-4px',
		borderRadius: '4px',
		overflow: 'hidden',
		maxWidth: '100%',
		height: '150px',
		position: 'fixed',
		bottom: '12vh',
		right: isMobile ? '11%' : isTab ? '9%' : isBiggerTab ? '9%' : '19%',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
		padding: '1rem',
	};

	const uploadImageContainerImg = {
		maxWidth: '100%',
		maxHeight: '100%',
		position: 'relative',
	};

	const handleClickInside = (event) => {
		event.stopPropagation();
	};

	return (
		<>
			{/* Add the uploaded image display */}
			{uploadedImage && (
				<>
					<div
						className="uploaded-image-container"
						style={uploadImageContainer}
					>
						<img
							src={URL.createObjectURL(uploadedImage)}
							alt="Uploaded"
							style={uploadImageContainerImg}
						/>
						<i
							className="fas fa-times-circle"
							style={{
								position: 'absolute',
								top: 0,
								right: '0',
								color: 'red',
								fontSize: '1rem',
							}}
							onClick={() => setUploadedImage(null)}
						></i>
					</div>
					<br />
				</>
			)}
			<div
				className="prompt-form-container pt-4 pt-md-1"
				ref={containerRef}
				onClick={handleClickInside}
			>
				<UploadOptions
					onSubmit={(result) => setValue((v) => v + result)}
					isOpen={showUploadModal}
					onClose={() => setShowUploadModel(false)}
				/>
				<ImageUploadModal
					isOpen={showImageUploadModal}
					onClose={() => setShowImageUploadModal(false)}
					onImageUpload={handleImageUpload}
				/>
				<div className="prompt-form">
					<div style={{ width: '90%' }}>
						<div>
							{mode.key === 'writing' ? (
								<WritingIdea
									prompt={mode.examples}
									setPromptText={handleValue}
									isMobile={isMobile}
									setIsVisible={setIsVisible}
									isVisible={isVisible}
									isGradeLevel={isGradeLevel}
									setIsGradeLevel={setIsGradeLevel}
									isTone={isTone}
									setIsTone={setIsTone}
									isLength={isLength}
									setIsLength={setIsLength}
									isPersona={isPersona}
									setIsPersona={setIsPersona}
									setWritingPrefix={setWritingPrefix}
									setActiveComponent={setActiveComponent}
									isInternet={isInternet}
									setIsInternet={setIsInternet}
								/>
							) : (
								<Idea
									prompt={mode.examples}
									setPromptText={handleValue}
									isMobile={isMobile}
									setIsVisible={setIsVisible}
									isVisible={isVisible}
									isInternet={isInternet}
									setIsInternet={setIsInternet}
									setActiveComponent={setActiveComponent}
								/>
							)}
						</div>
						<form
							className="input-wrapper p-0"
							onSubmit={(e) => e.preventDefault()}
						>
							{isMobile ? (
								<>
									<button
										className="input-btn upload-file"
										// onClick={() => {
										// 	if (mode.key === 'creativeImage') {
										// 		setShowImageUploadModal(true);
										// 	} else {
										// 		setShowUploadModel(true);
										// 	}
										// }}
										id="upload-file"
										type="button"
									>
										{value ? (
											<i
												className="fa fa-chevron-right"
												style={{ alignSelf: 'center' }}
											/>
										) : (
											<i
												className="fas fa-camera"
												style={{ alignSelf: 'center' }}
											/>
										)}
									</button>
									<Tooltip
										placement="top"
										isOpen={tooltipOpen}
										target="upload-file"
										toggle={toggle}
									>
										Coming soon!
									</Tooltip>
								</>
							) : (
								<>
									<button
										className="input-btn upload-file"
										// onClick={() => {
										// 	// if (mode.key === 'creativeImage') {
										// 	// 	setShowImageUploadModal(true);
										// 	// } else {
										// 	// 	setShowUploadModel(true);
										// 	// }
										// 	alert("Comming soon!")
										// }}
										id="upload-file"
										type="button"
									>
										<i
											className="fas fa-camera"
											style={{ alignSelf: 'center' }}
										/>
									</button>
									<Tooltip
										placement="top"
										isOpen={tooltipOpen}
										target="upload-file"
										toggle={toggle}
									>
										Coming soon!
									</Tooltip>
								</>
							)}

							<textarea
								type="text"
								className="input"
								value={value}
								ref={textareaRef}
								onKeyDown={
									!isMobile ? handleKeyDown : mobileKeyDown
								}
								onChange={handleChange}
								placeholder="Enter your message here"
							/>
							<button
								className="input-btn send-btn"
								onClick={() => handleSend()}
								type="submit"
								ref={buttonRef}
								style={{
									opacity: !value ? '0.5' : '1.0',
								}}
								disabled={!value}
							>
								<IoSend
									style={{
										color: 'white',
										fontSize: '1.9vh',
									}}
								/>
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}
