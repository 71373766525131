// react
import React from "react";

// components
import ShareCompoment from "components/ShareComponent/ShareComponent";
import Layout from "components/Layout";

// contexts
import ChatProvider from "contexts/ChatContext";

export default function ShareChat({ mode }) {
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        document.body.classList.add("chat-page");
        return function cleanup() {
            document.body.classList.remove("chat-page");
        };
    }, []);

    return (
        <ChatProvider mode={mode}>
            <Layout isShareComponent={true}>
                <ShareCompoment />
            </Layout>
        </ChatProvider>
    );
}
