import { useState, useEffect } from 'react';
import Slider from './Slider';
import { useAuth } from 'contexts/AuthContext';
import { db } from 'lib/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useLaunch } from 'contexts/LaunchContext';
import Switch from 'react-switch';

export default function Internet({
	isMobile,
	isInternet,
	setIsInternet,
	setActiveComponent,
}) {
	const { user, checked, setChecked, subscribe } = useAuth();
	const { setBigBrain } = useLaunch();

	const handleChange = (e) => {
		if (subscribe) {
			setChecked(e);
			updateUserInternetAccess(user.uid, e);
		}
	};

	useEffect(() => {
		const startUp = async () => {
			const docRef = doc(db, 'userProfiles', user.uid);
			const docSnap = await getDoc(docRef);

			if (docSnap && docSnap.exists()) {
				setChecked(docSnap.data().internet || false);
			}
		};
		startUp();
	}, [user.uid]);

	//updates the number if images generated by the user
	const updateUserInternetAccess = async (uid, num) => {
		const docRef = doc(db, 'userProfiles', uid);
		await updateDoc(docRef, { internet: num });
	};

	return (
		<>
			<style>
				{`
					
				`}
			</style>
			<Slider isVisible={isInternet}>
				<>
					
				</>
			</Slider>
			<button
				className="px-2 py-1 text-white mb-1 mr-2 bubble-btn d-flex align-items-center"
				style={{
					borderTopLeftRadius: isInternet ? '0px' : '',
					borderTopRightRadius: isInternet ? '0px' : '',
					borderTopLeftRadius: !isInternet
						? isMobile
							? '15px'
							: ''
						: '',
					borderTopRightRadius: !isInternet
						? isMobile
							? '15px'
							: ''
						: '',
					borderBottomLeftRadius: !isInternet
						? isMobile
							? '15px'
							: ''
						: '',
					borderBottomRightRadius: !isInternet
						? isMobile
							? '15px'
							: ''
						: '',
				}}
				onClick={() => {
					if (!subscribe) {
						setBigBrain(true);
						return;
					}
				}}
			>
				🔍 {` `}Internet
				<Switch
					onChange={(e) => handleChange(e)}
					checked={checked}
					className="react-switch ml-1"
					onColor="#ab00f5"
					height={20}
					width={48}
				/>
			</button>
		</>
	);
}
