
import React, {useState, useEffect} from "react";
import Layout from "components/Layout";
// reactstrap components
import {
	FormGroup,
	Row,
	Col,
	Form,
	FormText,
	Alert,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from 'reactstrap';
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import {
	doc,
	collection,
	addDoc,
	getDoc,
	query,
	orderBy,
	getDocs
} from 'firebase/firestore';
import { db, storage } from 'lib/firebase';
import { uploadBytes, getDownloadURL, ref } from 'firebase/storage';
import { randomString } from 'helpers/creativeImage';
import { resizeFile } from 'helpers/convert';
import { useAuth } from "contexts/AuthContext";
import { useLaunch } from 'contexts/LaunchContext';
import { useNavigate } from 'react-router-dom';
import base64 from 'base64-js';
import { config } from 'constants/stripe';
import axios from 'axios';
import Filter from 'bad-words';
import { isMobile } from "react-device-detect";

const CACHE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours

export default function CreateAvatar() {
    const { register, handleSubmit, reset, watch, setValue } = useForm();
	const { user } = useAuth();
	const { isCharacterOpen, setIsCharacterOpen, setCharacter, setS_char } =
		useLaunch();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false)
	const [isImageLoading, setIsImageLoading] = useState(false)
	const [isMessage, setIsMessage] = useState("")
	const [imageText, setImageText] = useState("")
	const [visible, setVisible] = useState(true);
	const [showAdvanced, setShowAdvanced] = useState(false);
	const [modal, setModal] = useState(false);
	const [imageView, setImageView] = useState(null);
	const [imageBlob, setImageBlob] = useState(null);
	// const [name, setName] = useState("");
	const textAreaContent = watch('craft_message', '');
	const name = watch('name', '');
	const [charCount, setCharCount] = useState(0);
	const [badWordsInFields, setBadWordsInFields] = useState({});
	const [canSubmit, setCanSubmit] = useState(true);

	const filter = new Filter();

	const introMessageInput = watch('introMessage');
	const personalityTextInput = watch('personalityText');
	const shortDescriptionInput = watch('short_description');
	const longDescriptionInput = watch('long_description');
	const craftMessageInput = watch('craft_message');

	useEffect(() => {
		const newBadWordsInFields = {
			name: filter.isProfane(name),
			introMessage: filter.isProfane(introMessageInput),
			personalityText: filter.isProfane(personalityTextInput),
			short_description: filter.isProfane(shortDescriptionInput),
			long_description: filter.isProfane(longDescriptionInput),
			craft_message: filter.isProfane(craftMessageInput),
		};
		setBadWordsInFields(newBadWordsInFields);
		setCanSubmit(!Object.values(newBadWordsInFields).includes(true));
	}, [
		name,
		introMessageInput,
		personalityTextInput,
		shortDescriptionInput,
		longDescriptionInput,
		craftMessageInput,
	]);
	

	const toggle = () => setModal(!modal);

  	const onDismiss = () => setVisible(false);

	const getchar = () => {
		const options = query(
			collection(db, 'characters'),
			orderBy('click_count', 'desc')
		);

		getDocs(options)
			.then((snapshot) => {
				const data = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				saveToCache(data);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	const saveToCache = (data) => {
		localStorage.setItem('character', JSON.stringify(data));
		localStorage.setItem('characterExpiry', Date.now() + CACHE_EXPIRY_TIME);
	};

	const removeFromCache = () => {
		localStorage.removeItem('character');
		localStorage.removeItem('characterExpiry');
	};

	async function getDocumentById(docId, url) {
		try {
			const docRef = doc(db, 'characters', docId);
			const docSnap = await getDoc(docRef);
			
			if (docSnap.exists()) {
				const data = docSnap.data();
				return {
					id: docSnap.id,
					name: data.name,
					prompts: data.prompts,
					intro: data.intro,
					image: url,
					visibility: data.visibility,
					sh_description: data.short_description,
					lg_description: data.long_description,
					craft_message: data.craft_message,
				};
			} else {
				console.log('No such document!');
			}
		} catch (error) {
			console.error('Error getting document:', error);
		}
	}


	function getImageDimensions(file) {
		return new Promise((resolve) => {
			const img = new Image();
			
			// Handle the load event of the Image object
			img.onload = function() {
				resolve({ width: this.naturalWidth, height: this.naturalHeight });
			};
			
			// Read the file as Data URL and assign it to Image src
			const fileReader = new FileReader();
			fileReader.onload = function(e) {
				img.src = e.target.result;
			};
			fileReader.readAsDataURL(file);
		});
	}

	async function getImageUrl(imageData) {
		const imageName = `image_${new Date().toISOString()}_${randomString(
			10
		)}.png`;
		const blob = new Blob([imageData], { type: 'image/png' });

		const storageRef = ref(storage, `avatars/${imageName}`);
		await uploadBytes(storageRef, blob);
		let url = await getDownloadURL(storageRef);

    	return { imagePath: `avatars/${imageName}`, url: url };
	}

	async function getCreateImageUrl(imageData) {

		const imageName = `image_${new Date().toISOString()}_${randomString(
			10
		)}.png`;

		const storageRef = ref(storage, `avatars/${imageName}`);
		await uploadBytes(storageRef, imageData);
		let url = await getDownloadURL(storageRef);

    	return { imagePath: `avatars/${imageName}`, url: url };
	}
	
	async function callImageCreate() {
		try {
			setIsImageLoading(true);
			const res = await generateImageAndUpload(imageText);
			setImageBlob(res)
			const imageUrl = URL.createObjectURL(res); // Convert Blob to URL
			setImageView(imageUrl);
		} catch (error) {
			// Handle error
			console.error(error);
		} finally {
			setIsImageLoading(false);
		}
	}

	async function generateImageAndUpload(prompt) {
		const imageDataBase64 = await generateImage(prompt);
		const imageData = base64.toByteArray(imageDataBase64);
		const blob = new Blob([imageData], { type: 'image/png' });
		return blob;
	}

	async function generateImage(prompt) {
		setIsImageLoading(true)

		const generateData = {
			prompt: prompt + ', 4k photo',
		};


		try {
			const response = await axios.post(
				`https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/genimage1/generate_image`,
				// `http://127.0.0.1:8000/api/v1/genimage1/generate_image`,
				generateData,
				config
			);
			const imageDataBase64 = response.data.output;
			return imageDataBase64;
		} catch (error) {
			if (error.code === 'ERR_NETWORK') {
				alert('An error occured. Message or Image not appropriate');
			}
			console.error(error);
			setIsImageLoading(false);
		}
	}


    const onSubmit = async (data) => {
		setIsMessage("")
		if (!canSubmit) {
			setIsMessage("Remove all profane words!");
			return;
		};
		try {
			console.log(data);
			setIsLoading(true)
			const docRef = doc(db, 'users', user.uid);
			const docSnap = await getDoc(docRef);

			if (docSnap && docSnap.exists()) {
				// Modifying the data object before adding to the collection
				let imagePath, urlPath; // Define imagePath here

				if (imageView && imageBlob) {
					const { imagePath: path, url } = await getCreateImageUrl(
						imageBlob
					);
					imagePath = path; // Assign the value to imagePath
					urlPath = url
				} else {
					if (!data.avatarImage[0]) {
						setIsMessage('Please select and Image');
						setIsLoading(false);
						return
					} else {
						const resizedImage = await resizeFile(
							data.avatarImage[0]
						);
						getImageDimensions(resizedImage).then((dimensions) => {
							console.log(dimensions); // { width: ..., height: ... }
						});
						const { imagePath: path, url } = await getImageUrl(
							resizedImage
						);
						imagePath = path; // Assign the value to imagePath
						urlPath = url;
					}
				}
				const modifiedData = {
					name: data.name,
					prompts: data.personalityText,
					intro: data.introMessage,
					creator: docSnap.data().username,
					main_image: imagePath,
					categories: ['all', data.category],
					visibility: data.visibility[0],
					sh_description: data.short_description || '',
					lg_description: data.long_description || '',
					craft_message: data.craft_message || '',
					click_count: 0,
				};
				console.log("adding avatar data");
				const docRef = await addDoc(collection(db, 'characters'), modifiedData);
				console.log(
					'added avatar data',
					modifiedData,
				);
				removeFromCache();
				getchar();
				getDocumentById(docRef.id, urlPath).then((documentData) => {
					console.log(documentData);
					setS_char(documentData.id);
					setCharacter(documentData);
				});
				setIsLoading(false)
				setIsMessage("Avatar added to collection successfully! Taking you to your Avatar!")
				reset();
				setTimeout(() => {
					navigate('/mode/learning');
				}, 2000);
			} else {
				setIsMessage("You do not have a username yet! \n Kindly create one. \n You will be redirected in 2 seconds.")
				setTimeout(() => {
					navigate("/settings");
				}, 2000);
				setIsLoading(false)
			}
		} catch (error) {
			console.error("Error uploading image: ", error);
			setIsLoading(false)
			setIsMessage("An error ocurred!")
		}
	};

	const maxCharLimit = 1000;

	const handleButtonClick = () => {
		const newText =
			"{{char}}: Hi {{user}}, I'm {{char}}.\n{{user}}: Hello!\n";
		const updatedContent = textAreaContent
			? textAreaContent + '\n' + newText
			: newText;
		if (charCount + newText.length <= maxCharLimit) {
			setValue('craft_message', updatedContent);
			setCharCount(updatedContent.length);
		}
	};

	const handleTextAreaChange = (event) => {
		const updatedContent = event.target.value;
		if (updatedContent.length <= maxCharLimit) {
			setValue('craft_message', updatedContent); // Update the form value
			setCharCount(updatedContent.length);
		}
	};

	const handleNameChange = (event) => {
		const updatedContent = event.target.value;
		setValue('name', updatedContent); 
	};

  return (
		<Layout
			setIsCharacterOpen={setIsCharacterOpen}
			isCharacterOpen={isCharacterOpen}
		>
			<Helmet>
				<title>Create Avatar | Learning With AI</title>
			</Helmet>
			<div
				style={{
					backgroundColor: 'rgba(30, 132, 227, 0.2)',
					background:
						'linear-gradient(107.62deg, rgba(30, 132, 227, 0.2) 0%,rgba(92, 38, 211, 0.2) 100% )',
					boxShadow: '0px 20px 50px 0px rgba(0, 0, 0, 0.3)',
					color: '#fff',
					paddingBottom: '5rem',
				}}
			>
				<style>
					{`
                    .modal-dialog {
                        // max-width: 100%;
                        margin: 0 auto;
                        
                    }
                    .modal-content {
						background-color: #000 !important;
                    }
                `}
				</style>
				<Modal isOpen={modal} toggle={toggle} centered>
					<div
						style={{
							backgroundColor: 'rgba(30, 132, 227, 0.2)',
							background:
								'linear-gradient(107.62deg, rgba(30, 132, 227, 0.2) 0%,rgba(92, 38, 211, 0.2) 100% )',
							boxShadow: '0px 20px 50px 0px rgba(0, 0, 0, 0.3)',
							overflowY: 'auto',
							color: '#fff',
						}}
					>
						<ModalHeader toggle={toggle} className="mb-2">
							{' '}
							<span
								className="text-white"
								style={{
									fontSize: '130%',
								}}
							>
								Generate an image for your avatar
							</span>
						</ModalHeader>
						<hr
							style={{
								borderBottom: '1px solid white',
								width: '100%',
								margin: '0',
							}}
						/>
						<ModalBody className="p-1 px-sm-3 mt-2">
							<textarea
								placeholder="Clearly describe your desired image."
								row="3"
								value={imageText}
								onChange={(e) => setImageText(e.target.value)}
								style={{
									width: '100%',
									background: 'transparent',
									border: '1px solid gray',
									paddingLeft: '.6rem',
									color: 'lightgray',
								}}
							></textarea>
							{imageView && (
								<div className="d-flex justify-content-center">
									<img
										src={imageView}
										alt="avatar"
										style={{
											width: '200px',
											height: '180px',
										}}
									/>
								</div>
							)}
							{isImageLoading ? (
								<div className=" d-flex justify-content-center">
									<div
										style={{
											width: '200px',
											height: '180px',
											textAlign: 'center',
											fontSize: '3rem',
										}}
									>
										<div
											className="spinner-border spinner-border-sm "
											role="status"
										>
											<span className="sr-only">
												Loading...
											</span>
										</div>{' '}
									</div>
								</div>
							) : (
								''
							)}
						</ModalBody>
						<hr
							style={{
								borderBottom: '1px solid white',
								width: '100%',
								margin: '0',
							}}
						/>
						<ModalFooter className="d-flex justify-content-end my-3">
							<button
								className="py-2 px-4 mr-2"
								style={{
									color: '#fff',
									background:
										'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
									borderRadius: '7px',
									outline: 'none',
									border: '0',
								}}
								type="button"
								onClick={() => {
									setImageView(null);
									callImageCreate();
								}}
							>
								{isImageLoading ? (
									<>
										<div
											className="spinner-border spinner-border-sm"
											role="status"
										>
											<span className="sr-only">
												Loading...
											</span>
										</div>{' '}
									</>
								) : (
									'Generate'
								)}
							</button>{' '}
							<button
								className="py-2 px-4 mr-2"
								style={{
									color: '#fff',
									background:
										'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
									borderRadius: '7px',
									outline: 'none',
									border: '0',
								}}
								type="button"
								onClick={() => toggle()}
							>
								Set avatar
							</button>
						</ModalFooter>
					</div>
				</Modal>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Row className="m-0 mx-sm-4">
						<Col className="details" style={{ maxWidth: '800px' }}>
							<Row className="ml-0 mt-2 position-relative d-flex justify-content-center">
								<button
									onClick={() => {
										navigate('/avatar-list');
									}}
									style={{
										position: 'absolute',
										cursor: 'pointer',
										backgroundColor: 'transparent',
										color: 'white',
										outline: 'none',
										border: '0',
										padding: '.3rem .7rem',
										fontSize: '1.3rem',
										left: 0,
									}}
								>
									<i className="fas fa-arrow-left"></i>
								</button>
								{isMobile ? (
									<h3 className="mt-1">Create Your Avatar</h3>
								) : (
									<h1>Create Your Avatar</h1>
								)}
							</Row>
							<Row>
								<Col className="align-self-start" md="3">
									<label
										className="labels pl-0 text-white"
										htmlFor="#name"
									>
										Name
									</label>
								</Col>
								<Col className="align-self-start" md="9">
									<FormGroup>
										<input
											id="name"
											required
											type="text"
											onChange={handleNameChange}
											{...register('name')}
											className={`form-control bg-dark ${
												badWordsInFields.name
													? 'border-danger'
													: ''
											}`}
										/>
										<FormText>
											This will be the name your avatar
											uses in chat.
										</FormText>
									</FormGroup>
								</Col>
							</Row>
							<Row className="mt-2">
								<Col className="align-self-start" md="3">
									<label
										className="labels pl-0 text-white"
										htmlFor="#introMessage"
									>
										Intro message
									</label>
								</Col>
								<Col className="align-self-start" md="9">
									<FormGroup>
										<input
											id="introMessage"
											{...register('introMessage')}
											required
											type="text"
											className={`form-control bg-dark ${
												badWordsInFields.introMessage
													? 'border-danger'
													: ''
											}`}
										/>
										<FormText>
											How should your avatar start a
											conversation?
										</FormText>
									</FormGroup>
								</Col>
							</Row>
							<Row className="mt-2">
								<Col className="align-self-start" md="3">
									<label
										className="labels pl-0 text-white"
										htmlFor="#personalityText"
									>
										Personality text
									</label>
								</Col>
								<Col className="align-self-start" md="9">
									<FormGroup>
										<textarea
											id="personalityText"
											{...register('personalityText')}
											required
											type="text"
											className={`form-control bg-dark ${
												badWordsInFields.personalityText
													? 'border-danger'
													: ''
											}`}
										/>
										<FormText>
											In just a few words, describe the
											personality of your avatar
										</FormText>
									</FormGroup>
								</Col>
							</Row>
							<Row className="mt-2">
								<Col className="align-self-start" md="3">
									<label
										className="labels pl-0 text-white"
										htmlFor="#personalityText"
									>
										Avatar category
									</label>
								</Col>
								<Col className="align-self-start" md="9">
									<FormGroup>
										<select
											id="exampleSelect"
											{...register('category')}
											type="select"
											className="form-control bg-dark"
										>
											<option value="memes">Memes</option>
											<option value="animals">
												Animals
											</option>
											<option value="famous_people">
												Famous People
											</option>
											<option value="people">
												People
											</option>
											<option value="movies_and_tV">
												Movies and TV
											</option>
										</select>
										<FormText>Select a category.</FormText>
									</FormGroup>
								</Col>
							</Row>
							<Row className="mt-2">
								<Col className="align-self-start" md="3">
									<label
										className="labels pl-0 text-white"
										htmlFor="#exampleSelectMulti"
									>
										Visibility
									</label>
								</Col>
								<Col className="align-self-start" md="9">
									<FormGroup>
										<select
											id="exampleSelectMulti"
											multiple
											name="selectMulti"
											{...register('visibility')}
											type="select"
											className="form-control bg-dark"
											style={{
												height: '3rem',
												overflow: 'hidden',
												padding: '5px',
											}}
										>
											<option value="public">
												Public: Anyone can chat
											</option>
											<option value="private">
												Private: Only you can chat
											</option>
										</select>
									</FormGroup>
								</Col>
							</Row>
							<Row className="mt-2">
								<Col className="align-self-start" md="3">
									<label
										className="labels pl-0 text-white"
										htmlFor="#avatarImage"
									>
										Avatar Image
									</label>
								</Col>
								<Col className="align-self-start" md="9">
									<FormGroup className="d-flex align-items-center">
										<button
											className="mr-2 p-2"
											style={{
												color: '#fff',
												background:
													'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
												borderRadius: '7px',
												outline: 'none',
												border: '0',
												whiteSpace: 'nowrap',
											}}
											type="button"
											onClick={toggle}
										>
											Generate AI Image 🚀
										</button>
										<span className="mr-2">or</span>
										<div>
											<input
												id="avatarImage"
												{...register('avatarImage')}
												type="file"
												// required
												className="form-control bg-dark"
											/>
										</div>
									</FormGroup>
									{imageView && (
										<div className="d-flex justify-content-start">
											<img
												src={imageView}
												alt="avatar"
												style={{
													width: '200px',
													height: '180px',
												}}
											/>
										</div>
									)}
								</Col>
							</Row>
							{showAdvanced && (
								<>
									<Row className="mt-2">
										<Col
											className="align-self-start"
											md="3"
										>
											<label
												className="labels pl-0 text-white"
												htmlFor="#short_description"
											>
												Short Description
											</label>
										</Col>
										<Col
											className="align-self-start"
											md="9"
										>
											<FormGroup>
												<input
													id="shor_description"
													{...register(
														'short_description'
													)}
													type="text"
													className={`form-control bg-dark ${
														badWordsInFields.short_description
															? 'border-danger'
															: ''
													}`}
												/>
												<FormText>
													In just a few words, how
													would {name} describe
													themselves?
												</FormText>
											</FormGroup>
										</Col>
									</Row>
									<Row className="mt-2">
										<Col
											className="align-self-start"
											md="3"
										>
											<label
												className="labels pl-0 text-white"
												htmlFor="#long_description"
											>
												Long Description
											</label>
										</Col>
										<Col
											className="align-self-start"
											md="9"
										>
											<FormGroup>
												<textarea
													id="long_description"
													{...register(
														'long_description'
													)}
													type="text"
													style={{
														height: '200px !important',
													}}
													className={`form-control bg-dark ${
														badWordsInFields.long_description
															? 'border-danger'
															: ''
													}`}
												/>
												<FormText>
													In a few sentences, how
													would {name} describe
													themselves?
												</FormText>
											</FormGroup>
										</Col>
									</Row>
									<Row className="mt-2">
										<Col
											className="align-self-start"
											md="3"
										>
											<label
												className="labels pl-0 text-white"
												htmlFor="#craft_message"
											>
												Craft Message
											</label>
										</Col>
										<Col
											className="align-self-start"
											md="9"
										>
											<FormGroup>
												<FormText>
													<button
														type="button"
														style={{
															color: '#fff',
															background:
																'transparent',
															borderRadius: '7px',
															outline: 'none',
															border: '1px solid lightgray',
															padding:
																'.6rem 1rem',
															marginBottom:
																'1rem',
														}}
														onClick={
															handleButtonClick
														}
													>
														{' '}
														<span
															style={{
																fontSize:
																	'130%',
															}}
														>
															+
														</span>{' '}
														Insert example messages
													</button>
												</FormText>
												<FormText>
													{charCount}/{maxCharLimit}{' '}
													characters, remaining:{' '}
													{maxCharLimit - charCount}
												</FormText>
												<textarea
													{...register(
														'craft_message'
													)}
													onChange={
														handleTextAreaChange
													}
													type="text"
													style={{
														height: '15rem',
														width: '100%',
														borderRadius: '7px',
														color: 'lightgray',
													}}
													className={`p-2  bg-dark ${
														badWordsInFields.craft_message
															? 'border-danger'
															: ''
													}`}
												/>
												<FormText>
													Example conversations and
													information to define your
													avatar. The first 15-30
													messages are the most
													important.
												</FormText>
											</FormGroup>
										</Col>
									</Row>
								</>
							)}
							<Row className="ml-1">
								{isMessage && (
									<Alert
										color="info"
										isOpen={visible}
										toggle={onDismiss}
									>
										{isMessage}
									</Alert>
								)}
							</Row>
							<Row className="d-flex justify-content-md-end mx-1 mt-4 mb-4">
								<button
									className="ml-sm-0 mb-4 p-3 px-4 mr-3 text-center"
									type="button"
									style={{
										outline: 'none',
										border: '1px solid gray',
										fontSize: '90%',
										color: 'black',
										backgroundColor: 'white',
										borderRadius: '7px',
									}}
									onClick={() => {
										setShowAdvanced(!showAdvanced);
									}}
								>
									Edit Details (Advanced)
								</button>
								<button
									className="ml-sm-0 mb-4 p-3"
									type="submit"
									style={{
										color: '#fff',
										background:
											'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
										borderRadius: '7px',
										outline: 'none',
										border: '0',
									}}
								>
									{isLoading ? (
										<>
											<div
												className="spinner-border spinner-border-sm"
												role="status"
											>
												<span className="sr-only">
													Loading...
												</span>
											</div>
										</>
									) : (
										'Create Avatar'
									)}
								</button>
							</Row>
						</Col>
					</Row>
				</Form>
			</div>
		</Layout>
  );
}
