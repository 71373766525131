

export function copyToClipboard(content) {
  try {
    window.navigator.clipboard.writeText(content)
    return true
  }
  catch(e) {
    return false
  }
}


export function downloadImage(imageUrl) {
  try {
    fetch(imageUrl)
		.then((response) => {
			// Convert the response to blob object
			return response.blob();
		})
		.then((blob) => {
			// Create a temporary URL for the blob object
			const url = URL.createObjectURL(blob);

			// Create a link element and click it to initiate download
			const a = document.createElement('a');
			a.href = url;
			a.download = 'image.png';
			a.click();
		});
    return true;
  } catch(e) {
    return false;
  }
}