


function getAsDataUrl(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            resolve(reader.result)
        }
        reader.readAsDataURL(file)
    })
}

export default async function imageToText(file) {
    const encodedString = await getAsDataUrl(file)

    const options = JSON.stringify({
        requests: [
            {
                image:{
                    content: encodedString
                },
                features: [
                    {
                        type:"DOCUMENT_TEXT_DETECTION",
                        maxResults:1
                    }
                ]
            }
        ]
    })
    const apiKey = "AIzaSyBQ-xpjn5py-4YDGs8jjbdWOXvdWJWwoA0"

    const url = `https://vision.googleapis.com/images:annotate?key=${apiKey}`

    const data = await fetch(url, {
        method: "POST",
        body: options
    })
    .then(res => res.json())

    console.log(data)

    return data.fullTextAnnotation.text
}