import { useContext, createContext, useState, useEffect } from 'react';
import { db } from 'lib/firebase';
import {
	doc,
	getDoc,
} from 'firebase/firestore';
import { useAuth } from 'contexts/AuthContext';

const LaunchContext = createContext();

export function useLaunch() {
	return useContext(LaunchContext);
}


export function LaunchProvider({ children }) {
	const [launchPadData, setLaunchPadData] = useState(null);
	const [characterData, setCharacterData] = useState(null);
	const [char_len, setChar_len] = useState(0);
	const [charSub, setCharSub] = useState(false);
	const [isCharacterOpen, setIsCharacterOpen] = useState(false);
	const [chars, setChars] = useState(null);
	const [s_char, setS_char] = useState('sensei');
	const [bigBrain, setBigBrain] = useState(false);
	const { user } = useAuth();

	useEffect(() => {
		if (user) {
			const fetchUserProfileCharacterList = async (uid) => {
				const docRef = doc(db, 'userProfiles', uid);
				const docSnap = await getDoc(docRef);
				const char = docSnap.data().char_list || [];
				return char;
			};

			const fetchUserProfileSubscribed = async (uid) => {
				const docRef = doc(db, 'userProfiles', uid);
				const docSnap = await getDoc(docRef);
				return docSnap.data().is_subscribed;
			};
			const startup = async () => {
				const subscribed = await fetchUserProfileSubscribed(user.uid);
				let char_list = await fetchUserProfileCharacterList(user.uid);
				setCharSub(subscribed);
				setChars(char_list);
				setChar_len(char_list.length);
			};
			startup();
		}
	}, [window.location.pathname.split('/')[1] === 'avatar-list']);

	const setLaunchPad = (item) => {
		setLaunchPadData(item);
	};

	const setCharacter = (item) => {
		setCharacterData(item);
	};

	const value = {
		launchPadData,
		setLaunchPad,
		setCharacter,
		characterData,
		s_char,
		setS_char,
		char_len,
		chars,
		charSub,
		setIsCharacterOpen,
		isCharacterOpen,
		bigBrain,
		setBigBrain,
	};

	return (
		<LaunchContext.Provider value={value}>{children}</LaunchContext.Provider>
	);
}
