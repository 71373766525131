import { useState } from 'react';
import Slider from './Slider';

export default function Tone({
	isMobile,
	isTone,
	showToneOther,
	tone,
	toneText,
	handleToneOther,
	setShowToneOther,
	setToneText,
	setIsTone,
	setActiveComponent,
}) {
	const [prev, setPrev] = useState('');
	return (
		<>
			<Slider isVisible={isTone}>
				<>
					<div className={`big-wrapper`}>
						<div
							className={`bubble-wrapper ${
								showToneOther ? '' : 'position-relative'
							}  d-flex flex-wrap`}
						>
							<i
								className="fa fa-times"
								style={{
									position: 'absolute',
									top: 5,
									right: 8,
									color: '#fff',
									cursor: 'pointer',
								}}
								onClick={() => {
									setShowToneOther(false);
									setIsTone(!isTone);
								}}
							></i>
							{tone.map((item, key) => {
								return (
									<p
										className={`bubble mx-1  ${
											item === prev ? 'bubble-white' : ''
										}`}
										key={key}
										onClick={() => {
											if (item === prev) {
												setToneText('');
												setPrev(''); // Clear the previously selected item
											} else {
												setToneText(item);
												setPrev(item); // Set this item as the currently selected item
											}
										}}
									>
										{item}
									</p>
								);
							})}
							<p
								className={`bubble mx-1 d-flex align-items-center justify-content-center position-relative ${
									showToneOther ? 'p-0' : 'p-2'
								}`}
								onClick={() => setShowToneOther(true)}
							>
								{showToneOther ? (
									<>
										<input
											type="text"
											className="input-save"
											value={toneText}
											onChange={handleToneOther}
											placeholder="What tone?"
											autoFocus
										/>
										<i
											className="fa fa-times mr-2"
											onClick={(event) => {
												event.stopPropagation();
												setShowToneOther(false);
											}}
										></i>
									</>
								) : (
									'Other'
								)}
							</p>
						</div>
					</div>
				</>
			</Slider>
			<button
				className="px-2 py-1 text-white mb-1 mr-2 bubble-btn"
				style={{
					borderTopLeftRadius: isTone ? '0px' : '',
					borderTopRightRadius: isTone ? '0px' : '',
					borderTopLeftRadius: !isTone
						? isMobile
							? '15px'
							: ''
						: '',
					borderTopRightRadius: !isTone
						? isMobile
							? '15px'
							: ''
						: '',
					borderBottomLeftRadius: !isTone
						? isMobile
							? '15px'
							: ''
						: '',
					borderBottomRightRadius: !isTone
						? isMobile
							? '15px'
							: ''
						: '',
				}}
				onClick={() => {
					setActiveComponent('isTone');
					setIsTone(!isTone);
				}}
			>
				{toneText ? toneText : 'Tone'}
				{isTone ? (
					<i className="fas fa-caret-up ml-1"></i>
				) : (
					<i className="fas fa-caret-down ml-1"></i>
				)}
			</button>
		</>
	);
}
