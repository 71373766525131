import { useAuth } from 'contexts/AuthContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import Username from './Username';
import UserDetails from './UserDetails';
import { auth } from 'lib/firebase';
import './Onboarding.css';
import {useRoute} from "contexts/RouteContext"

export default function Onboarding() {
	const [step, setStep] = useState('verify-email');
	const { user, signOut, reloadAuth, username } = useAuth();
	const navigate = useNavigate();
	const [width, setWidth] = useState(window.innerWidth);
	const { isLogin, handleLogin } = useRoute();

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const isMobile = width <= 450;

	useEffect(() => {
		if (user){
			if (user.emailVerified) {
				if (user.displayName) {
					if (username) {
						navigate('/plans');
					} else if (step !== 'add-username') {
						setStep('add-username');
					}
				} else if (step !== 'user-details') {
					// <-- add this condition
					setStep('user-details');
				}
			} else{
				// <-- add this condition
				setStep('verify-email');
			}
		}
	}, [user, navigate, step, username]);

	useEffect(() => {
		if (user) {
			if (step === 'verify-email') {
				let interval = setInterval(() => {
					auth.currentUser.reload();
					if (auth.currentUser.emailVerified) {
						setStep('user-details');
					}
				}, 2000);
	
				return () => clearInterval(interval);
			}
		}
	}, [step]);

	let component;

	if (step === 'verify-email') {
		component = (
			<div
				className="d-flex justify-content-center align-items-center flex-column"
				style={{
					textAlign: 'center',
					paddingRight: isMobile ? '5vw' : '2vw',
					paddingLeft: isMobile ? '5vw' : '2vw',
				}}
			>
				<span
					style={{
						alignSelf: 'center',
						color: 'white',
						fontSize: '3.4vh',
						marginLeft: 'auto',
						marginBottom: '2vh',
						marginRight: 'auto',
						textAlign: 'center',
					}}
				>
					{user ? 'Email Verification' : 'Unauthorized Access'}
				</span>
				{user ? (
					<>
						{isLogin ? (
							<div>
								<p>
									An email has been previously sent to{' '}
									{user.email}
								</p>
								<p>Search for "Learning With AI team"</p>
								<p>Refresh the page after Verification</p>
							</div>
						) : (
							<p>
								An email has been sent to {user.email}. Refresh
								the page after Verification.
							</p>
						)}
					</>
				) : (
					<p>
						To access this page. Login{' '}
						<span
							style={{
								color: 'lightblue',
								borderBottomColor: 'blue',
								borderBottomWidth: '1px',
								borderBottomStyle: 'solid',
								cursor: 'pointer',
							}}
							onClick={() => navigate('/login')}
						>
							here
						</span>{' '}
					</p>
				)}
			</div>
		);
	}

	if (step === 'add-username') {
		component = (
			<Username
				onComplete={() => {
					reloadAuth().then(() => {
						navigate('/');
					});
				}}
			/>
		);
	}

	if (step === 'user-details') {
		component = (
			<UserDetails
				onComplete={() => {
					setStep('add-username');
				}}
			/>
		);
	}

	return (
		<Container>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					minHeight: '100vh',
				}}
			>
				<div className="onboardingContainer">
					<h3
						className="text-center"
						style={{
							cursor: 'pointer',
							zIndex: '1000',
							paddingTop: '5vh',
						}}
						// onClick={signOut}
					>
						<img
							src="/logo.png"
							alt="logo"
							style={{
								marginTop: '4px',
								height: '6.4vh',
								width: 'auto',
							}}
						/>
					</h3>
					<div
						className="justify-content-center"
						style={{
							position: 'relative',
							paddingBottom: isMobile ? '7rem' : '5rem',
						}}
					>
						{component}
						{step !== 'verify-email' && (
							<div
								style={{
									position: 'absolute',
									bottom: 0,
									right: 5,
								}}
								className="d-flex justify-content-end pr-2"
							>
								<button
									type="button"
									onClick={signOut}
									style={{
										outline: 'none',
										border: '0px',
										background: '0px',
										color: '#fff',
										padding: '1.2rem 1rem',
									}}
								>
									<i className="fa fa-sign-out mr-2"></i>
									Logout
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</Container>
	);
}
