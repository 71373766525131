export const gradeLevel = [
    'Gold fish',
    'Elementary',
    'Junior High',
    'High school',
    'College',
    'Post-grad',
]

export const tone = [
	'Academic',
	'Informal',
	'Persuasive',
	'Funny',
	'Professional',
	'Satirical',
	'Informative',
	'Romantic',
	'Reflective',
	'Business',
];

export const length = [
    '5 words',
    'One sentence',
    'A few sentences',
    'Paragraph',
    '3 paragraphs',
    'Essay',
]

export const persona = [
    'Pirate',
    'Batman',
    'Alien',
    'Time traveler',
    'Mad scientist',
    'Comedian',
    'Samurai',
    'Shrek',
    'Dragon',
    'Super hero',
    'Jedi',
    'Surfer',
    'Yoda',
    'The Godfather',
]