import { createContext, useContext, useState, useEffect } from 'react';
import { auth, analytics } from 'lib/firebase';
import {
	onAuthStateChanged,
	updateProfile,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	GoogleAuthProvider,
	signInWithPopup,
	signOut as firebaseSignOut,
	sendEmailVerification,
	sendPasswordResetEmail,
} from 'firebase/auth';
import { Navigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { db } from 'lib/firebase';
import {
	doc,
	getDoc,
	setDoc,
	updateDoc,
} from 'firebase/firestore';

// most of the needed code is here for a 'users' table so that user information can be seen by other users
// also so that more data relating to that user can be added

const AuthContext = createContext();

export function useAuth() {
	return useContext(AuthContext);
}

const updateCombinedData = async (user) => {
	// Check if the user object has the required properties
	if (
		!user &&
		!user.email &&
		!user.uid &&
		!user.displayName &&
		!user.emailVerified
	) {
		return;
	}

	if (user.displayName === null){
		return
	}

	// Get all collections data for the user
	const collectionsData = await Promise.all([
		getDoc(doc(db, 'users', user.uid)),
		getDoc(doc(db, 'userProfiles', user.uid)),
		// Add more collections as needed
	]);


	const dataObjects = collectionsData.map((docSnapshot) =>
		docSnapshot.data()
	);

	const mergedData = dataObjects.reduce(
		(acc, data) => Object.assign(acc, data),
		{}
	);

	// Merge all the data with the user's authentication data
	const newCombinedData = {
		firstName: user.displayName.split(' ')[0] || '',
		lastName: user.displayName.split(' ')[1] || '',
		email: user.email,
		uid: user.uid,
		...mergedData,
	};


	// Get the existing combined data for the user
	const existingCombinedDataDoc = await getDoc(
		doc(db, 'user-data', user.uid)
	);

	// If the document doesn't exist, create it using the set method
	if (!existingCombinedDataDoc.data()) {
		await setDoc(doc(db, 'user-data', user.uid), newCombinedData);
		return;
	}

	// Compare the new combined data with the existing combined data
	const existingCombinedData = existingCombinedDataDoc.data();
	const updatedData = {};

	for (const key in newCombinedData) {
		if (
			!existingCombinedData ||
			!existingCombinedData.hasOwnProperty(key) ||
			newCombinedData[key] !== existingCombinedData[key]
		) {
			updatedData[key] = newCombinedData[key];
		}
	}


	// Update only the changed fields
	if (Object.keys(updatedData).length > 0) {
		await updateDoc(doc(db, 'user-data', user.uid), updatedData);
	}
};

export function ProtectedRoute({ element, redirect, redirectAuthenticated }) {
	const { user } = useAuth();

	// checks if should redirect user
	if (!Boolean(user)) {
		const cacheExpiry = localStorage.getItem('loginExpiry');
		let component;
		if (cacheExpiry && Date.now() < cacheExpiry) {
			component = () => (
				<Navigate
					to={redirect || `/login?from=${window.location.pathname}`}
					replace
				/>
			);
		} else {
			component = () => (
				<Navigate
					to={redirect || `/sign-up?from=${window.location.pathname}`}
					replace
				/>
			);
		}

		return component();
	}

	// checks if all the user's info is present
	const userSetup =
		user &&
		user.emailVerified &&
		user.displayName;

	if (userSetup && !redirectAuthenticated) {
		return element;
	}

	// redirects user to onboarding if they are not yet completely registered
	if (user && !userSetup && window.location.pathname !== '/onboarding') {
		return <Navigate to="/onboarding" replace />;
	}

	return element;
}


export function AuthProvider({ children }) {
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
  	const [username, setUsername] = useState(null);
	const [checked, setChecked] = useState(false);
	const [subscribe, setSubscribe] = useState(false);

	useEffect(() => {
		// listens to changes in current auth state
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			setUser(user ? { ...user } : null);
			setLoading(false);
			if(user) {
				await updateCombinedData(user);
			}
		});

		// removes listener when component is unmounted
		return () => unsubscribe();
	}, []);

  useEffect(() => {
		// checks if the user's username has been created before
		const isUsernameExists = async () => {
      if (!user) return;
			const docRef = doc(db, 'users', user.uid);
			const docSnap = await getDoc(docRef);
			if (docSnap && docSnap.exists()) {
				setUsername(docSnap.data().username);
			} else {
				setUsername(null);
			}
		};
		isUsernameExists();
  }, [user]);

  useEffect(() => {
		if (user) {
			const fetchUserProfileSubscribed = async (uid) => {
				const docRef = doc(db, 'userProfiles', uid);
				const docSnap = await getDoc(docRef);
				return docSnap.data().is_subscribed;
			};
			const startup = async () => {
				const subscribed = await fetchUserProfileSubscribed(user.uid);
				setSubscribe(subscribed || false);
			};
			startup();
		}
  }, [user]);

	const reloadAuth = async () => {
		if (!user) return;

		await auth.currentUser.reload();

		setUser({ ...auth.currentUser });
	};

	const updateUser = async (obj) => {
		await updateProfile(auth.currentUser, obj);
		setUser({ ...auth.currentUser });
		return auth.currentUser;
	};

	const login = async (email, password) => {
		if (!email || !password) {
			throw new Error('Must include email and password');
		}
		try {
			const result = await signInWithEmailAndPassword(
				auth,
				email,
				password
			);
			const uid = result.user.uid; // Get the user's unique identifier
			logEvent(analytics, 'sign_in', { signin_user_id: uid }); // Log the event with the user ID
			return result;
		} catch (error) {
			console.error('Error signing in:', error);

			let errorMessage =
				'An error occurred during sign-in. Please try again later.';

			if (error.code === 'auth/user-not-found') {
				errorMessage =
					'Invalid email or password. Please check your credentials.';
			} else if (error.code === 'auth/wrong-password') {
				errorMessage =
					'Invalid email or password. Please check your credentials.';
			} else if (error.code === 'auth/too-many-requests') {
				errorMessage =
					'Too many failed login attempts. Please try again later.';
			}

			throw new Error(errorMessage);
		}
	};

	const signUp = async (email, password) => {
		if (!email || !password) {
			throw new Error('Must include email and password');
		}
		try {
			const credential = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);

			// Log the event with the user ID
			logEvent(analytics, 'sign_up', { signup_user_id: credential.user.uid });

			await sendEmailVerification(credential.user);
			return credential;
		} catch (error) {
			if (error.code === 'auth/email-already-in-use') {
				console.log('User already exists, logging in...');
				return login(email, password);
			} else if (error.code === 'auth/too-many-requests') {
				throw new Error(
					'Too many failed attempts. Please try again later.'
				);
			} else {
				console.error('Error signing up:', error);
				throw new Error('Error signing up. Please try again.');
			}
		}
	};

	const signInWithGoogle = async () => {
		const googleProvider = new GoogleAuthProvider();
		try {
			const result = await signInWithPopup(auth, googleProvider);
			const uid = result.user.uid; // Get the user's unique identifier
			logEvent(analytics, 'sign_in_with_google', { google_signin_user_id: uid }); // Log the event with the user ID
			return result;
		} catch (error) {
			 if (error.code === 'auth/popup-closed-by-user') {
					console.log(
						'Sign-in with Google was canceled by the user.'
					);
				} else {
					console.error('Error signing in with Google:', error);
					throw new Error(
						'Error signing in with Google. Please try again.'
					);
				}
		}
	};

	const forgotPassword = async (email) => {
		await sendPasswordResetEmail(auth, email);
	};

	const signOut = async () => {
		return await firebaseSignOut(auth);
	};

	const value = {
		user,
		username,
		loggedIn: !!user,
		loading,
		updateUser,
		login,
		forgotPassword,
		signUp,
		signInWithGoogle,
		signOut,
		reloadAuth,
		checked,
		setChecked,
		subscribe,
	};

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
}

// Auth provider that supports users table
/* export function AuthProvider2({ children }) {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [uid, setUid] = useState(null)

  // returns a user object based on auth object from firebase authentication
  const getUserFromAuth = (user) => {
    return {
      uid: user.uid,
      imageURL: user.imageURL || '/profile.jpeg',
      name: user.displayName || user.email,
      email: user.email
    }
  }

  const initializeUser = async (user) => {
    const userDocRef = doc(db, 'users', user.uid)
    const userDoc = await getDoc(userDocRef)

    // if user already exists, return that user
    if(userDoc.exists()) {
      return userDoc.data()
    }
    
    // otherwise, create a new user and save it to 'users' collection in firestore
    const userData = getUserFromAuth(user)
    await setDoc(userDocRef, userData)

    // return newly created user
    return userData
  }

  useEffect(() => {
    // listens to changes in current auth state
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if(user) {
        setLoading(true)
        await initializeUser(user)
        setUid(user.uid)
      }
      else {
        // update state, log out user
        setLoading(false)
        setUid(null)
        setUser(null)
      }
    })

    // removes listener when component is unmounted
    return () => unsubscribe()
  }, [])

  // every time the user's uid is changed, update user data
  useEffect(() => {
    if(!uid) return
    
    // gets a reference to user document
    const userDocRef = doc(db, 'users', uid)
    
    // sets up a listener updates user when data changes
    const unsubscribe = onSnapshot(userDocRef, snapshot => {
      if(snapshot.exists()) {
        setUser(snapshot.data())
      }
    })

    setLoading(false)

    // removes listener when component is unmounted
    return () => unsubscribe()
  }, [uid])

  return (
    <AuthContext.Provider value={user}>
      { !loading && children }
    </AuthContext.Provider>
  )
} */
