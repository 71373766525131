import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Alert,
} from "reactstrap";
import { useAuth } from "contexts/AuthContext";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { db } from 'lib/firebase';
import {
	doc,
	setDoc,
	getDoc,
} from 'firebase/firestore';
import axios from "axios";


const CACHE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours

export default function RegisterPage() {
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { signInWithGoogle, signUp, reloadAuth } = useAuth();
  const [emailBorderColor, setEmailBorderColor] = useState('#2b3553');
  const [passwordBorderColor, setPasswordBorderColor] = useState('#2b3553');
	const [loading, setLoading] = useState(false);
	const [googleLoading, setGoogleLoading] = useState(false);

  React.useEffect(() => {
    document.body.classList.add("register-page");
    return () => {
      document.body.classList.remove("register-page");
    };
  }, []);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 450;

  const updateReferrals = async (referrer_url, referralUid) => {
		try {
			const response = await axios.post(
				'https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/affiliate/update_user_referrals',
				// 'http://127.0.0.1:8000/api/v1/affiliate/update_user_referrals',
				{
					referrer_url: referrer_url,
					referralUid: referralUid,
				}
			);
			console.log(response.data);
		} catch (error) {
			console.error(error);
		}
  };

  const createUserProfile = async (user) => {
		try {
			let data = {
				question_count: 0,
				is_subscribed: false,
				checkout_session_id: '',
				affiliate: localStorage.getItem('referrer_url') || '',
				referral_list: [],
				paid_referrals: 0,
				unpaid_referrals: 0,
				referrer_url:
					user.displayName.split(' ')[0] + user.uid.substring(0, 7) ||
					user.uid.substring(0, 7),
				sensei_intro: false,
				avatar_tab: false,
				launchpad_text: false,
			};
			await setDoc(doc(db, 'userProfiles', user.uid), data);
			let referrer_url = localStorage.getItem('referrer_url') || '';
			if (referrer_url !== "") {
				await updateReferrals(referrer_url, user.uid);
				console.log("Updated referral list")
			}
			console.log('Created new profile for user:', user);
		} catch (error) {
			console.error('Error creating user profile:', error);
		}
  };

  const callCreateUserProfile = async (user) => {
		const docRef = doc(db, 'userProfiles', user.uid);
		const docSnap = await getDoc(docRef);

		if (docSnap && docSnap.exists()) {
			console.log('User profile already exists!');
			return true
		} else {
			await createUserProfile(user);
			return false
		}
  };

  const handleGoogleSignIn = async (cb) => {
	setGoogleLoading(true)
    signInWithGoogle()
      .then(async (e) => {
		await callCreateUserProfile(e.user);
		localStorage.setItem('loginExpiry', Date.now() + CACHE_EXPIRY_TIME);
		if (e.user.emailVerified && e.user.displayName) {
			reloadAuth();
			navigate('/mode/learning');
		} else {
			navigate('/onboarding');
		}
		setGoogleLoading(false)
      })
      .catch((err) => {
        setError(err.message);
		setGoogleLoading(false)
      });
  };

  const handleSignUp = async () => {
	setLoading(true)
    signUp(email, password, username)
      .then(async (e) => {
		await callCreateUserProfile(e.user);
		localStorage.setItem('loginExpiry', Date.now() + CACHE_EXPIRY_TIME);
		navigate('/onboarding');
		setLoading(false)
      })
      .catch((err) => {
        setError(err.message);
		setLoading(false)
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignUp();
  };

  return (
		<>
			<Helmet>
				<title>Sign Up | Learning with AI</title>
			</Helmet>
			<div className="position-relative">
				<style>
					{`
						@media screen and (max-width: 450px) {
							.logo-div {
								padding-top: 1.6rem !important;
							}	
						}
					`}
				</style>
				<div className="text-center position-absolute w-100 py-3 logo-div">
					<img
						src="/logo.png"
						alt="logo"
						style={{
							marginTop: '4px',
							height: '6.4vh',
							width: 'auto',
						}}
					/>
				</div>
				<div className="page-header login_page_ll">
					<Container className="pageContainer">
						<h1 className="font-weight-bold header">Sign Up</h1>
						{error && <Alert color="warning">{error}</Alert>}
						<Form
							action=""
							className="form"
							method=""
							onSubmit={handleSubmit}
						>
							<InputGroup
								className={classnames('input-lg', {
									'input-group-focus': emailFocus,
								})}
							>
								<InputGroupText
									style={{
										borderWidth: '1px',
										borderStyle: 'solid',
										borderLeftColor: emailBorderColor,
										borderTopColor: emailBorderColor,
										borderBottomColor: emailBorderColor,
										borderRight: '0',
										borderTopRightRadius: '0',
										borderBottomRightRadius: '0',
										marginRight: '0',
									}}
								>
									<i className="tim-icons icon-email-85" />
								</InputGroupText>
								<Input
									placeholder="Email"
									type="email"
									value={email}
									onFocus={(e) => {
										setEmailBorderColor('#1d8cf8');
										setEmailFocus(true);
									}}
									onBlur={(e) => {
										setEmailBorderColor('#2b3553');
										setEmailFocus(false);
									}}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</InputGroup>
							<InputGroup
								className={classnames('input-lg', {
									'input-group-focus': passwordFocus,
								})}
							>
								<InputGroupText
									style={{
										borderWidth: '1px',
										borderStyle: 'solid',
										borderLeftColor: passwordBorderColor,
										borderTopColor: passwordBorderColor,
										borderBottomColor: passwordBorderColor,
										borderRight: '0',
										borderTopRightRadius: '0',
										borderBottomRightRadius: '0',
										marginRight: '0',
									}}
								>
									<i className="tim-icons icon-lock-circle" />
								</InputGroupText>
								<Input
									placeholder="Password"
									type="password"
									value={password}
									onFocus={(e) => {
										setPasswordBorderColor('#1d8cf8');
										setPasswordFocus(true);
									}}
									onBlur={(e) => {
										setPasswordBorderColor('#2b3553');
										setPasswordFocus(false);
									}}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
							</InputGroup>
							{loading ? 
								(
									<button
										className="loginBtn"
										color="primary"
										type="submit"
									>
										<div className="spinner-border spinner-border-sm" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<span> Sign Up</span>
									</button>
								)
								: 
								(
									<button
										className="loginBtn"
										color="primary"
										type="submit"
									>
										Sign Up
									</button>
								)
							}
							<div className="alignment">
								Already have an account?
								<Link
									className="link footer-link"
									to="/login"
									style={{
										fontSize: isMobile ? '1.6vh' : '',
									}}
								>
									Login
								</Link>
							</div>
						</Form>
						<div className="lineContainer">
							<div className="line" />
							OR
							<div className="line" />
						</div>
						{googleLoading ? 
							(
								<button
									className="googleBtn"
									onClick={handleGoogleSignIn}
								>
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="sr-only">Loading...</span>
									</div>
									<img
										src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
										alt="google icon"
										className="googleIcon"
									/>
									<span className="text">Continue with Google</span>
								</button>
							)
							: 
							(
								<button
									className="googleBtn"
									onClick={handleGoogleSignIn}
								>
									<img
										src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
										alt="google icon"
										className="googleIcon"
									/>
									<span className="text">Continue with Google</span>
								</button>
							)
						}
					</Container>
				</div>
			</div>
		</>
  );
}
