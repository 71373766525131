import { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';

export default function RegenerateButton({ handleRegenerate, text }) {
	const [regenerate, setRegenerate] = useState(false);

	useEffect(() => {
		if (!regenerate) return;
		const timeout = setTimeout(() => {
			setRegenerate(false);
		}, 3000);

		return () => clearTimeout(timeout);
	}, [regenerate]);

	const handleRegenerateCall = () => {
		if (regenerate) return;
        const success = handleRegenerate();
		setRegenerate(success);
	};

	return (
		<span className="d-flex flex-row-reverse" onClick={handleRegenerateCall}>
			{regenerate ? 'Regenerated!' : text || 'Regenerate'}
			<FaSpinner className="icon mr-1" />
		</span>
	);
}
