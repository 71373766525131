import React, { useState, useEffect, useRef } from "react";

// reactstrap components
import {
	Button,
	FormGroup,
	Input,
	Row,
	Col,
	Alert,
	NavLink,
	Tooltip
} from 'reactstrap';

import { useNavigate } from "react-router-dom";

import ProfileUpload from "components/CustomUpload/ProfileUpload";
import { useAuth } from "contexts/AuthContext";
import { Helmet } from "react-helmet";
import { config } from "../constants/stripe";
import axios from "axios";
import { db, auth } from "lib/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";


export default function AccountSettings({ handlePortal }) {
	const { user, updateUser, signOut } = useAuth();
	const navigate = useNavigate();
	const [firstName, setFirstName] = useState(
		user.displayName.split(' ')[0] || ''
	);
	const [lastName, setLastName] = useState(
		user.displayName.split(' ')[1] || ''
	);
	const [email, setEmail] = useState(user.email || '');
	const [username, setUsername] = useState('');
	const [affiliate, setAffiliate] = useState('');
	const [error, setError] = useState('');
	const wrapper = React.useRef(null);
	const [visible, setVisible] = useState(false);
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	const inputRef = useRef(null);

	const onDismiss = () => setVisible(false);

	useEffect(() => {
		const startUp = async () => {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			wrapper.current.scrollTop = 0;
			document.body.classList.add('account-settings');
			const profileRef = doc(db, 'userProfiles', user.uid);
			const profileSnap = await getDoc(profileRef);
			if (
				profileSnap.data().referrer_url === undefined ||
				profileSnap.data().referrer_url === ""
			) {
				await updateDoc(profileRef, {
					referrer_url: firstName + user.uid.substring(0, 7),
				});
			} else {
				setAffiliate(profileSnap.data().referrer_url);
			}
			const docRef = doc(db, 'users', user.uid);
			const docSnap = await getDoc(docRef);

			if (docSnap && docSnap.exists()) {
				setUsername(docSnap.data().username);
			}
		};
		startUp();
		return function cleanup() {
			document.body.classList.remove('account-settings');
		};
	}, [user.uid]);

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setIsCopied(false);
			}, 2000);
		}
	}, [isCopied]);

	const copyToClipboardAndSelect = (text) => {
		navigator.clipboard.writeText(text).then(
			() => {
				setIsCopied(true);
			},
			(err) => {
				console.error('Could not copy text: ', err);
			}
		);

		// Select the text
		inputRef.current.select();
	};

	const handleAccountUpdate = async () => {
		if (!firstName || !lastName) {
			setVisible(true);
			setError('Must have first and last name!');
			return;
		}
		if (!username) {
			setVisible(true);
			setError('Must have username!');
			return;
		} else if (username) {
			const docRef = doc(db, 'users', user.uid);
			const docSnap = await getDoc(docRef);
			if (docSnap && docSnap.exists()) {
				console.log('Username already exists!');
				setVisible(true);
				setError('Username already exists!');
				setInterval(() => {
					setError('')
					setVisible(false);
				}, 4000);
			} else {
				await updateDoc(doc(db, 'users', user.uid), {
					username: username,
				});
			}
		}
		try {
			await updateUser({ displayName: `${firstName} ${lastName}` });
		} catch (err) {
			setVisible(true);
			setError(err.message);
		}
	};

	return (
		<>
			<Helmet>
				<title>Settings | Learning With AI</title>
			</Helmet>
			<div ref={wrapper}>
				<div>
					<div className="m-4">
						<ProfileUpload className="mt-3" addBtnColor="primary" />
					</div>
					<Row className="m-4">
						<Col className="details" style={{ maxWidth: '600px' }}>
							<hr className="line-info" />
							<br />
							<Row>
								<Col className="align-self-center" md="3">
									<label
										className="labels"
										htmlFor="#username"
									>
										Username
									</label>
								</Col>
								<Col className="align-self-center" md="9">
									<FormGroup>
										<Input
											value={username}
											id="username"
											name="username"
											required
											type="text"
											onChange={(e) =>
												setUsername(e.target.value)
											}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col className="align-self-center" md="3">
									<label
										className="labels"
										htmlFor="#firstName"
									>
										First Name
									</label>
								</Col>
								<Col className="align-self-center" md="9">
									<FormGroup>
										<Input
											value={firstName}
											id="firstName"
											name="firstName"
											required
											type="text"
											onChange={(e) =>
												setFirstName(e.target.value)
											}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col className="align-self-center" md="3">
									<label
										className="labels"
										htmlFor="#lastName"
									>
										Last Name
									</label>
								</Col>
								<Col className="align-self-center" md="9">
									<FormGroup>
										<Input
											value={lastName}
											id="lastName"
											name="lastName"
											required
											type="text"
											onChange={(e) =>
												setLastName(e.target.value)
											}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col className="align-self-center" md="3">
									<label className="labels" htmlFor="#email">
										Email
									</label>
								</Col>
								<Col className="align-self-center" md="9">
									<FormGroup>
										<Input
											value={email}
											id="email"
											name="email"
											required
											type="email"
											className="text-white"
											onChange={(e) =>
												setEmail(e.target.value)
											}
											disabled
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col className="align-self-center" md="3">
									<label className="labels" htmlFor="#email">
										Referral Link
									</label>
								</Col>
								<Col className="align-self-center" md="9">
									<FormGroup>
										<div className="">
											<div
												onClick={() =>
													copyToClipboardAndSelect(
														`app.learningwith.ai?aff=${affiliate}`
													)
												}
												style={{ cursor: 'pointer' }}
											>
												<Input
													innerRef={inputRef} // Add this line
													value={`app.learningwith.ai?aff=${affiliate}`}
													id="affiliate"
													name="affiliate"
													className="text-white"
													required
													type="text"
													onChange={(e) =>
														setAffiliate(
															e.target.value
														)
													}
													readOnly
												/>
											</div>
											<Tooltip
												placement="bottom"
												isOpen={isCopied}
												target="affiliate"
											>
												Copied!
											</Tooltip>
										</div>
									</FormGroup>
								</Col>
							</Row>
							{error && (
								<Alert
									className="mt-4"
									color="warning"
									isOpen={visible}
									toggle={onDismiss}
								>
									{error}
								</Alert>
							)}
							<Row className="mt-2">
								<NavLink href="#" onClick={handlePortal}>
									Manage your billings account{' '}
								</NavLink>
							</Row>
							<Row className="mt-4 mb-5 pb-4">
								<Col className="d-block d-sm-flex justify-content-between">
									<div>
										<Button
											color="primary"
											type="button"
											className="mr-3 gradBtn"
											onClick={handleAccountUpdate}
										>
											Save Changes
										</Button>
										<Button
											color="secondary"
											type="button"
											onClick={() => navigate(-1)}
										>
											Cancel
										</Button>
									</div>
									<Button
										color="secondary"
										type="button"
										onClick={signOut}
									>
										<i className="fa fa-sign-out mr-2"></i>
										Logout
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
}
