import React, { useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import { useAuth } from "contexts/AuthContext";
import { uploadImageAndGetURL } from "helpers/uploadImage";

export default function ProfileUpload({
  addBtnColor,
  addBtnClasses,
  changeBtnColor,
  changeBtnClasses,
  removeBtnColor,
  removeBtnClasses,
}) {
  const { user, updateUser } = useAuth();
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    user.photoURL || defaultAvatar
  );
  const [loading, setLoading] = React.useState(false);


  const fileInput = React.useRef(null);
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(user.photoURL || defaultAvatar);
    fileInput.current.value = null;
  };
  const handleSave = async () => {
    if (!file) return;
    if (loading) return;
    setLoading(true);
    try {
      const url = await uploadImageAndGetURL(
        file,
        `/profile-images/${user.uid}`
      );
      await updateUser({ photoURL: url });
      setImagePreviewUrl(url);
      setLoading(false);
      setFile(null);
    } catch (err) {
      setLoading(false);
      alert("Error: " + err.message);
    }
  };
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div>
        <img src={imagePreviewUrl} alt="..." className="avatar avatar-2xl" />
      </div>
      <div>
        {file === null ? (
          <>
            <br />
            <Button
              color={addBtnColor}
              className="btn-round gradBtn"
              onClick={() => handleClick()}
            >
              Set Photo
            </Button>
          </>
        ) : (
          <span>
            {!loading && (
              <>
                <Button
                  color={changeBtnColor}
                  className={changeBtnClasses}
                  onClick={() => handleClick()}
                >
                  Change
                </Button>
                <br />
                <Button
                  color={removeBtnColor}
                  className={removeBtnClasses}
                  onClick={() => handleRemove()}
                >
                  <i className="fa fa-times" /> Remove
                </Button>
                <br />
              </>
            )}
            <Button
              color="success"
              className="btn-round"
              onClick={() => handleSave()}
            >
              <i
                className={`fa ${loading ? "fa-spinner fa-spin" : "fa-check"}`}
              ></i>
              {loading ? " Uploading" : " Save"}
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

ProfileUpload.defaultProps = {
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary",
};

ProfileUpload.propTypes = {
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
};
