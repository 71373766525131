import { useState, useEffect } from 'react';
import { downloadImage } from '../../helpers/util';
import { FaDownload } from 'react-icons/fa';

export default function DownloadImageButton({ isDownloadMobbile, imageUrl, text }) {
	const [download, setDownloaded] = useState(false);

	useEffect(() => {
		if (!download) return;
		const timeout = setTimeout(() => {
			setDownloaded(false);
		}, 3000);

		return () => clearTimeout(timeout);
	}, [download]);

	const handleDownload = () => {
		if (download) return;
		const success = downloadImage(imageUrl);
		setDownloaded(success);
	};

	return (
		<span className="d-flex flex-row-reverse" onClick={handleDownload}>
			{download ? 'Downloaded!' : text || 'Download'}
			<FaDownload className="icon mr-1" />
		</span>
	);
}
