import hljs from "highlight.js"
import 'highlight.js/styles/atom-one-dark.css'
import CopyToClipboadButton from "./CopyToClipboardButton"
import { useMemo } from 'react'
import { Row, Col } from "reactstrap"


export default function CodeBlock({ inline, children }) {

  const initialCode = children[0]
  const highlightedCode = useMemo(() => {
    return !inline && hljs.highlightAuto(initialCode).value
  }, [initialCode])

  if(inline) {
    return <code>{ children }</code>
  }

  return (
    <div className='code-block'>
      <Row className="bg-dark">
        <Col className='mr-1'>
          <CopyToClipboadButton content={children[0]} text="Copy Code" />
        </Col>
      </Row>
      <div className='code'>
        <code dangerouslySetInnerHTML={{ __html: highlightedCode }}></code>
      </div>
    </div>
  )
}