import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import defaultImage from "assets/img/image_placeholder.jpg";

export default function ImageUpload({
  addBtnColor,
  addBtnClasses,
  changeBtnColor,
  changeBtnClasses,
  removeBtnColor,
  removeBtnClasses,
  onSubmit,
}) {
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
  const [dragActive, setDragActive] = useState(false);
  const [validFileType, setValidFileType] = useState(true);
  const fileInput = useRef(null);

  const acceptedFileTypes = ["application/pdf", "image/jpeg", "image/png"];

  useEffect(() => {
    let depth = 0;
    const handleDragEnter = (e) => {
      setDragActive(true);
      depth++;
      console.log(e.dataTransfer);
      const file = e.dataTransfer.files?.[0];
      if (!file) return;
      if (!acceptedFileTypes.includes(file.type)) {
        setValidFileType(false);
      }
    };
    const handleDragLeave = (e) => {
      depth--;
      if (depth <= 0) {
        setDragActive(false);
        setValidFileType(true);
      }
    };

    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("dragleave", handleDragLeave);

    return () => {
      document.removeEventListener("dragenter", handleDragEnter);
      document.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);

  useEffect(() => {
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreviewUrl(defaultImage);
    }
  }, [file]);

  const handleChange = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
  };

  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!file) return;
    onSubmit(file);
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(defaultImage);
    fileInput.current.value = null;
  };

  const handleFile = (file) => {
    if (acceptedFileTypes.includes(file.type)) {
      setFile(file);
    } else {
      setValidFileType(false);
      setTimeout(() => setValidFileType(true), 2000);
    }
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragActive(false);

    if (e.dataTransfer.files?.[0]) {
      handleFile(e.dataTransfer.files[0]);
    }

    console.log("Dropping files!");
  };

  return (
    <div className="fileinput text-center">
      <input
        type="file"
        onChange={handleChange}
        ref={fileInput}
        accept={acceptedFileTypes.join(",")}
      />
      <div className="thumbnail custom-thumbnail">
        <img src={imagePreviewUrl} />
        {(dragActive || !validFileType) && (
          <div
            className={`drag-n-drop ${!validFileType && "invalid"}`}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {validFileType ? (
              <>
                Drag here to upload.
                <br />
                Maximum file size 5MB
              </>
            ) : (
              "Invalid file type"
            )}
          </div>
        )}
      </div>
      <div>
        {file === null ? (
          <Button
            color={addBtnColor}
            className={addBtnClasses}
            onClick={() => handleClick()}
          >
            Upload Image or PDF
          </Button>
        ) : (
          <span>
            <Button
              color={changeBtnColor}
              className={changeBtnClasses}
              onClick={() => handleClick()}
            >
              Change
            </Button>
            <Button
              color={removeBtnColor}
              className={removeBtnClasses}
              onClick={() => handleRemove()}
            >
              <i className="fa fa-times" /> Remove
            </Button>
          </span>
        )}
        {file && (
          <div className="mt-3">
            <Button
              color="success"
              className="btn-round"
              onClick={handleSubmit}
            >
              Extract text from file
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

ImageUpload.defaultProps = {
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary",
};

ImageUpload.propTypes = {
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link",
  ]),
};
