import { useState, createContext, useContext } from 'react';

const RouteContext = createContext();

export function useRoute() {
	return useContext(RouteContext);
}

export const RouteProvider = ({ children }) => {
	const [isLogin, setIsLogin] = useState(false);

	const handleLogin = (value) => {
		setIsLogin(value)
	}

	let value = {
		isLogin,
		handleLogin
	}

	return (
		<RouteContext.Provider value={value}>{children}</RouteContext.Provider>
	);
};
