import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Alert,
} from "reactstrap";
import "../ForgotPassword.css";

import { useAuth } from "contexts/AuthContext";
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";

export default function LoginPage() {
  const { forgotPassword } = useAuth();
  const navigate = useNavigate();
  const [emailFocus, setEmailFocus] = useState(undefined);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [emailBorderColor, setEmailBorderColor] = useState('#2b3553');

  const handleForgotPassword = (e) => {
    e.preventDefault();
    forgotPassword(email, password)
      .then(() => {
        Swal.fire({
          title: "Success!",
          text: "An email has been sent to your inbox!",
          icon: "success",
          confirmButtonText: "Continue",
        });
        navigate('/login');
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
			}}
		>
			<Helmet>
				<title>Forgot Password | Learning with AI</title>
			</Helmet>
			<div className="glassContainer">
				<h1 className="font-weight-bold header">Forgot Password</h1>
				<p className="text-align-center mb-5">
					Enter your email below to reset your password!
				</p>
				{error && <Alert color="warning">{error}</Alert>}
				<Form action="" className="form" method="">
					<InputGroup
						className={classnames('input-lg', {
							'input-group-focus': emailFocus,
						})}
						style={{ width: '20vw' }}
					>
						<InputGroupText
							style={{
								borderWidth: '1px',
								borderStyle: 'solid',
								borderLeftColor: emailBorderColor,
								borderTopColor: emailBorderColor,
								borderBottomColor: emailBorderColor,
								borderRight: '0',
								borderTopRightRadius: '0',
								borderBottomRightRadius: '0',
								marginRight: '0',
							}}
						>
							<i className="tim-icons icon-email-85" />
						</InputGroupText>
						<Input
							placeholder="Email"
							type="email"
							value={email}
							onFocus={(e) => {
                setEmailBorderColor('#1d8cf8');
								setEmailFocus(true);
							}}
							onBlur={(e) => {
                setEmailBorderColor('#2b3553');
								setEmailFocus(false);
							}}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</InputGroup>
					<button
						className="continueBtn"
						color="primary"
						onClick={handleForgotPassword}
					>
						Continue
					</button>
				</Form>
			</div>
		</div>
  );
}
