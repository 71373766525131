import { createContext, useRef, useState } from "react";

export const TypeContext = createContext();

let initialValue = "";

const examples = [
  {
    id: 1,
    name: "write an essay",
    prompts: [
      "write an essay about Alexander Hamilton",
      "write an essay about Bill Gates",
      "write an essay about Elon Musk",
    ],
  },
  {
    id: 2,
    name: "discussion on post",
    prompts: [
      "discussion on Alexander Hamilton",
      "discussion on Bill Gates",
      "discussion on Elon Musk",
    ],
  },
  {
    id: 3,
    name: "solve multiple choice",
    prompts: [
      "solve multiple choice on Mathematics",
      "solve multiple choice on English",
      "solve multiple choice on Chemistry",
    ],
  },
  {
    id: 4,
    name: "Make counterpoints to X",
    prompts: [
      "Make counterpoints to Joe Biden's Speech",
      "Make counterpoints to Elon Musk's tweet",
      "Make counterpoints to anything",
    ],
  },
  {
    id: 5,
    name: "Apply book principles to something. ",
    prompts: [
      "Apply book principles to swimming",
      "Apply book principles to sports",
      "Apply book principles to technology",
    ],
  },
  {
    id: 6,
    name: "Summarize this passage: X",
    prompts: [
      "Summarize this passage: Chapter 2 of A Software development Project",
      "Summarize this passage: Chapter 2 of A Civil Engineering Project",
      "Summarize this passage: Chapter 2 of A Testing automation Project",
    ],
  },
  {
    id: 7,
    name: "Write an opinion about X",
    prompts: [
      "Write an opinion about Web3",
      "Write an opinion about BLM movement",
      "Write an opinion about COVID-19",
    ],
  },
  {
    id: 8,
    name: "Cite sources",
    prompts: [
      "Cite sources from Charles W.'s paper on Radio advertising",
      "Cite sources from Reactjs Documentation",
      "Cite sources from Vlad Shkurin's thesis on Blockchain technology",
    ],
  },
  {
    id: 9,
    name: "Write a speech.",
    prompts: [
      "Write a speech on drug abuse",
      "Write a speech on discipline",
      "Write a speech on Civilization",
    ],
  },
  {
    id: 10,
    name: "Make a presentation.",
    prompts: [
      "Make a presentation on Human Evolution",
      "Make a presentation on The Birth of Jesus",
      "Make a presentation on Software Design and Development",
    ],
  },
];

export function TypeProvider({ children }) {
  const [value, setValue] = useState(initialValue);
  const [isTyping, setIsTyping] = useState(false);
  const [sidebar, setSidebar] = useState(false);

  const showSideBar = () => setSidebar(!sidebar);

  const messageRef = useRef();
  let animationRef = useRef();

  let wait = async (time) => {
    // animationCounter = true
    return await new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  };
  let cancel;

  let makeLine = async (l) => {
    // animationCounter = true
    let string = "";
    // animationCounter = true
    for (let i of l) {
      // blockRef.current.style.display='block';
      // animationCounter = true;
      string += i;
      messageRef.current.innerHTML = string;
      setValue(string);
      await wait(20);
    }
  };

  let start = async (arr) => {
    let animationCounter = true;
    setSidebar(!sidebar);
    setIsTyping(true);
    console.log(isTyping);
    if (animationCounter) {
      if (cancel) cancel();
      let cancelMe = false;
      cancel = () => {
        cancelMe = true;
      };
      for (let l of arr) {
        // animationCounter = true
        await makeLine(l);
        // blockRef.current.style.display='block';
        await wait(1000);
        if (cancelMe) {
          break;
        }
      }
    }
    setIsTyping(false);
  };

  return (
    <TypeContext.Provider
      value={{
        isTyping,
        start,
        value,
        setValue,
        initialValue,
        messageRef,
        showSideBar,
        sidebar,
        setSidebar,
        examples,
        animationRef,
      }}
    >
      {children}
    </TypeContext.Provider>
  );
}
