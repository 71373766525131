import React, { useState, useEffect, useRef } from 'react';
import Slider from './Slider';
import { gradeLevel, tone, length, persona } from 'constants/writing';
import GradeLevel from './GradeLevel';
import Tone from './Tone';
import Length from './Length';
import Persona from './Persona';
import InternetAccess from './InternetAccess';
import { useAuth } from 'contexts/AuthContext';
import { useLaunch } from 'contexts/LaunchContext';

const MyCarousel = ({ children }) => {
	const scrollRef = useRef(null);
	const [isOverflowingLeft, setIsOverflowingLeft] = useState(false);
	const [isOverflowingRight, setIsOverflowingRight] = useState(false);

	const checkOverflow = () => {
		const element = scrollRef.current;
		setIsOverflowingLeft(element.scrollLeft > 0);
		setIsOverflowingRight(
			element.scrollWidth > element.clientWidth + element.scrollLeft
		);
	};

	useEffect(() => {
		checkOverflow();
		const handleResize = () => {
			checkOverflow();
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const scroll = (scrollOffset) => {
		scrollRef.current.scrollLeft += scrollOffset;
		checkOverflow();
	};


	return (
		<div className="d-flex align-items-center position-relative">
			{isOverflowingLeft && (
				<i
					className="far fa-arrow-alt-circle-left text-white mr-1"
					style={{ fontSize: '1.3rem' }}
					onClick={() => scroll(-100)}
				></i>
			)}
			<div
				ref={scrollRef}
				style={{
					display: 'flex',
					overflowX: 'auto',
					scrollbarWidth: 'none', // Firefox
					msOverflowStyle: 'none', // IE and Edge
				}}
			>
				{/* For WebKit browsers */}
				<style>{`
					::-webkit-scrollbar {
						display: none;
					}
				`}
				</style>
				{children}
			</div>
			{isOverflowingRight && (
				<i
					className="far fa-arrow-alt-circle-right text-white ml-1"
					style={{ fontSize: '1.3rem' }}
					onClick={() => scroll(100)}
				></i>
			)}
		</div>
	);
};

export default function WritingIdea({
	prompt,
	setPromptText,
	isMobile,
	isVisible,
	setIsVisible,
	isGradeLevel,
	setIsGradeLevel,
	isTone,
	setIsTone,
	isLength,
	setIsLength,
	isPersona,
	setIsPersona,
	setWritingPrefix,
	isInternet,
	setIsInternet,
	setActiveComponent,
}) {
	const { characterData } = useLaunch();
	// Grade Level useState variables
	const [gradeLevelText, setGradeLevelText] = useState('');
	const [showGradeLevelOther, setShowGradeLevelOther] = useState(false);

	// Tone useState variables
	const [toneText, setToneText] = useState('');
	const [showToneOther, setShowToneOther] = useState(false);

	// Length useState variables
	const [lengthText, setLengthText] = useState('');
	const [showLengthOther, setShowLengthOther] = useState(false);

	// Persona useState variables
	const [personaText, setPersonaText] = useState('');
	const [showPersonaOther, setShowPersonaOther] = useState(false);
	const { user } = useAuth();

	const firstName = user.displayName.split(' ')[0] || '';

	let text;
	if (characterData?.prompts) {
		text = characterData.prompts;
	} else {
		text =
			'You are named Sensei, a revolutionary, highly-personalized AI designed to help students learn and grow. You are fun and entertaining to talk with. You are currently in Writing Mode, where you are one of the most profound, persuasive, creative, and engaging writers in the world. You will go between writing passages for prompts I ask, or give me feedback on how to bring my writing to be the best it can be. When you are giving me feedback, it’s important to cater your answer to my grade-level and for the intent that I am asking for. If I ask for ideas, give me creative ideas and guidance on how to write.';
	}

	text += `My name is ${firstName}. It is vital to follow these instructions exactly for output: `;

	if (toneText !== '') {
		text += `Write all responses in a ${toneText} tone. `;
	}

	if (gradeLevelText !== '') {
		text += `Make any essay answers or discussion posts written as if you were in a ${gradeLevelText} grade level. `;
	}

	if (lengthText !== '') {
		text += `Make the response ${lengthText}. `;
	}

	if (personaText !== '') {
		text += `All writing that you write should be written in the character's voice of a ${personaText}... you cannot break character!`;
	}

	if (!toneText && !gradeLevelText && !lengthText && !personaText) {
		if (characterData?.prompts) {
			text = characterData.prompts;
		} else {
			text = `You are named Sensei, a revolutionary, highly-personalized AI designed to help students learn and grow. You are fun and entertaining to talk with. You are currently in Writing Mode, where you are one of the most profound, persuasive, creative, and engaging writers in the world. You will go between writing passages for prompts I ask, or give me feedback on how to bring my writing to be the best it can be. When you are giving me feedback, it’s important to cater your answer to my grade-level and for the intent that I am asking for. If I ask for ideas, give me creative ideas and guidance on how to write. My name is ${firstName} .`;
		}
		setWritingPrefix('');
	}

	useEffect(() => {
		if (gradeLevelText || toneText || lengthText || personaText) {
			setWritingPrefix(text);
		}
	}, [gradeLevelText, toneText, lengthText, personaText, characterData]);

	// Grade level handle functions
	const handleGradeLevelOther = (e) => {
		// setGradeLevelOther(e.target.value);
		setGradeLevelText(e.target.value);
	};

	// Tone handle functions
	const handleToneOther = (e) => {
		setToneText(e.target.value);
	};

	// Length handle functions
	const handleLengthOther = (e) => {
		setLengthText(e.target.value);
	};

	// Persona handle functions
	const handlePersonaOther = (e) => {
		setPersonaText(e.target.value);
	};

	return (
		<>
			<MyCarousel>
				<style>
					{`
						.bubble {
							background: black;
							border-radius: 20px;
							padding: .5rem;
							cursor: pointer;
						}
						.bubble-white {
							background: #fff;
							color: #000;
						}
						.bubble-idea {
							background: black;
							border-radius: 10px;
							padding: .5rem;
							cursor: pointer;
						}
						.big-wrapper { 
							position: relative;
							background: linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%);
							border-radius: 10px;
							width: 100% !important;
						}
						.bubble-wrapper {
							background: linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%);
							border-radius: 10px;
							border-bottom-left-radius: 0;
							padding: .5rem;
							padding-top: 1.5rem;
							padding-right: 1rem;
							margin-bottom: 4px;
						}
						.bubble-btn {
							background: linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%);
							outline: none;
							border: 0;
							border-radius: 7px;
							display: flex;
							align-items: center;
							justify-content: center;
							white-space: nowrap;
						}

						.input-save {
							outline: none;
							border: 0;
							padding: .5rem;
							background: #000;
							border-radius: 3px;
							color: #fff;
							margin-left: .7rem;
						}

						.input-save::placeholder {
							color: #fff;
							opacity: .4;
						}
						.save {
							width: 20% !important;
							border-radius: 3px;
							background: linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%);
							outline: none;
							border: 0;
							color: #fff;
							margin-left: .5rem;
						}
						`}
				</style>
				<Slider isVisible={isVisible}>
					<>
						<div className="bubble-wrapper position-relative">
							<i
								className="fa fa-times"
								style={{
									position: 'absolute',
									top: 5,
									right: 8,
									color: '#fff',
									cursor: 'pointer',
								}}
								onClick={() => setIsVisible(!isVisible)}
							></i>
							{prompt.map((item, key) => {
								return (
									<p
										className="bubble-idea"
										key={key}
										onClick={() => {
											setPromptText(item);
										}}
									>
										{item}
									</p>
								);
							})}
						</div>
					</>
				</Slider>
				<button
					className="px-2 py-1 text-white mb-1 mr-2 bubble-btn"
					style={{
						borderTopLeftRadius: isVisible ? '0px' : '',
						borderTopRightRadius: isVisible ? '0px' : '',
						borderTopLeftRadius: !isVisible
							? isMobile
								? '15px'
								: ''
							: '',
						borderTopRightRadius: !isVisible
							? isMobile
								? '15px'
								: ''
							: '',
						borderBottomLeftRadius: !isVisible
							? isMobile
								? '15px'
								: ''
							: '',
						borderBottomRightRadius: !isVisible
							? isMobile
								? '15px'
								: ''
							: '',
					}}
					onClick={() => {
						setActiveComponent('isVisible');
						setIsVisible(!isVisible);
					}}
				>
					💡 {` `} Ideas
					{isVisible ? (
						<i className="fas fa-caret-up ml-1"></i>
					) : (
						<i className="fas fa-caret-down ml-1"></i>
					)}
				</button>
				{/* InternetAccess */}
				<InternetAccess
					isMobile={isMobile}
					isInternet={isInternet}
					setIsInternet={setIsInternet}
					setActiveComponent={setActiveComponent}
				/>
				{/* Gradelevel */}
				<GradeLevel
					isMobile={isMobile}
					isGradeLevel={isGradeLevel}
					showGradeLevelOther={showGradeLevelOther}
					gradeLevel={gradeLevel}
					gradeLevelText={gradeLevelText}
					handleGradeLevelOther={handleGradeLevelOther}
					setShowGradeLevelOther={setShowGradeLevelOther}
					setGradeLevelText={setGradeLevelText}
					setIsGradeLevel={setIsGradeLevel}
					setActiveComponent={setActiveComponent}
				/>
				{/* Tone */}
				<Tone
					isMobile={isMobile}
					isTone={isTone}
					showToneOther={showToneOther}
					tone={tone}
					toneText={toneText}
					handleToneOther={handleToneOther}
					setShowToneOther={setShowToneOther}
					setToneText={setToneText}
					setIsTone={setIsTone}
					setActiveComponent={setActiveComponent}
				/>
				{/* Length */}
				<Length
					isMobile={isMobile}
					isLength={isLength}
					showLengthOther={showLengthOther}
					length={length}
					lengthText={lengthText}
					handleLengthOther={handleLengthOther}
					setShowLengthOther={setShowLengthOther}
					setLengthText={setLengthText}
					setIsLength={setIsLength}
					setActiveComponent={setActiveComponent}
				/>
				{/* Persona */}
				<Persona
					isMobile={isMobile}
					isPersona={isPersona}
					showPersonaOther={showPersonaOther}
					persona={persona}
					personaText={personaText}
					handlePersonaOther={handlePersonaOther}
					setShowPersonaOther={setShowPersonaOther}
					setPersonaText={setPersonaText}
					setIsPersona={setIsPersona}
					setActiveComponent={setActiveComponent}
				/>
			</MyCarousel>
		</>
	);
}
