import React, { useState } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import {
	Form,
	Input,
	// InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Alert,
} from 'reactstrap';

import { useAuth } from 'contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useRoute } from 'contexts/RouteContext';

import { db } from 'lib/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import axios from 'axios';

const CACHE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours

export default function LoginPage() {
	const { signInWithGoogle, login, reloadAuth } = useAuth();
	const navigate = useNavigate();
	const [firstNameFocus, setFirstNameFocus] = useState(undefined);
	const [lastNameFocus, setLastNameFocus] = useState(undefined);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [emailBorderColor, setEmailBorderColor] = useState('#2b3553');
	const [passwordBorderColor, setPasswordBorderColor] = useState('#2b3553');
	const { handleLogin } = useRoute();
	const [loading, setLoading] = useState(false);
	const [googleLoading, setGoogleLoading] = useState(false);

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		document.body.classList.add('login-page');
		return function cleanup() {
			document.body.classList.remove('login-page');
		};
	}, []);

	const updateReferrals = async (referrer_url, referralUid) => {
		try {
			const response = await axios.post(
				'https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/affiliate/update_user_referrals',
				// 'http://127.0.0.1:8000/api/v1/affiliate/update_user_referrals',
				{
					referrer_url: referrer_url,
					referralUid: referralUid,
				}
			);
			console.log(response.data);
		} catch (error) {
			console.error(error);
		}
	};

	const createUserProfile = async (user) => {
		try {
			let data = {
				word_count: 0,
				is_subscribed: false,
				checkout_session_id: '',
				affiliate: localStorage.getItem('referrer_url') || '',
				referral_list: [],
				paid_referrals: 0,
				unpaid_referrals: 0,
				referrer_url: '',
				sensei_intro: false
			};
			await setDoc(doc(db, 'userProfiles', user.uid), data);
			let referrer_url = localStorage.getItem('referrer_url') || '';
			if (referrer_url !== '') {
				await updateReferrals(referrer_url, user.uid);
				console.log('Updated referral list');
			}
			console.log('Created new profile for user:', user);
		} catch (error) {
			console.error('Error creating user profile:', error);
		}
	};

	const callCreateUserProfile = async (user) => {
		const docRef = doc(db, 'userProfiles', user.uid);
		const docSnap = await getDoc(docRef);

		if (docSnap && docSnap.exists()) {
			console.log('User profile already exists!');
			return true;
		} else {
			await createUserProfile(user);
			return false;
		}
	};

	const handleGoogleSignIn = async () => {
		console.log('google');
		setGoogleLoading(true)
		signInWithGoogle()
			.then(async (e) => {
				await callCreateUserProfile(e.user);
				localStorage.setItem(
					'loginExpiry',
					Date.now() + CACHE_EXPIRY_TIME
				);
				console.log(e)
				if (e.user.emailVerified && e.user.displayName) {
					reloadAuth();
					navigate('/mode/learning');
				} else {
					navigate('/onboarding');
				}
				setGoogleLoading(false)
			})
			.catch((err) => {
				setError(err.message);
				setGoogleLoading(false)
			});
	};

	const handleSignIn = async (e) => {
		e.preventDefault();
		setLoading(true)
		login(email, password)
			.then(async (e) => {
				await callCreateUserProfile(e.user);
				localStorage.setItem(
					'loginExpiry',
					Date.now() + CACHE_EXPIRY_TIME
				);
				if (e.user.emailVerified && e.user.displayName) {
					reloadAuth();
					navigate('/mode/learning');
				} else {
					handleLogin(true);
					navigate('/onboarding');
				}
				setLoading(false)
			})
			.catch((err) => {
				setError(err.message);
				setLoading(false)
			});
	};

	return (
		<>
			<Helmet>
				<title>Log In | Learning with AI</title>
			</Helmet>
			<div className="position-relative">
				<style>
					{`
						@media screen and (max-width: 450px) {
							.logo-div {
								padding-top: 1.6rem !important;
							}	
						}
					`}
				</style>
				<div className="text-center position-absolute w-100 py-3 logo-div">
					<img
						src="/logo.png"
						alt="logo"
						style={{
							marginTop: '4px',
							height: '6.4vh',
							width: 'auto',
						}}
					/>
				</div>
				<div className="page-header login_page_ll">
					<Container className="pageContainer">
						<h1 className="font-weight-bold header">Login</h1>
						{error && <Alert color="warning">{error}</Alert>}
						<Form action="" className="form" method="">
							<InputGroup
								className={classnames('input-lg', {
									'input-group-focus': firstNameFocus,
								})}
							>
								<InputGroupText
									style={{
										borderWidth: '1px',
										borderStyle: 'solid',
										borderLeftColor: emailBorderColor,
										borderTopColor: emailBorderColor,
										borderBottomColor: emailBorderColor,
										borderRight: '0',
										borderTopRightRadius: '0',
										borderBottomRightRadius: '0',
										marginRight: '0',
									}}
								>
									<i className="tim-icons icon-email-85 " />
								</InputGroupText>
								<Input
									placeholder="Email"
									type="email"
									value={email}
									onFocus={(e) => {
										setFirstNameFocus(true);
										setEmailBorderColor('#1d8cf8');
									}}
									onBlur={(e) => {
										setFirstNameFocus(false);
										setEmailBorderColor('#2b3553');
									}}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</InputGroup>
							<InputGroup
								className={classnames('input-lg', {
									'input-group-focus': lastNameFocus,
								})}
							>
								<InputGroupText
									style={{
										borderWidth: '1px',
										borderStyle: 'solid',
										borderLeftColor: passwordBorderColor,
										borderTopColor: passwordBorderColor,
										borderBottomColor: passwordBorderColor,
										borderRight: '0',
										borderTopRightRadius: '0',
										borderBottomRightRadius: '0',
										marginRight: '0',
									}}
								>
									<i className="tim-icons icon-lock-circle" />
								</InputGroupText>
								<Input
									placeholder="Password"
									type="password"
									onFocus={(e) => {
										setPasswordBorderColor('#1d8cf8');
										setLastNameFocus(true);
									}}
									onBlur={(e) => {
										setPasswordBorderColor('#2b3553');
										setLastNameFocus(false);
									}}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
							</InputGroup>
							<div
								style={{ alignItems: 'flex-end' }}
								className="alignment"
							>
								<Link
									className="link footer-link"
									to="/forgot-password"
								>
									Forgot Password?
								</Link>
							</div>
							{loading ? 
								(
									<button
										className="loginBtn"
										color="primary"
										onClick={handleSignIn}
									>
										<div className="spinner-border spinner-border-sm" role="status">
											<span className="sr-only">Loading...</span>
										</div>
										<span> Log In</span>
									</button>
								)
								: 
								(
									<button
										className="loginBtn"
										color="primary"
										onClick={handleSignIn}
									>
										Log In
									</button>
								)
							}
							<div className="alignment">
								Don't have an account?
								<Link
									className="link footer-link"
									to="/sign-up"
								>
									Sign Up
								</Link>
							</div>
						</Form>
						<div className="lineContainer">
							<div className="line" />
							OR
							<div className="line" />
						</div>
						{googleLoading ? 
							(
								<button
									className="googleBtn"
									onClick={handleGoogleSignIn}
								>
									<div className="spinner-border spinner-border-sm" role="status">
										<span className="sr-only">Loading...</span>
									</div>
									<img
										src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
										alt="google icon"
										className="googleIcon"
									/>
									<span className="text">Continue with Google</span>
								</button>
							)
							: 
							(
								<button
									className="googleBtn"
									onClick={handleGoogleSignIn}
								>
									<img
										src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
										alt="google icon"
										className="googleIcon"
									/>
									<span className="text">Continue with Google</span>
								</button>
							)
						}
					</Container>
				</div>
			</div>
		</>
	);
}
