import React, { useState, useEffect, useMemo, Suspense } from 'react';
import {
	Nav,
	NavItem,
	NavLink,
	Card,
	Form,
	Input,
	InputGroup,
	Placeholder,
} from 'reactstrap';
import chemistryImg from 'assets/img/cards/chemistry.png';
import { ref, getDownloadURL } from 'firebase/storage';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import classnames from 'classnames';
import { db, storage, analytics } from 'lib/firebase';
import { useAuth } from 'contexts/AuthContext';
import {
	query,
	collection,
	orderBy,
	doc,
	getDoc,
	getDocs,
	updateDoc,
} from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { useLaunch } from 'contexts/LaunchContext';
import { useNavigate } from 'react-router-dom';
import Layout from 'components/Layout';
import { Helmet } from 'react-helmet';

const CACHE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours

const categories = [
	'all',
	'memes',
	'animals',
	'famous_people',
	'people',
	'movies_and_tV',
];

function AvatarList() {
	const [width, setWidth] = useState(window.innerWidth);
	const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const [searchOption, setSearchOption] = useState(categories[0]);
	const [char, setChar] = useState([]);
	const [savedChar, setSavedChar] = useState([]);
	const [cachedImageUrl, setCachedImageUrl] = useState([]);
	const [username, setUsername] = useState('');
	const [isOverflowingLeft, setIsOverflowingLeft] = useState(false);
	const [isOverflowingRight, setIsOverflowingRight] = useState(false);
	const { user } = useAuth();
	const [searchQuery, setSearchQuery] = useState('');
	const [isNavigating, setIsNavigating] = useState(false);
	const [filteredTools, setFilteredTools] = useState(char);
	const [isLoading, setIsLoading] = useState(true);
	const { setCharacter, char_len, chars, charSub, s_char, setS_char } =
		useLaunch();

	// function to check for overflow
	// function should be called when the component mounts
	// and also whenever the window is resized
	// should also call the checkOverflow function whenever the user scrolls
	useEffect(() => {
		const container = document.querySelector('.card-row');

		const checkOverflow = () => {
			const isLeft = container.scrollLeft > 0;
			const isRight =
				container.scrollWidth >
				container.clientWidth + container.scrollLeft;

			setIsOverflowingLeft(isLeft);
			setIsOverflowingRight(isRight);
		};

		setTimeout(checkOverflow, 1000);

		window.addEventListener('resize', checkOverflow);
		container.addEventListener('scroll', checkOverflow);

		return () => {
			window.removeEventListener('resize', checkOverflow);
			container.removeEventListener('scroll', checkOverflow);
		};
	}, [char, searchQuery]);

	useEffect(() => {
		const startUp = async () => {
			
			const docRef = doc(db, 'users', user.uid);
			const docSnap = await getDoc(docRef);

			if (docSnap && docSnap.exists()) {
				setUsername(docSnap.data().username);
			}
		};
		startUp();
	}, [user.uid]);

	const isSmallMobile = width <= 420;
	const isSm = width <= 576;
	const isSmallestMobile = width <= 372;
	const isSmallerMobile = width <= 400;
	const isMediumMobile = width <= 540;
	const isButtonMobile = width <= 570;
	const isMidMobile = width <= 579;
	const isMobile = width <= 800;
	const isTab = width <= 1000;

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	const cachedChar = useMemo(() => {
		const cachedData = localStorage.getItem('character');
		const cacheExpiry = localStorage.getItem('characterExpiry');
		if (cachedData && cacheExpiry && Date.now() < cacheExpiry) {
			return JSON.parse(cachedData);
		}
		return null;
	}, []);

	const saveToCache = (data) => {
		localStorage.setItem('character', JSON.stringify(data));
		localStorage.setItem('characterExpiry', Date.now() + CACHE_EXPIRY_TIME);
	};

	useEffect(() => {
		const filtered = char.filter(
			(tool) =>
				tool.categories.includes(searchOption) &&
				tool.name.toLowerCase().includes(searchQuery.toLowerCase())
		);
		setFilteredTools(filtered);
	}, [char, searchOption, searchQuery]);

	useEffect(() => {
		const getImageUrls = async (cards) => {
			const urls = await Promise.all(
				cards.map(async (tool) => {
					return { [tool.id]: await getImageUrl(tool.main_image) };
				})
			);

			setCachedImageUrl(urls);
			setIsLoading(false); // All images are loaded, set loading status to false
		};
		if (cachedChar) {
			const sortedData = sortCharBySavedChar(cachedChar, savedChar);
			setChar(sortedData);
			getImageUrls(sortedData);

			return;
		}
		const options = query(
			collection(db, 'characters'),
			orderBy('click_count', 'desc')
		);
		getDocs(options)
			.then((snapshot) => {
				const data = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				const sortedData = sortCharBySavedChar(data, savedChar);
				setChar(sortedData);
				saveToCache(sortedData);
				getImageUrls(sortedData);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [cachedChar, savedChar]);
	
	const filterImageUrl = (id) => {
		const tool = cachedImageUrl?.find((item) => Object.keys(item)[0] === id);
		return tool ? Object.values(tool)[0] : null;
	};

	const updateCharacterWordCount = async (id) => {
		const docRef = doc(db, 'characters', id);
		const docSnap = await getDoc(docRef);
		const char = docSnap.data().click_count;
		await updateDoc(docRef, { click_count: char + 1 });
	};

	const sortCharBySavedChar = (charData, savedCharData) => {
		const savedCharIds = new Set(savedCharData);
		const sortedData = [...charData].sort((a, b) => {
			if (savedCharIds.has(a.id) && !savedCharIds.has(b.id)) {
				return -1;
			} else if (!savedCharIds.has(a.id) && savedCharIds.has(b.id)) {
				return 1;
			} else {
				return 0;
			}
		});
		return sortedData;
	};

	useEffect(() => {
		async function fetchData() {
			if (user) {
				let save = await fetchUserProfileSavedChar(user.uid);
				if (save === undefined) {
					setSavedChar([]);
				} else {
					setSavedChar(save);
				}
			}
		}
		fetchData();
	}, [user]);

	const getImageUrl = async (path) => {
		const imageRef = ref(storage, path);
		try {
			const url = await getDownloadURL(imageRef);
			return url;
		} catch (error) {
			console.error(error);
		}
	};

	const addToSavedChar = async (uid, toolId) => {
		const docRef = doc(db, 'userProfiles', uid);
		const userProfileSnap = await getDoc(docRef);
		const savedChar = userProfileSnap.data().saved_char || []; // default to empty array if saved_cards doesn't exist yet
		if (!savedChar.includes(toolId)) {
			// add toolId to saved_cards if it's not already there
			savedChar.push(toolId);
		}
		await updateDoc(docRef, { saved_char: savedChar }); // update saved_cards field of userProfiles document
		setSavedChar([...savedChar, toolId]);
	};

	const removeFromSavedChar = async (uid, toolId) => {
		const docRef = doc(db, 'userProfiles', uid);
		const userProfileSnap = await getDoc(docRef);
		const savedChar = userProfileSnap.data().saved_char || [];
		const updatedSavedChars = savedChar.filter((id) => id !== toolId); // remove toolId from saved_cards
		await updateDoc(docRef, { saved_char: updatedSavedChars }); // update saved_cards field of userProfiles document
		setSavedChar(updatedSavedChars);
	};

	const fetchUserProfileSavedChar = async (uid) => {
		const docRef = doc(db, 'userProfiles', uid);
		const docSnap = await getDoc(docRef);
		return docSnap.data().saved_char;
	};

	const handleBookmarkClick = async (toolId) => {
		const savedChar = await fetchUserProfileSavedChar(user.uid);
		if (savedChar === undefined) {
			addToSavedChar(user.uid, toolId);
		} else {
			console.log('Save avatars', savedChar);
			const isSaved = savedChar.includes(toolId);
			if (isSaved) {
				removeFromSavedChar(user.uid, toolId);
			} else {
				addToSavedChar(user.uid, toolId);
			}
		}
	};

	const handleSearchInputChange = (event) => {
		setSearchQuery(event.target.value);
	};

	const checkedSaved = (id) => {
		if (savedChar.includes(id)) {
			return <i className="fas fa-star" style={{ color: 'white' }}></i>;
		} else {
			return <i className="far fa-star"></i>;
		}
	};

	const getImageUrlsForTools = async (tools) => {
		const urls = await Promise.all(
			tools.map((tool) => getImageUrl(tool.main_image))
		);
		return urls;
	};

	const handleSearchOptionChange = async (newOption) => {
		setSearchOption(newOption);
	};

	const displayInCaps = (text) => {
		if (text === 'all') {
			return 'All';
		} else if (text === 'animals') {
			return 'Animals';
		} else if (text === 'famous_people') {
			return 'Famous People';
		} else if (text === 'people') {
			return 'People';
		} else if (text === 'movies_and_tV') {
			return 'Movies and TV';
		} else if (text === 'memes') {
			return 'Memes';
		}
	};

	const scrollAmount = 300; // Amount to scroll (in pixels)

	const scrollLeft = () => {
		const container = document.querySelector('.card-row');
		container.scrollLeft -= scrollAmount;
	};

	const scrollRight = () => {
		const container = document.querySelector('.card-row');
		container.scrollLeft += scrollAmount;
	};

	return (
		<div>
			<Layout>
				<Helmet>
					<title>Avatar List | Learning With AI</title>
				</Helmet>
				<style>
					{`
					.top-row-char {
						display: flex;
						flex-wrap: wrap;
						margin-right: -15px;
						margin-left: -15px;
					}

					.card {
						cursor: pointer;
					}
					.character {
						height: 100%;
						background-color: rgba(30, 132, 227, 0.2);
						background: linear-gradient(107.62deg, rgba(30, 132, 227, 0.2) 0%,rgba(92, 38, 211, 0.2) 100% );
						boxShadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.3);
						&::-webkit-scrollbar {
							width: 1px;
							background-color: transparent;
						}
						
						&::-moz-scrollbar-scrollbar {
							width: 1px;
							background-color: transparent;
						}
					}

					.scroll-container {
						position: relative;
						padding-bottom: 0;
					}

					.card-row {
						display: flex;
						overflow-x: auto;
						scroll-snap-type: x mandatory;
						-webkit-overflow-scrolling: touch;
						padding-bottom: 0;
						margin-bottom: 0;
						scrollbar-width: none; /* For Firefox */
						
						/* For Chrome, Safari, and Edge */
						&::-webkit-scrollbar {
							display: none;
						}
					}


					.card-col > .card {
						width: 10rem;
						padding-bottom: 0;
						margin-bottom: 0;
					}
					@media screen and (min-width: 500px) {
						.card-col > .card {
							width: 10.6rem;
						}
					}
					@media screen and (min-width: 800px) {
						.card-col > .card {
							width: 13rem;
						}
					}

					.scroll-btn {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						background: linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%);
						color: #fff;
						border: none;
						font-size: 2em;
						cursor: pointer;
						z-index: 100000000;
						border-radius: 25%;
					}

					.scroll-left {
						left: 0;
					}

					.scroll-right {
						right: 0;
					}
                `}
				</style>
				<div className="character">
					<div className="">
						<div className="px-1 px-sm-3 px-md-4">
							<div className="d-flex justify-content-center align-items-center py-3 py-sm-4 position-relative">
								<i
									className="fas fa-arrow-left"
									style={{
										position: 'absolute',
										top: '50%',
										transform: 'translateY(-50%)',
										left: 10,
										fontSize: '2em',
										color: '#fff',
										cursor: 'pointer',
									}}
									onClick={() => {
										if (isNavigating) {
											return;
										} else {
											navigate(-1);
											setIsNavigating(true);
										}
									}}
								></i>
								<p
									className="my-0 pb-0 ml-3"
									style={{ fontSize: '1.9em' }}
								>
									Chat with an Avatar
								</p>
							</div>
							<div>
								<h4 className="pl-2 ml-1 d-flex justify-content-between">
									<span>Favorites</span>
									<button
										className="p-1 px-2"
										onClick={() => {
											if (charSub) {
												navigate('/create-avatar');
											} else {
												alert(
													'Only Big Brain users can create avatars!'
												);
											}
										}}
										style={{
											color: '#fff',
											background:
												'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
											borderRadius: '7px',
											outline: 'none',
											border: '0',
										}}
									>
										+ Create Avatar
									</button>
								</h4>
								<div className="scroll-container">
									{isOverflowingLeft && !isMobile && (
										<button
											className="scroll-btn scroll-left"
											onClick={(event) => {
												event.stopPropagation();
												scrollLeft();
											}}
										>
											❮
										</button>
									)}
									<div className="pb-0 mx-2 card-row">
										<div className="card-col px-1">
											<Card
												className="p-1 p-sm-3 m-0 card-raised d-flex card-mobile position-relative"
												onClick={() => {
													if (isNavigating) {
														return;
													} else {
														setS_char('sensei');
														setCharacter(null);
														navigate(-1);
														setIsNavigating(true);
													}
												}}
											>
												<div className="card-image mr-2 mr-sm-3 text-center">
													<LazyLoadImage
														src="/sensei2.png"
														style={{
															width: '90px',
															height: '90px',
														}}
														className="mt-3"
														placeholderSrc={
															chemistryImg
														}
														effect="blur"
													/>
												</div>
												<div className="card-content text-center">
													<h4 className="mb-0 mt-2 mb-sm-2">
														Sensei
													</h4>
													<h5
														className="mb-0 mt-2 px-1"
														style={{
															backgroundColor:
																'gray',
															width: 'min-content',
															margin: '0 auto',
														}}
													>
														@creator
													</h5>
												</div>
											</Card>
										</div>
										{filteredTools
											.filter(
												(tool) =>
													tool.creator.includes(
														username
													) &&
													tool.categories.includes(
														searchOption
													)
											)
											.map((tool, index) => {
												return (
													<Suspense
														key={index}
														fallback={
															<div className="card-col px-1">
																<Card className="p-1 p-sm-3 m-0 card-raised d-flex card-mobile position-relative">
																	<Placeholder
																		animation="glow"
																		tag="div"
																		style={{
																			width: '90px',
																			height: '90px',
																			borderRadius:
																				'50%',
																		}}
																	/>
																	<div className="card-content text-center">
																		<Placeholder
																			animation="glow"
																			tag="h4"
																			className="mb-0 mt-2 mb-sm-2"
																			style={{
																				width: '150px',
																			}}
																		/>
																		<Placeholder
																			animation="glow"
																			tag="h5"
																			className="mb-0 mt-2 px-1"
																			style={{
																				width: '120px',
																				height: '30px',
																				marginBottom:
																					'10px',
																			}}
																		/>
																		hello
																	</div>
																</Card>
															</div>
														}
													>
														<div
															className="card-col px-1"
															onClick={() => {
																if (isLoading) {
																	return;
																}
																updateCharacterWordCount(
																	tool.id
																);
															}}
															
														>
															<Card
																className="p-1 p-sm-3 m-0 card-raised d-flex card-mobile position-relative"
																onClick={() => {
																	if (
																		isNavigating
																	) {
																		return;
																	} else {
																		navigate(
																			-1
																		);
																		setIsNavigating(
																			true
																		);
																	}
																	if (
																		s_char ===
																		tool.name
																	) {
																		setS_char(
																			'sensei'
																		);
																		setCharacter(
																			null
																		);
																	} else {
																		if (
																			!charSub
																		) {
																			if (
																				char_len >=
																				3
																			) {
																				let the_chars =
																					[];
																				for (let i in chars) {
																					// checks if the name in chars
																					if (
																						chars[
																							i
																						]
																							.name ===
																						tool.name
																					) {
																						setS_char(
																							tool.name
																						);
																						setCharacter(
																							{
																								id: tool.id,
																								name: tool.name,
																								prompts:
																									tool.prompts,
																								intro: tool.intro,
																								creator:
																									tool.creator,
																								image: filterImageUrl(
																									tool.id
																								),
																								category:
																									tool
																										.categories[1],
																								visibility:
																									tool.visibility,
																								sh_description:
																									tool.short_description,
																								lg_description:
																									tool.long_description,
																								craft_message:
																									tool.craft_message,
																							}
																						);
																						break;
																					}
																					the_chars.push(
																						chars[
																							i
																						]
																							.name
																					);
																				}

																				// checks if the list has the two values
																				if (
																					the_chars.length ===
																					3
																				) {
																					let the_chars =
																						[];
																					for (let i in chars) {
																						the_chars.push(
																							chars[
																								i
																							]
																								.name
																						);
																					}
																					alert(
																						`You're only allowed to use three avatars, you have used ${the_chars.join(
																							' and '
																						)} for free plan.`
																					);
																				}
																				return;
																			}
																		}
																		setS_char(
																			tool.name
																		);
																		setCharacter(
																			{
																				id: tool.id,
																				name: tool.name,
																				prompts:
																					tool.prompts,
																				intro: tool.intro,
																				creator:
																					tool.creator,
																				image: filterImageUrl(
																					tool.id
																				),
																				category:
																					tool
																						.categories[1],
																				visibility:
																					tool.visibility,
																				sh_description:
																					tool.short_description,
																				lg_description:
																					tool.long_description,
																				craft_message:
																					tool.craft_message,
																			}
																		);
																	}
																}}
															>
																<div className="card-image mr-2 mr-sm-3 text-center">
																	<LazyLoadImage
																		src={filterImageUrl(
																			tool.id
																		)}
																		style={{
																			width: '90px',
																			height: '90px',
																			borderRadius:
																				'50%',
																			padding:
																				tool.creator ===
																				'creator'
																					? ''
																					: '4px',
																		}}
																		className="mt-3"
																		placeholderSrc={
																			chemistryImg
																		}
																		effect="blur"
																	/>
																</div>
																<div className="card-content text-center">
																	<h4 className="mb-0 mt-2 mb-sm-2">
																		{
																			tool.name
																		}
																	</h4>
																	<h5
																		className="mb-0 mt-2 px-1"
																		style={{
																			backgroundColor:
																				'gray',
																			width: 'min-content',
																			margin: '0 auto',
																		}}
																	>
																		@
																		{
																			tool.creator
																		}
																	</h5>
																</div>
																<div
																	className="bookmark position-absolute"
																	style={{
																		// top: 0,
																		right: 10,
																		fontSize:
																			'1.4rem',
																	}}
																	data-id={
																		tool.id
																	}
																	onClick={(
																		event
																	) => {
																		event.stopPropagation(); // prevent click from bubbling up to Card
																		const id =
																			event
																				.currentTarget
																				.dataset
																				.id;
																		navigate(
																			`/edit-avatar/${id}`
																		);
																	}}
																>
																	<i className="fas fa-pencil-alt"></i>
																</div>
															</Card>
														</div>
													</Suspense>
												);
											})}
									</div>
									{isOverflowingRight && !isMobile && (
										<button
											className="scroll-btn scroll-right"
											onClick={(event) => {
												event.stopPropagation();
												scrollRight();
											}}
										>
											❯
										</button>
									)}
								</div>
								.
								<Form
									className=""
									style={{
										flexShrink: 1,
										width: '100%',
										minWidth: '200px',
										paddingLeft: isMobile
											? '.7rem'
											: '.5rem',
										paddingRight: isMobile
											? '.7rem'
											: '.5rem',
									}}
								>
									<style>
										{`
                                    input.custom-input::selection {
                                        background:  hsla(234, 91%, 47%, 1);
                                        color: white;
                                    }

                                    input.custom-input::-moz-selection {
                                        background: hsla(234, 91%, 47%, 1);
                                        color: white;
                                    }

                                    input.custom-input::-webkit-selection {
                                        background: hsla(234, 91%, 47%, 1);
                                        color: white;
                                    }
                                    `}
									</style>
									<InputGroup
										className="position-relative d-flex"
										style={{
											marginTop: isSm ? '-2px' : '1px',
										}}
									>
										<span className="position-absolute mt-1 mt-sm-2 pt-1 ml-3 top-50 start-0 translate-middle-y">
											<i
												className="fas fa-search"
												style={{ marginTop: '3.5px' }}
											></i>
										</span>
										<Input
											className="custom-input pl-5 mt-0 mt-sm-1 rounded-pill"
											type="text"
											placeholder="Search avatars"
											value={searchQuery}
											onChange={handleSearchInputChange}
											style={{
												border: '1px solid rgba(50, 50, 50, 0.9)',
											}}
										/>
									</InputGroup>
								</Form>
								<Nav
									className="nav-pills-primary d-flex  flex-wrap"
									pills
									role="tablist"
								>
									{categories.map((category, key) => (
										<NavItem key={key}>
											<NavLink
												to="/"
												className={classnames(
													'pointer',
													{
														active:
															searchOption ===
															category,
														gradBtn:
															searchOption ===
															category,
													}
												)}
												style={{
													padding: isSmallestMobile
														? '.5rem 1.45rem'
														: isSmallerMobile
														? '.5rem .7rem'
														: isMediumMobile
														? '.5rem 1.45rem'
														: '.7rem 1.45rem',
												}}
												onClick={() =>
													handleSearchOptionChange(
														category
													)
												}
											>
												{displayInCaps(category)}
											</NavLink>
										</NavItem>
									))}
								</Nav>
								<div className="row d-flex m-0 mt-3">
									{filteredTools
										.filter(
											(tool) =>
												tool.visibility.includes(
													'public'
												) ||
												tool.creator.includes(
													username || 'creator'
												)
										)
										.map((tool, index) => {
											return (
												<div
													key={index}
													className="px-3 col-6 col-sm-4 col-md-3 col-xl-2 mb-4 mb-sm-3 p-sm-3 m-0 card-raised  card-mobile position-relative"
													onClick={() => {
														if (isLoading) {
															return;
														}
														updateCharacterWordCount(
															tool.id
														);
														if (isNavigating) {
															return;
														} else {
															navigate(-1);
															setIsNavigating(
																true
															);
														}
														if (
															s_char === tool.name
														) {
															setS_char('sensei');
															setCharacter(null);
														} else {
															if (!charSub) {
																if (
																	char_len >=
																	3
																) {
																	let the_chars =
																		[];
																	for (let i in chars) {
																		// checks if the name in chars
																		if (
																			chars[
																				i
																			]
																				.name ===
																			tool.name
																		) {
																			setS_char(
																				tool.name
																			);
																			setCharacter(
																				{
																					id: tool.id,
																					name: tool.name,
																					prompts:
																						tool.prompts,
																					intro: tool.intro,
																					creator:
																						tool.creator,
																					image: filterImageUrl(
																						tool.id
																					),
																					category:
																						tool
																							.categories[1],
																					visibility:
																						tool.visibility,
																					sh_description:
																						tool.short_description,
																					lg_description:
																						tool.long_description,
																					craft_message:
																						tool.craft_message,
																				}
																			);
																			break;
																		}
																		the_chars.push(
																			chars[
																				i
																			]
																				.name
																		);
																	}

																	// checks if the list has the two values
																	if (
																		the_chars.length ===
																		3
																	) {
																		let the_chars =
																			[];
																		for (let i in chars) {
																			the_chars.push(
																				chars[
																					i
																				]
																					.name
																			);
																		}
																		alert(
																			`You're only allowed to use three avatars, you have used  ${the_chars.join(
																				' and '
																			)} for free plan.`
																		);
																		return;
																	}
																	return;
																}
															}
															setS_char(
																tool.name
															);
															setCharacter({
																id: tool.id,
																name: tool.name,
																prompts:
																	tool.prompts,
																intro: tool.intro,
																creator:
																	tool.creator,
																image: filterImageUrl(
																	tool.id
																),
																category:
																	tool
																		.categories[1],
																visibility:
																	tool.visibility,
																sh_description:
																	tool.short_description,
																lg_description:
																	tool.long_description,
																craft_message:
																	tool.craft_message,
															});
														}
													}}
												>
													<Card className="h-100 pb-2">
														<div className="card-image mr-2 mr-sm-3 text-center">
															<LazyLoadImage
																src={filterImageUrl(
																	tool.id
																)}
																style={{
																	width: '90px',
																	height: '90px',
																	borderRadius:
																		'50%',
																	padding:
																		tool.creator ===
																		'creator'
																			? ''
																			: '4px',
																}}
																className="mt-3"
																placeholderSrc={
																	chemistryImg
																}
																effect="blur"
															/>
														</div>
														<div className="card-content text-center">
															<h4 className="mb-0 mt-2 mb-sm-2">
																{tool.name}
															</h4>
															<h5
																className="mb-0 mt-2 px-1"
																style={{
																	backgroundColor:
																		'gray',
																	width: 'min-content',
																	margin: '0 auto',
																}}
															>
																@{tool.creator}
															</h5>
														</div>
														<div
															className="bookmark position-absolute"
															style={{
																right: 5,
															}}
															data-id={tool.id}
															onClick={(
																event
															) => {
																event.stopPropagation(); // prevent click from bubbling up to Card
																const id =
																	event
																		.currentTarget
																		.dataset
																		.id;
																console.log(
																	'Clicked bookmark for tool with ID:',
																	id
																);
																handleBookmarkClick(
																	id
																);
															}}
														>
															{user ? (
																checkedSaved(
																	tool.id
																)
															) : (
																<i className="far fa-star"></i>
															)}
														</div>
													</Card>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</div>
	);
}

export default AvatarList;
