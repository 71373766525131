import React, {useState} from "react";
import ChatbotComponent from "components/ChatbotComponent/ChatbotComponent";
import Layout from "components/Layout";
import { useDocumentOnce } from "react-firebase-hooks/firestore";
import ChatProvider from "contexts/ChatContext";
import { useLaunch } from 'contexts/LaunchContext';


export default function ChatPage({ mode }) {
  // refs
  const chatBotChild = React.useRef(null);

  const [snapshot, loading, error, reload] = useDocumentOnce();
  const [isVisible, setIsVisible] = useState(false);
  const [bottomShare, setBottomShare] = useState("");
  const { isCharacterOpen, setIsCharacterOpen } = useLaunch();
  

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.body.classList.add("chat-page");
    return function cleanup() {
      document.body.classList.remove("chat-page");
    };
  }, []);

  // methods
  const callChatBotChild = () => {
    chatBotChild.current()
  };

  const toggleCancelButton = () => {
    if (bottomShare === "yes") {
      callChatBotChild();
      setBottomShare("")
    }
		callChatBotChild();
		setIsVisible(!isVisible);
  };

  return (
		<ChatProvider mode={mode}>
			<Layout
				callChatBotChild={callChatBotChild}
				toggleCancelButton={toggleCancelButton}
				isVisible={isVisible}
				isCharacterOpen={isCharacterOpen}
				setIsCharacterOpen={setIsCharacterOpen}
			>
				<ChatbotComponent
					chatBotChild={chatBotChild}
					callChatBotChild={callChatBotChild}
					setIsVisible={setIsVisible}
					setBottomShare={setBottomShare}
					toggleCancelButton={toggleCancelButton}
				/>
			</Layout>
		</ChatProvider>
  );
}
