import { Modal, Container, Row, Col, Button } from "reactstrap";
import { useState, useEffect } from "react";
import { pdfToText } from "helpers/convert";
import imageToText from "helpers/imageToText";
import PropTypes from "prop-types";
import FileUpload from "components/CustomUpload/FileUpload";
import { Spinner } from "reactstrap";

export default function UploadOptions({ isOpen, onClose, onSubmit }) {
  const [file, setFile] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [result, setResult] = useState(null);

  const handleClose = () => {
    setInProgress(false);
    setFile(null);
    setResult(null);
    onClose();
  };

  useEffect(() => {
    setInProgress(false);
    setFile(null);
    setResult(null);
  }, [isOpen]);

  const loadFile = (file) => {
    setFile(file);

    if (file.type === "application/pdf") {
      pdfToText(file).then((text) => {
        console.log("Result", text);
        setResult(text);
      });
    } else if (file.type.match(/^image/)) {
      imageToText(file).then((text) => {
        console.log("Result", text);
        setResult(text);
      });
    } else {
      throw new Error("Invalid file type");
    }

    setInProgress(true);
  };

  const handleSubmit = () => {
    result && onSubmit(result);
    handleClose();
  };

  // if there is a result, any conversion process must be completed
  const completed = Boolean(result);

  return (
    <Modal
      style={{ backgroundColor: "#304069" }}
      isOpen={isOpen}
      modalClassName="modal-black"
      size="lg"
    >
      <Container className="p-4">
        <Row className="m-0 justify-content-center">
          <Col>
            {completed ? (
              <h1 className="text-center">Completed!</h1>
            ) : inProgress ? (
              <h1 className="text-center">Extracting text...</h1>
            ) : null}
          </Col>
        </Row>
        {!file && (
          <Row className="justify-content-center">
            <FileUpload onSubmit={loadFile} />
          </Row>
        )}

        {inProgress && (
          <Row className="justify-content-center">
            <Spinner style={{ width: "4em", height: "4em" }} children={""} />
          </Row>
        )}
        <Row className="justify-content-center m-0 mt-4">
          {completed ? (
            <Button color="primary" onClick={handleSubmit}>
              Close
            </Button>
          ) : (
            <Button color="default" onClick={handleClose}>
              Cancel
            </Button>
          )}
        </Row>
      </Container>
    </Modal>
  );
}

UploadOptions.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onSubmit: () => {},
};

UploadOptions.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
