import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalBody,
	ModalFooter,
	Alert,
	Table
} from 'reactstrap';
import axios from 'axios';
import { useAuth } from 'contexts/AuthContext';
import { config } from 'constants/stripe';


function PlansRedirect({ subscribe, mode }) {
	const { user } = useAuth();
	const [error, setError] = useState('');
	const [width, setWidth] = useState(window.innerWidth);
	const [selectedOptionPlans, setSelectedOptionPlans] = useState('Pro');
	const [isChecked, setIsChecked] = useState(true);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const isMobile = width <= 503;

	const handleButtonClick = () => {
		handleCheckout(selectedOptionPlans);
	};

	const handleCheckout = async (price_plan) => {
		try {
			const response = await axios.post(
				'https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/stripe/create-checkout-session/',
				{
					price_plan: price_plan,
					uid: user.uid,
				},
				config
			);
			if (response.data.url !== undefined) {
				const amount = response.data.amount_total / 100;
				localStorage.setItem('sub_amount', amount);
				window.location.href = response.data.url;
			}
		} catch (error) {
			console.error(error);
			setError('Failed to create checkout session');
		}
	};

	return (
		<Modal isOpen={subscribe} modalClassName="custom-height-modal" centered>
			<style>
				{`
					.modal-content {
						background-color: #000 !important;
						border-radius: 7px !important;
					}

					.selected {
						background: linear-gradient(rgba(92, 38, 211, 0.2), rgba(30, 132, 227, 0.2)) padding-box,
									linear-gradient(45deg, #AB00F5, #0B21E5) border-box;
						border: 2px solid transparent;
						border-radius: 3px !important;
						cursor: pointer;
						width: 12rem;
					}

					.not-selected {
						border-width: 2px;
						border-style: solid;
						bordercolor: #64748b;
						border-radius: 3px !important;
						cursor: pointer;
						width: 12rem;
					}

					.small_card {
						background: hsla(282, 100%, 48%, 1) !important;
					}

					.blue-purple-brain:hover {
						background-image:
							linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%) !important;
						
					}

					.blue-purple-brain {
						width: 60% !important;
						font-size: 1.15rem;
						font-weight: 700;
					}

					@media screen and (max-width: 450px) {
						.blue-purple-brain {
							width: 90% !important;
						}
					}

					.check-icon {
						background: #0B21E5;
						background: hsla(282, 100%, 48%, 1);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}

					.switch-label {
						transform: scale(-1);
					}

					.switch-input {
						height: 0;
						width: 0;
						visibility: hidden;
					}

					.switch-label {
						cursor: pointer;
						text-indent: -9999px;
						width: 40px;
						height: 20px;
						background: grey;
						display: block;
						border-radius: 100px;
						position: relative;
					}

					.switch-label:after {
						content: '';
						position: absolute;
						top: 5px;
						left: 5px;
						width: 10px;
						height: 10px;
						background: #fff;
						border-radius: 90px;
						transition: 0.3s;
					}

					.switch-input:checked + .switch-label {
						background: hsla(282, 100%, 48%, 1);
					}

					.switch-input:checked + .switch-label:after {
						left: calc(100% - 5px);
						transform: translateX(-100%);
					}

					.switch-label:active:after {
						width: 30px;
					}

					td {
						text-align: center;
						line-height: 1rem;
						padding: 0 !important;
					}

					th {
						line-height: .8rem;
						padding: 0;
						color: #fff !important;
					}

					tr {
						padding: 0;
					}

					.odd {
						background: rgb(225, 225, 225, .1) !important;
					}

					.x-me {
						font-size: 1.3rem;
					}

					.check-me {
						font-size: 1.3rem;
					}

					.head {
						font-weight: 700 !important;
						font-size: 1rem !important;
						color: #fff;
						text-align: center;
					}

					@media screen and (max-width: 370px){
						.head {
							font-size: 80% !important;
						}
						
					}
				`}
			</style>
			<div
				className="relative rounded-lg"
				style={{
					backgroundColor: 'rgba(30, 132, 227, 0.2)',
					background:
						'linear-gradient(107.62deg, rgba(30, 132, 227, 0.2) 0%,rgba(92, 38, 211, 0.2) 100% )',
					boxShadow: '0px 20px 50px 0px rgba(0, 0, 0, 0.3)',
				}}
			>
				<div className="p-0">
					<ModalBody className="p-0 pt-4 p-sm-3">
						<div className="d-flex align-items-center justify-content-center text-center">
							<div style={{ width: isMobile ? '' : '85%' }}>
								<h2 className="mb-2 mt-3 text-white font-weight-bolder">
									Upgrade to Big Brain Mode to go unlimited!
								</h2>
							</div>
						</div>
						<div
							className="px-md-5 text-center py-4 text-white"
							style={{ fontSize: '1.3rem', lineHeight: '1.4rem' }}
						>
							{mode.key === 'creativeImage' ? (
								<div>
									You used your 5 free images this month.
								</div>
							) : (
								<div>
									You used your 10000 free characters this
									month.
								</div>
							)}
						</div>
						<div className="px-md-5">
							<Table borderless>
								<thead>
									<tr>
										<th className="head"></th>
										<th className="head">Free</th>
										<th className="head">
											Big Brain
											{!isMobile && (
												<span className="ml-2">
													<img
														src="/brain.png"
														style={{
															filter: 'invert(100%)',
															width: '1.2rem',
															height: '1.2rem',
														}}
														alt="brain"
													/>
												</span>
											)}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr className="odd">
										<th scope="row">Questions</th>
										<td>25/month</td>
										<td>Unlimited</td>
									</tr>
									<tr>
										<th scope="row">AI Images</th>
										<td>5/month</td>
										<td>200/month</td>
									</tr>
									<tr className="odd">
										<th scope="row">Avatars</th>
										<td> 3</td>
										<td>Unlimited</td>
									</tr>
									<tr>
										<th scope="row">Infinite memory</th>
										<td>
											<i className="fa fa-times p-1 x-me"></i>
										</td>
										<td>
											<i className="fas fa-check p-1 check-me"></i>
										</td>
									</tr>
									<tr className="odd">
										<th scope="row">
											Personalizes to you over time
										</th>
										<td>
											<i className="fa fa-times p-1 x-me"></i>
										</td>
										<td>
											<i className="fas fa-check p-1 check-me"></i>
										</td>
									</tr>
									<tr>
										<th scope="row">
											Upload images to Sensei
										</th>
										<td>
											<i className="fa fa-times p-1 x-me"></i>
										</td>
										<td>
											<i className="fas fa-check p-1 check-me"></i>
										</td>
									</tr>
									<tr className="odd">
										<th scope="row">
											Sensei internet access
										</th>
										<td>
											<i className="fa fa-times p-1 x-me"></i>
										</td>
										<td>
											<i className="fas fa-check p-1 check-me"></i>
										</td>
									</tr>
									<tr>
										<th scope="row">Price</th>
										<td>Free</td>
										<td
											style={{
												fontWeight: '700',
											}}
										>
											$100/year or $10/month
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</ModalBody>
					<ModalFooter className="mt-0 mb-2">
						<button
							style={{
								background:
									'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
								cursor: 'pointer',
								outline: 'none',
								border: 0,
								borderRadius: '8px',
							}}
							className="m-auto py-3 text-white px-4 blue-purple "
							onClick={handleButtonClick}
						>
							Go Big Brain Mode
						</button>
						<div className="d-flex align-items-start justify-content-center mt-1 w-100 py-2">
							<div className="d-flex flex-column justify-content-between align-items-center">
								<p className="text-white">
									<b
										style={{
											fontWeight: '700',
										}}
									>
										Yearly
									</b>
								</p>
								<p
									className="py-0 px-2 rounded small_card text-white"
									style={{
										fontSize: '.8rem',
									}}
								>
									Save 20%
								</p>
							</div>
							<div className="d-flex align-items-center mx-3">
								<input
									type="checkbox"
									id="switch"
									className="switch-input"
									checked={isChecked}
									onChange={(e) => {
										const checked = e.target.checked;
										setIsChecked(checked);
										setSelectedOptionPlans(
											e.target.checked ? 'Pro' : 'Basic'
										);
									}}
								/>
								<label
									htmlFor="switch"
									className="switch-label"
								>
									Toggle
								</label>
							</div>
							<div
								className={`d-flex px-1 pt-0 mt-0 align-items-start`}
							>
								<div>
									<p className="text-white">
										<b
											style={{
												fontWeight: '700',
											}}
										>
											Monthly
										</b>
									</p>
								</div>
							</div>
						</div>
					</ModalFooter>
				</div>
			</div>
		</Modal>
	);
}

export default PlansRedirect;
