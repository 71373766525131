import { NavLink, useNavigate } from 'react-router-dom';
import "./style.css";
import { useLocation, useParams } from 'react-router-dom';
import { useLaunch } from 'contexts/LaunchContext';
import { useEffect, useState } from 'react';
import { db } from 'lib/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from 'contexts/AuthContext';

export default function Sidebar({
	isMobile,
	setIsSidebarOpen,
	setBigBrain,
}) {
	const location = useLocation();
	const navigate = useNavigate();
	const { setLaunchPad } = useLaunch();
	const { topic } = useParams();
	const [decodedTopic, setDecodedTopic] = useState('');
	const { user } = useAuth();

	// Decode the topic parameter
	useEffect(() => {
		setDecodedTopic(topic ? decodeURIComponent(topic) : '');
	}, [topic]);

	const [textOpen, setTextOpen] = useState(true);


	useEffect(() => {
		const isLaunchpadText = async (uid) => {
			const docRef = doc(db, 'userProfiles', uid);
			const docSnap = await getDoc(docRef);
			return docSnap.data().launchpad_text;
		};

		const getLaunchpadText = async () => {
			let launchpadText = await isLaunchpadText(user.uid);
			if (launchpadText === false) {
				setTextOpen(false);
			}
		};

		if (user && user.uid) {
			getLaunchpadText();
		}
	}, []);

	const updateUserProfileLaunchpadText = async (uid) => {
		const docRef = doc(db, 'userProfiles', uid);
		const docSnap = await getDoc(docRef);
		if (docSnap && docSnap.exists()) {
			if (!docSnap.data().launchpad_text) {
				await updateDoc(docRef, { launchpad_text: true });
			}
		}
	};

	return (
		<div style={{ marginBottom: '9vh' }}>
			{isMobile && (
				<i
					className="fa fa-times"
					style={{
						position: 'absolute',
						top: 15,
						right: 15,
						fontSize: '2em',
						color: '#fff',
						cursor: 'pointer',
					}}
					onClick={() => setIsSidebarOpen(false)}
				></i>
			)}
			{!isMobile ? (
				<>
					<div
						className="mt-3 pl-1 py-1 position-absolute learn-tooltip"
						style={{
							color: 'white',
							top: 75,
							right: -265,
							fontSize: '1rem',
							width: '21rem',
							height: '2.1rem ',
							background:
								'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
							borderRadius: '8px',
							zIndex: 100,
							visibility: !textOpen ? 'visible' : 'hidden',
						}}
					>
						<div
							className="mt-0 px-0 py-0  position-absolute"
							style={{
								color: 'white',
								left: '2%',
								fontSize: '1rem',
								width: '20rem',
								height: '2.9rem',
								border: 0,
								zIndex: 100,
								background:
									'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1) 100%)',
								clipPath:
									'polygon(0% 0%, 100% 0%, 100% 65%, 18% 64%, 6% 100%, 9% 63%, 0 63%)',
							}}
						>
							<span className="position-relative">
								Explore other projects in Launchpad
								<span
									onClick={async (event) => {
										event.stopPropagation();
										setTextOpen(true);
										await updateUserProfileLaunchpadText(user.uid);
									}}
									style={{
										cursor: 'pointer',
									}}
								>
									<i className="fas fa-times-circle ml-3"></i>
								</span>{' '}
							</span>
						</div>
					</div>
				</>
			) : (
				<></>
			)}
			<div
				style={{
					display: 'flex',
					justifyContent: isMobile ? 'center' : 'center',
					alignItems: 'center',
					overflow: 'hidden',
					fontSize: '2.3vh',
					position: isMobile ? 'relative' : 'inherit',
					right: isMobile ? '.4vw' : '0vw',
					width: isMobile ? '100%' : 'inherit',
					color: 'white',
					marginTop: 0,
					padding: 0,
				}}
			>
				{isMobile ? (
					<>
						<style>
							{`
							@media screen and (max-width: 390px){
								.go-big {
									padding: 4px !important;
									padding-right: 0px !important;
									margin-right: 16px !important;
								}
							}
							`}
						</style>
						<span className="">
							<button
								className="py-2 px-2 go-big text-white mb-3"
								style={{
									outline: 'none',
									border: 0,
									bottom: '3vh',
									width: '11.5rem',
									borderRadius: '7px',
									background:
										'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1)100%)',
								}}
								onClick={() => {
									setBigBrain(true);
									setIsSidebarOpen(false);
								}}
							>
								Go Big Brain{' '}
								<img
									src="/brain.png"
									style={{
										filter: 'invert(100%)',
										width: '1.2rem',
										height: '1.2rem',
									}}
									alt="brain"
								/>
							</button>
						</span>
					</>
				) : location.pathname === '/settings' ? (
					<>
						<span
							className="logclick p-0 mt-2 pb-0"
							onClick={() => navigate('/')}
						>
							<img
								src="/logo.png"
								alt="logo"
								className="mb-3"
								style={{
									marginTop: '4px',
									height: '6.4vh',
									width: 'auto',
								}}
							/>
						</span>
					</>
				) : (
					<>
						<span
							className="logclick p-0 mt-2 pb-0"
							onClick={() => navigate('/')}
						>
							<img
								src="/logo.png"
								alt="logo"
								className="mb-3"
								style={{
									marginTop: '4px',
									height: '6.4vh',
									width: 'auto',
								}}
							/>

							<span className=""></span>

							{/* <h4 className="p-0 mb-2 mt-1 pt-md-1">
								Chat With Sensei:
							</h4> */}
						</span>
					</>
				)}
			</div>
			<ul className="nav" style={{ marginTop: '.5vh' }}>
				<li>
					{isMobile ? (
						<></>
					) : (
						<>
							<button
								className="py-2 px-2 go-big text-white mb-3"
								style={{
									outline: 'none',
									border: 0,
									width: '100%',
									// width: '11.5rem',
									borderRadius: '7px',
									fontSize: "130%",
									background:
										'linear-gradient(135deg,hsla(282, 100%, 48%, 1) 34%,hsla(234, 91%, 47%, 1)100%)',
								}}
								onClick={(event) => {
									event.stopPropagation();
									setBigBrain(true);
									setIsSidebarOpen(false);
								}}
							>
								Go Big Brain{' '}
								<img
									src="/brain.png"
									style={{
										filter: 'invert(100%)',
										width: '1.2rem',
										height: '1.2rem',
									}}
									alt="brain"
								/>
							</button>
						</>
					)}

				</li>
				<li
					className="nav-link"
					onClick={async () => {
						setTextOpen(true);
						await updateUserProfileLaunchpadText(user.uid);
					}}
				>
					<NavLink to="/" end>
						<i className="fa fa-rocket"></i>
						Launchpad
					</NavLink>
				</li>
				<li
					className="nav-link"
					onClick={() => {
						setLaunchPad(null);
						setDecodedTopic('');
						navigate('/mode/play', { replace: true });
					}}
				>
					<NavLink
						to={`/mode/play${
							decodedTopic ? `/${decodedTopic}` : ''
						}`}
						onClick={() => {
							setLaunchPad(null);
							navigate('/mode/play', { replace: true });
						}}
						end
					>
						<i className="fas fa-gamepad "></i>
						<span
							style={{
								marginLeft: '-.3rem',
							}}
						>
							Play
						</span>
					</NavLink>
				</li>
				<li
					className="nav-link"
					onClick={() => {
						setLaunchPad(null);
						setDecodedTopic('');
						navigate('/mode/learning', { replace: true });
					}}
				>
					<NavLink
						to={`/mode/learning${
							decodedTopic ? `/${decodedTopic}` : ''
						}`}
						end
						className="position-relative"
					>
						<i className="fa fa-brain"></i>
						Learning
					</NavLink>
				</li>
				<li
					className="nav-link"
					onClick={() => {
						setLaunchPad(null);
						setDecodedTopic('');
						navigate('/mode/solver', { replace: true });
					}}
				>
					<NavLink
						to={`/mode/solver${
							decodedTopic ? `/${decodedTopic}` : ''
						}`}
						end
					>
						<i className="tim-icons icon-bulb-63"></i>
						Solve it
					</NavLink>
				</li>
				<li
					className="nav-link"
					onClick={() => {
						setLaunchPad(null);
						setDecodedTopic('');
						navigate('/mode/math', { replace: true });
					}}
				>
					<NavLink
						to={`/mode/math${
							decodedTopic ? `/${decodedTopic}` : ''
						}`}
						end
					>
						<i
							className="fa fa-calculator"
							style={{
								paddingRight: isMobile ? '1.4vw' : '.3vw',
							}}
						/>
						Math & Science
					</NavLink>
				</li>
				<li
					className="nav-link"
					onClick={() => {
						setLaunchPad(null);
						setDecodedTopic('');
						navigate('/mode/creative', { replace: true });
					}}
				>
					<NavLink
						to={`/mode/creative${
							decodedTopic ? `/${decodedTopic}` : ''
						}`}
						className={({ isActive }) => {
							return location.pathname.startsWith(
								'/mode/creative'
							) ||
								location.pathname.startsWith(
									'/mode/creativeImage'
								)
								? isActive
									? 'active'
									: ''
								: '';
						}}
					>
						<i className="fa fa-palette"></i>
						Creative
					</NavLink>
				</li>
				<li
					className="nav-link"
					onClick={() => {
						setLaunchPad(null);
						setDecodedTopic('');
						navigate('/mode/writing', { replace: true });
					}}
				>
					<NavLink
						to={`/mode/writing${
							decodedTopic ? `/${decodedTopic}` : ''
						}`}
						end
					>
						<i className="fa fa-pencil"></i>
						Writing
					</NavLink>
				</li>
				<li
					className="nav-link"
					onClick={() => {
						setLaunchPad(null);
						setDecodedTopic('');
						navigate('/mode/coding', { replace: true });
					}}
				>
					<NavLink
						to={`/mode/coding${
							decodedTopic ? `/${decodedTopic}` : ''
						}`}
						end
					>
						<i className="fa fa-code"></i>
						Coding
					</NavLink>
				</li>
				{location.pathname === '/account' ? (
					<li className="nav-link">
						<NavLink to="/plans" end>
							<i className="fa fa-credit-card"></i>
							Billing
						</NavLink>
					</li>
				) : (
					<></>
				)}
				{isMobile ? (
					<>
						<div style={{ marginTop: isMobile ? '1vh' : '16vh' }}>
							<li className="nav-link">
								<NavLink
									to={{
										pathname:
											'https://discord.gg/QkdQbrh23G',
									}}
									target="_blank"
								>
									<i className="fab fa-discord" />
									Join Discord
								</NavLink>
							</li>
							<li
								className="nav-link"
								style={{ paddingLeft: '1vw' }}
							>
								<NavLink to="/settings">
									<i className="fa fa-gear" />
									Settings
								</NavLink>
							</li>
						</div>
					</>
				) : (
					<div />
				)}
			</ul>
		</div>
	);
}
