import { useState, useEffect } from "react";
import { copyToClipboard } from "../../helpers/util";
import { useBanner } from "../../contexts/BannerContext";
import { FaRegClipboard } from "react-icons/fa";

export default function CopyToClipboadButton({ content, text }) {
  const [copied, setCopied] = useState(false);
  const { addMessage } = useBanner();

  useEffect(() => {
    if (!copied) return;
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [copied]);

  const handleCopy = () => {
    if (copied) return;
    const success = copyToClipboard(content);
    setCopied(success);
    addMessage({ type: "success", text: "Copied!", duration: 2000 });
  };

  return (
    <span className="copy-answer" onClick={handleCopy}>
      {copied ? "Copied!" : text || "Copy"}
      <FaRegClipboard className="icon" />
    </span>
  );
}
