import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Media, Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import { config } from '../../constants/stripe';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { db, analytics } from 'lib/firebase';
import {
  doc,
  getDoc,
} from "firebase/firestore";
import { logEvent } from 'firebase/analytics';
import { randomString } from 'helpers/creativeImage';

const SubcribedDisplay = ({ subscribed, pause, openCancelSub, date, type }) => (
	<>
		<Container fluid className="mt-3">
			<CardBody>
				<h3 className="mb-3" style={{ fontWeight: '600' }}>
					Current plan
				</h3>
				<h1
					className="mb-3 text-capitalize"
					style={{ fontWeight: '700' }}
				>
					{type ? type : 'special plan'}
				</h1>
				<p className="my-2">
					Next payment is on {date ? date : 'special plan'}
				</p>
				<Button
					color="danger"
					onClick={() => {
						if (subscribed) {
							openCancelSub();
						} else if (pause === true) {
							alert(
								'Your subscription is currently paused contact support to resume it.'
							);
						}
					}}
				>
					{pause ? 'SUBSCRIPTION PAUSED' : 'CANCEL SUBSCRIPTION'}
				</Button>
			</CardBody>
		</Container>
	</>
);

const ProductDisplay = ({
	onCheckout,
	subscribed,
	pause,
	isMobile,
	openCancelSub,
}) => (
	<>
		<Container className="mt-3" fluid={isMobile}>
			<Row>
				<Col lg="6">
					<Card
						style={{
							width: isMobile ? '100%' : '20rem',
							margin: '1rem auto',
						}}
					>
						<CardBody>
							<h2 className="text-center mt-3">Big Brain Mode</h2>
							<div className="text-center">
								<h4>$10 Billed Monthly</h4>
							</div>
							<Media className="align-items-center justify-content-center mt-3">
								<Button
									color={subscribed ? 'danger' : 'primary'}
									className={subscribed ? '' : 'gradBtn'}
									size="lg"
									type="button"
									onClick={() => {
										if (subscribed) {
											openCancelSub();
										} else if (pause === true) {
											alert(
												'Your subscription is currently paused contact support to resume it.'
											);
										} else {
											onCheckout('Basic');
										}
									}}
								>
									{subscribed
										? 'CANCEL SUBSCRIPTION'
										: pause
										? 'SUBSCRIPTION PAUSED'
										: 'SUBSCRIBE NOW'}
								</Button>
							</Media>
							<Card className="pb-1">
								<CardBody>
									<div>
										<ul className="pl-2">
											<li>Unlimited Avatars</li>
											<li>200 images per month</li>
											<li>
												Big brain memory (have Sensei
												remember all your conversations
												and grow with you)
											</li>
											<li>
												Give Sensei internet access
												(coming soon)
											</li>
											<li>
												Upgrade Sensei math/science
												correctness with Wolfram-Alpha
											</li>
											<li>Upload images for questions</li>
										</ul>
									</div>
								</CardBody>
							</Card>
						</CardBody>
					</Card>
				</Col>
				<Col lg="6">
					<Card
						style={{
							width: isMobile ? '100%' : '20rem',
							margin: '1rem auto',
						}}
					>
						<CardBody>
							<h2 className="text-center mt-3">Big Brain Mode</h2>
							<div className="text-center">
								<h4>$100 Billed Yearly</h4>
							</div>
							<Media className="align-items-center justify-content-center mt-3">
								<Button
									color={subscribed ? 'danger' : 'primary'}
									className={subscribed ? '' : 'gradBtn'}
									size="lg"
									type="button"
									onClick={() => {
										if (subscribed) {
											openCancelSub();
										} else if (pause === true) {
											alert(
												'Your subscription is currently paused contact support to resume it.'
											);
										} else {
											onCheckout('Pro');
										}
									}}
								>
									{subscribed
										? 'CANCEL SUBSCRIPTION'
										: pause
										? 'SUBSCRIPTION PAUSED'
										: 'SUBSCRIBE NOW'}
								</Button>
							</Media>
							<Card className="pb-1">
								<CardBody>
									<div>
										<ul className="pl-2">
											<li>Unlimited Avatars</li>
											<li>200 images per month</li>
											<li>
												Big brain memory (have Sensei
												remember all your conversations
												and grow with you)
											</li>
											<li>
												Give Sensei internet access
												(coming soon)
											</li>
											<li>
												Upgrade Sensei math/science
												correctness with Wolfram-Alpha
											</li>
											<li>Upload images for questions</li>
										</ul>
									</div>
								</CardBody>
							</Card>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	</>
);

const SuccessDisplay = ({ onPortal, toHome}) => {
	return (
		<>
			<Container className="mt-3">
				<Row>
					<Col>
						<div className="mt-5 pt-4">
							<h2 className="text-center mb-4">
								Thank You For Subscribing!
							</h2>
							<p className="text-center">
								Your subscription Subscription to Learning with
								AI has been completed successfully.
								We appreciate your support!
							</p>
							<div className=" d-flex justify-content-around text-center mt-4">
								<button
									className="btn btn-dark btn-lg"
									onClick={toHome}
								>
									Return to Home
								</button>
								<button
									size="sm"
									as="button"
									className="btn gradBtn btn-lg"
									onClick={onPortal}
								>
									Manage your billing information
								</button>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
};

const Message = ({ message, toHome, toBillings }) => (
	<>
		<section>
			<Container>
				<Row>
					<Col>
						<div className="mt-5 pt-4">
							<h2 className="text-center mb-4">
								Sorry, A problem occurred!
							</h2>
							<p className="text-center">{message}</p>
							<div className=" d-flex justify-content-around text-center mt-4">
								<button
									onClick={toHome}
									className="btn btn-dark btn-lg"
								>
									Return to Home
								</button>
								<button
									size="sm"
									as="button"
									className="btn gradBtn btn-lg"
									onClick={toBillings}
								>
									Retry payment
								</button>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	</>
);

export default function Plans({
	message,
	setMessage,
	success,
	setSuccess,
	sessionIdRef,
	handleOpenCancelSub,
	type,
	date,
	subscribed,
	pause
}) {
	const { user } = useAuth();
	const navigate = useNavigate();

	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const isMobile = width <= 400;

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	const handleCheckout = async (price_plan) => {
		try {
			const response = await axios.post(
				'https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/stripe/create-checkout-session/',
				{
					price_plan: price_plan,
					uid: user.uid,
				},
				config
			);
			console.log(response.data.url);
			setSuccess(true);
			sessionIdRef.current = response.data.session_id;
			if (response.data.url !== undefined) {
				const amount = response.data.amount_total / 100;
				localStorage.setItem('sub_amount', amount);
				window.location.href = response.data.url;
			}
		} catch (error) {
			console.error(error);
			setMessage('Failed to create checkout session');
		}
	};

	const handlePortal = async () => {
		try {
			const response = await axios.post(
				`https://learningwithaiv1-t45fnh5fzq-uc.a.run.app/api/v1/stripe/create-portal-session/`,
				{
					session_id: sessionIdRef.current,
				},
				config
			);
			window.location.href = response.data.url;
		} catch (error) {
			console.error(error);
			setMessage('Failed to create portal session');
		}
	};

	const toHome = () => {
		navigate('/');
	};

	const toBillings = () => {
		window.location.reload();
	};

	if (subscribed) {
		return (
			<SubcribedDisplay
				subscribed={subscribed}
				pause={pause}
				openCancelSub={handleOpenCancelSub}
				date={date}
				type={type}
			/>
		);
	} else if (!success && message === '') {
		return (
			<ProductDisplay
				onCheckout={handleCheckout}
				subscribed={subscribed}
				pause={pause}
				isMobile={isMobile}
				openCancelSub={handleOpenCancelSub}
			/>
		);
	} else if (
		success &&
		sessionIdRef.current !== '' &&
		sessionIdRef.current !== undefined
	) {
		logEvent(analytics, 'subscribed_users', { subscribed_user_id: user.uid });
		logEvent(analytics, 'purchase', {
			action: 'completed subscription',
			user_uid: user.uid || '1234',
			user_name: user.displayName || '',
			currency: 'USD', // Use appropriate currency code
			value: parseInt(localStorage.getItem('sub_amount')) || 0, // Convert to cents if it's in dollars
			transaction_id: randomString(24),
		});
		return <SuccessDisplay onPortal={handlePortal} toHome={toHome} />;
	} else if (message !== '') {
		return (
			<Message
				message={message}
				toHome={toHome}
				toBillings={toBillings}
			/>
		);
	} else if (message === '') {
		return (
			<ProductDisplay
				onCheckout={handleCheckout}
				subscribed={subscribed}
				pause={pause}
				isMobile={isMobile}
				openCancelSub={handleOpenCancelSub}
			/>
		);
	}
}
