import { createWorker } from "tesseract.js";
import Resizer from "react-image-file-resizer";

export async function imageToText(image, options) {
  const worker = await createWorker({
    logger: (m) => options?.onProgress?.(m.progress),
    workerPath: "/worker.min.js",
    corePath: "/tesseract-core.wasm.js",
  });

  await worker.load();
  await worker.loadLanguage("eng");
  await worker.initialize("eng");

  const result = await worker.recognize(image);

  await worker.terminate();

  return result.data.text;
}

// stackoverflow
function Pdf2Text() {
  var self = this;
  this.complete = 0;
  this.pdfToText = function (data, callbackPageDone, callbackAllDone) {
    console.assert(data instanceof ArrayBuffer || typeof data == "string");
    var loadingTask = window.pdfjsLib.getDocument(data);
    loadingTask.promise.then(function (pdf) {
      var total = pdf._pdfInfo.numPages;
      var layers = {};
      for (var i = 1; i <= total; i++) {
        pdf.getPage(i).then(function (page) {
          var n = page.pageNumber;
          page.getTextContent().then(function (textContent) {
            if (null != textContent.items) {
              var page_text = "";
              var last_block = null;
              for (var k = 0; k < textContent.items.length; k++) {
                var block = textContent.items[k];
                if (
                  last_block != null &&
                  last_block.str[last_block.str.length - 1] != " "
                ) {
                  if (block.x < last_block.x) page_text += "\r\n";
                  else if (
                    last_block.y != block.y &&
                    last_block.str.match(/^(\s?[a-zA-Z])$|^(.+\s[a-zA-Z])$/) ==
                      null
                  )
                    page_text += " ";
                }
                page_text += block.str;
                last_block = block;
              }
              textContent != null && console.log("page " + n + " finished.");
              layers[n] = page_text + "\n\n";
            }
            ++self.complete;
            if (self.complete == total) {
              window.setTimeout(function () {
                var full_text = "";
                var num_pages = Object.keys(layers).length;
                for (var j = 1; j <= num_pages; j++) full_text += layers[j];
                callbackAllDone(full_text);
              }, 1000);
            }
          });
        });
      }
    });
  };
}

export function pdfToText(file) {
  return new Promise((resolve) => {
    var fileReader = new FileReader();
    var converter = new Pdf2Text();
    fileReader.onload = function () {
      converter.pdfToText(fileReader.result, null, (text) => resolve(text));
    };
    fileReader.readAsDataURL(file);
  });
}

export const submitToGoogle = async (file) => {
  try {
    let body = JSON.stringify({
      requests: [
        {
          features: [
            { type: "LABEL_DETECTION", maxResults: 10 },
            { type: "LANDMARK_DETECTION", maxResults: 5 },
            { type: "FACE_DETECTION", maxResults: 5 },
            { type: "LOGO_DETECTION", maxResults: 5 },
            { type: "TEXT_DETECTION", maxResults: 5 },
            { type: "DOCUMENT_TEXT_DETECTION", maxResults: 5 },
            { type: "SAFE_SEARCH_DETECTION", maxResults: 5 },
            { type: "IMAGE_PROPERTIES", maxResults: 5 },
            { type: "CROP_HINTS", maxResults: 5 },
            { type: "WEB_DETECTION", maxResults: 5 },
          ],
          image: {
            source: {
              imageUri: file,
            },
          },
        },
      ],
    });
    let response = await fetch(
      "https://vision.googleapis.com/v1/images:annotate?key=" +
        "Environment[GOOGLE_CLOUD_VISION_API_KEY]",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: body,
      }
    );
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.log(error);
  }
};




export const resizeFile = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        500,
        500
      );
    });
}