import { useAuth } from 'contexts/AuthContext';
import { useEffect, useState } from 'react';
import { Container, Input, InputGroup, Form, Alert, Spinner, Button } from 'reactstrap';
import classnames from 'classnames';
import './Onboarding.css';
import { db } from 'lib/firebase';
import {
	collection,
	doc,
	getDocs,
	query,
	setDoc,
	getDoc,
} from 'firebase/firestore';

export default function Username({ onComplete }) {
	const { user } = useAuth();
	const [username, setUsername] = useState('');
	const [usernameSet, setUsernameSet] = useState(false);
	const [error, setError] = useState('');
	const [width, setWidth] = useState(window.innerWidth);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

	const onDismiss = () => {
        setError('')
        setVisible(false);
    } 

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	const isMobile = width <= 450;

	useEffect(() => {
		// checks if the user's username has been created before
		const isUsernameExists = async () => {
			const docRef = doc(db, 'users', user.uid);
			const docSnap = await getDoc(docRef);
			if (docSnap && docSnap.exists()) {
				console.log('Username already exists!');
				setUsernameSet(true);
			} else {
				setUsernameSet(false);
			}
		};
		isUsernameExists();
	}, [user]);

	const handleUsername = async () => {
		if (!username) {
			return;
		}
        setLoading(true)
		let usernameAlreadyExists = false;

		const q = query(collection(db, 'users'));

		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			if (doc.data().username === username) {
                setVisible(true);
				setError(
					'The username is already in use! Try another username'
				);
                setInterval(() => {
					setError('');
					setVisible(false);
				}, 4000);
				usernameAlreadyExists = true;
                setLoading(false)
			}
		});
        
		if (!usernameAlreadyExists) {
			await setDoc(doc(db, 'users', user.uid), {
				username: username,
			}).then(() => {
				setUsernameSet(true);
                setLoading(false);
                onComplete();
			});
		}
	};

	const usernameInputComponent = (
		<Form
			onSubmit={(e) => e.preventDefault()}
			action=""
			className="form"
			style={{
				overflowX: isMobile ? 'hidden' : '',
				overflowY: isMobile ? 'hidden' : '',
			}}
			method=""
		>
			{error && (
				<Alert color="warning" isOpen={visible} toggle={onDismiss}>
					{error}
				</Alert>
			)}
			<InputGroup
				style={{
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
				className={classnames(
					'd-flex',
					'justify-content-center',
					'mb-2',
					{
						'input-group-focus': true,
					}
				)}
			>
				<Input
					type="text"
					placeholder="Enter Username"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
				/>
			</InputGroup>
			<InputGroup className="d-flex justify-content-center">
				{loading ? (
					<Button color="primary" disabled>
						<Spinner size="sm">Sending...</Spinner>
						<span>Loading</span>
					</Button>
				) : (
					<button
						className="onboardingButton"
						onClick={handleUsername}
					>
						Continue
					</button>
				)}
			</InputGroup>
		</Form>
	);

	if (usernameSet) {
		onComplete();
	}
	return (
		<Container style={{ display: 'flex', flexDirection: 'column' }}>
			<span
				style={{
					color: 'white',
					fontSize: '3.4vh',
					marginLeft: 'auto',
					marginBottom: '4vh',
					marginRight: 'auto',
					textAlign: 'center',
				}}
			>
				Enter your username.
			</span>
			{usernameInputComponent}
		</Container>
	);
}
