
import { Helmet } from "react-helmet";
import OnboardingComponent from "components/Signup/Onboarding";
import { useEffect } from "react";

export default function OnboardingPage() {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.body.classList.add("onboarding-page");
    return function cleanup() {
      document.body.classList.remove("onboarding-page");
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Create Account | Learning With AI</title>
      </Helmet>
      <OnboardingComponent />
    </>
  );
}