import React, { useState } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
    ModalHeader,
	Row,
    Input,
} from 'reactstrap';
import { db } from 'lib/firebase';
import {
	doc,
	updateDoc,
} from 'firebase/firestore';

function CancelSub({ isOpen, onClose, openNext, toggle, uid }) {
	const handleToggle = () => toggle(!isOpen);
	const [reason, setReason] = useState("");

	const updateCancelReason = async () => {
		const docRef = doc(db, 'userProfiles', uid);
		await updateDoc(docRef, { cancel_sub_reason: reason }); // update cancel_sub_reason field of userProfiles document
	};

	return (
		<Modal
			isOpen={isOpen}
			modalClassName="modal-dark-blue custom-height-modal"
			centered
			toggle={handleToggle}
		>
			<div className="p-3">
				<ModalHeader toggle={onClose}></ModalHeader>
				<ModalBody>
					<label>Why do you want to cancel your subscription?</label>
					<Input
						placeholder=""
						name="cancelSub"
						type="text"
						value={reason}
						onChange={(e) => setReason(e.target.value)}
					/>
				</ModalBody>
				<ModalFooter className="mt-3">
					<Button onClick={onClose} className="" color="success">
						I changed my mind
					</Button>
					<Button
						onClick={() => {
							onClose();
							openNext();
							updateCancelReason();
						}}
						className=""
						color="danger"
					>
						I'm sure
					</Button>
				</ModalFooter>
			</div>
		</Modal>
	);
}

export default CancelSub;
